import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function CompleteIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 64 64" {...props}>
      <circle
        cx="23.002"
        cy="23.002"
        r="23.002"
        transform="translate(8.966 9.031)"
      />
      <path
        d="m9 16.089 6.089 6.089L27.268 10"
        transform="translate(13.866 16.911)"
      />
    </Icon>
  )
}
