import {useMemo} from 'react'
import {ProductAttrKey} from '_app/types/product'
import {AttributeOption, useGetCustomAttributeMetadataQuery,} from '../graphql/generated/graphql'
import AttributesEnum from '../../enums/AttributesEnum/AttributesEnum'

const attributeMetadataVariables = {
  attributes: [...AttributesEnum.CUSTOM_ATTRIBUTES, 'color_swatch'].map(attr => ({
    attribute_code: attr,
    entity_type: 'catalog_product',
  })),
}

export type AttributeMetadata = {
  [key in ProductAttrKey | 'color_swatch']?: AttributeOption[];
};

const useAttributesMetadata = (p: NonNullable<unknown>) => {
  const { data } = useGetCustomAttributeMetadataQuery({
    variables: attributeMetadataVariables,
    fetchPolicy: 'cache-first',
  })

  const metaData = useMemo(() => {
    const newMetaData: AttributeMetadata = {}
    data?.customAttributeMetadata?.items?.forEach(item => {
      if (item?.attribute_code) {
        const attributeCode =
          item?.attribute_code as typeof AttributesEnum.CUSTOM_ATTRIBUTES[number]
        newMetaData[attributeCode] =
          item?.attribute_options as AttributeOption[]
      }
    })

    return newMetaData
  }, [data])

  const decodeOption = (
    attr: ProductAttrKey | 'color_swatch',
    val?: number | string
  ) => {
    const attribute = metaData[attr]

    if (!val) return null

    return {
      image: attribute?.find(
        ({ value }) => value && val && value.toString() === val.toString()
      )?.image,
      value: attribute?.find(
        ({ value }) => value && val && value.toString() === val.toString()
      )?.label,
    }
  }

  return { metaData, decodeOption }
}

export default useAttributesMetadata
