import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function GmailIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 26 26" {...props}>
      <path
        id="Path_455"
        data-name="Path 455"
        d="M16,20h3a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1H16Z"
        fill="none"
        stroke="#000"
      />
      <path
        id="Path_456"
        data-name="Path 456"
        d="M5,20H8V4H5A1,1,0,0,0,4,5V19A1,1,0,0,0,5,20Z"
        fill="none"
        stroke="#000"
      />
      <path
        id="Path_457"
        data-name="Path 457"
        d="M16,4,12,8,8,4"
        fill="none"
        stroke="#000"
      />
      <path
        id="Path_458"
        data-name="Path 458"
        d="M4,6.5,12,14l8-7.5"
        fill="none"
        stroke="#000"
      />
    </Icon>
  )
}
