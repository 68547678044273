import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { StepsStyleConfig as Steps } from 'chakra-ui-steps'
import { colors } from './colors'
import { styles } from './styles'
import { textStyles } from './fonts'
import { Button } from './components/Button'
import { Link } from './components/Link'
import { Input } from './components/Input'
import { FormLabel } from './components/FormLabel'
import { Container } from './components/Container'
import { Form } from './components/Form'
import { Heading } from './components/Heading'

const theme = extendTheme(
  {
    styles,
    breakpoints: {
      xs: '25em',
      sm: '40em',
      md: '52em',
      lg: '64em',
      xl: '80em',
      '2xl': '96em',
    },
    textStyles,
    colors,
    components: {
      Button,
      Link,
      Input,
      FormLabel,
      Container,
      Form,
      Steps,
      Heading,
    },
    config: {
      disableTransitionOnChange: false,
    }
  },
  withDefaultColorScheme({ colorScheme: 'dark' })
)

export default theme
