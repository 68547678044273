import { FC, ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalContentProps,
} from '@chakra-ui/react'

interface Props extends ModalContentProps {
  title?: string;
  isOpen: boolean;
  children: ReactNode;
  onClose?: () => void;
  isCloseButton?: boolean;
  isInfoSize?: boolean;
}

const Modal: FC<Props> = ({
  isOpen,
  title,
  children,
  onClose,
  isCloseButton,
  isInfoSize,
  ...props
}) => {
  const infoSizeProps = {
    w: { base: '100%', md: '55%' },
    maxW: { base: '100%', md: '65rem' },
    maxH: { base: '100%', md: '57.5rem' },
    height: { base: '100%', md: '70%' },
  }

  return (
    <ChakraModal onClose={() => onClose?.()} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="0"
        overflowY="auto"
        margin="0"
        {...(isInfoSize ? infoSizeProps : {})}
        {...props}
      >
        {title && <ModalHeader>{title}</ModalHeader>}
        {isCloseButton && <ModalCloseButton />}
        <ModalBody padding="0">{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
