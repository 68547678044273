import React from 'react'
import Image from 'next/image'
import ImageHelper from '../../helpers/ImageHelper/ImageHelper'

const CloudflareImage = ({
    src,
    quality = 90,
    height,
    width,
    layout,
    priority = false,
    ...props
}: any) => {
    if (!src) return null
    if (height && width) {
        return (
            <Image
                {...props}
                alt={props.alt ?? ''}
                loader={ImageHelper.cloudflareLoader}
                src={src}
                height={height}
                width={width}
                quality={quality}
                loading={priority ? 'eager' : 'lazy'}
            />
        )
    } else {
        return (
            <Image
                {...props}
                alt={props.alt ?? ''}
                loader={ImageHelper.cloudflareLoader}
                src={src}
                layout="fill"
                quality={quality}
                loading={priority ? 'eager' : 'lazy'}
            />
        )
    }
}

export default CloudflareImage
