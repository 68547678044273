import {useReactiveVar} from '@apollo/client'
import {useRouter} from 'next/router'
import {useEffect} from 'react'
import stringHash from 'string-hash'
import {isAuthenticatedVar} from '_app/cache'
import {VisitorLoginState} from '_app/types/dataLayer'
import {getPageType, GTM_EVENTS} from '_app/utils/dataLayer'
import useCustomer from './useCustomer'

const loggedOutUserData = {
    visitorLoginState: VisitorLoginState.LoggedOut,
    userId: '',
    email: '',
    visitorLTVRevenue: 0,
    visitorLTVTransactions: 0,
}

const useVisitorEvent = () => {
    const isAuthenticated = useReactiveVar(isAuthenticatedVar)
    const router = useRouter()
    const {customer, loading} = useCustomer()
    useEffect(() => {
        const pageType = {pageType: getPageType(router.pathname)}
        const loggedInUserData =
            isAuthenticated && customer?.email
                ? {
                    visitorLoginState: VisitorLoginState.LoggedIn,
                    userId: stringHash(customer.email).toString(),
                    email: customer.email,
                    visitorLTVRevenue: (customer.orders?.items || []).reduce(
                        (acc, val) => {
                            return acc + Number(val?.total?.subtotal_including_tax?.value) || 0
                        },
                        0
                    ),
                    visitorLTVTransactions: (customer.orders?.items || []).length,
                }
                : {}
        if (router?.asPath) {
            GTM_EVENTS.visitor(Object.assign(pageType, loggedOutUserData, loggedInUserData))
        }
    }, [isAuthenticated, router?.asPath])

    return null
}

export default useVisitorEvent
