import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function SearchIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle cx="7" cy="7" r="7" transform="translate(3 3)" />
      <path transform="translate(15 15)" d="M6 6 0 0" />
    </Icon>
  )
}
