export const intentions: any = {
  title: 'CHOOSE_INTENTION',
  description: 'INTENTION_DESC',
  items: [
    {
      key: 'joy',
      name: 'INTENTION_JOY_NAME',
      title: 'INTENTION_JOY_TITLE',
      description: 'INTENTION_JOY_DESC',
    },
    {
      key: 'love',
      name: 'INTENTION_LOVE_NAME',
      title: 'INTENTION_LOVE_TITLE',
      description: 'INTENTION_LOVE_DESC',
    },
    {
      key: 'abundance',
      name: 'INTENTION_ABUNDANCE_NAME',
      title: 'INTENTION_ABUNDANCE_TITLE',
      description: 'INTENTION_ABUNDANCE_DESC',
    },
    {
      key: 'health',
      name: 'INTENTION_HEALTH_NAME',
      title: 'INTENTION_HEALTH_TITLE',
      description: 'INTENTION_HEALTH_DESC',
    },
    {
      key: 'protection',
      name: 'INTENTION_PROTECTION_NAME',
      title: 'INTENTION_PROTECTION_TITLE',
      description: 'INTENTION_PROTECTION_DESC',
    },
  ],
}
