import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react'
import qs from 'qs'
import {ComponentNavigationColumn} from '_app/graphql/cms/generated/graphql'
import LinkButton from '_app/components/Buttons/LinkButton'
import {useRouter} from '_app/hooks/useRouter'
import {ComponentPartsLink} from '../../types/cms'
import {UrlQuery} from '../../types'
import URLHelper from '../../../helpers/URLHelper/URLHelper'
import React from 'react'

interface FooterGroupProps {
    withAccordion?: boolean;
    column?: ComponentNavigationColumn;
    content?: { title: string; description: string };
}

export const FooterGroup = ({
    withAccordion,
    column,
    content,
}: FooterGroupProps) => {
    const router = useRouter()
    const {pathname, locale, query} = router

    const renderLink = (item: ComponentPartsLink) => {
        let link
        if (item?.external_href) {
            link = (
                <Link href={item.external_href} target="_blank" rel="noreferrer">
                    {item.text}
                </Link>
            )
        } else if (item.href?.startsWith('?')) {
            const parsedQuery = qs.parse(item.href.slice(1)) as UrlQuery
            link = (
                <LinkButton
                    fontSize="1rem"
                    fontWeight="light"
                    href={{
                        pathname: locale + pathname,
                        query: {
                            ...query,
                            ...parsedQuery,
                        },
                    }}
                    reverse
                    shallow
                    scroll={false}
                >
                    {item.text}
                </LinkButton>
            )
        } else {
            link = (
                <LinkButton
                    href={item?.href ? URLHelper.createURL({href: item.href, locale, addLocale: true }) : '/'}
                    fontSize="1rem"
                    fontWeight="light"
                    reverse
                >
                    {item?.text}
                </LinkButton>
            )
        }

        return link
    }

    return (
        <Box>
            {withAccordion && (
                <Box
                    display={{
                        base: 'block',
                        lg: 'none',
                    }}
                >
                    <AccordionItem border="none">
                        <Heading as="h2">
                            <AccordionButton _hover={{bg: 'white'}} p="0">
                                <Text flex="1" textAlign="left">
                                    {column?.title}
                                </Text>
                                <AccordionIcon/>
                            </AccordionButton>
                        </Heading>
                        {column?.items?.map((item, idx) =>
                            item?.text ? (
                                <AccordionPanel
                                    py="0.75rem"
                                    key={`accordion[${idx}]`}
                                    fontWeight="300"
                                >
                                    {renderLink(item)}
                                </AccordionPanel>
                            ) : null
                        )}
                    </AccordionItem>
                </Box>
            )}
            <Box
                display={{
                    base: withAccordion ? 'none' : 'block',
                    lg: 'block',
                }}
            >
                <Text mb="1.5rem">{column?.title || content?.title}</Text>
                {content?.description && (
                    <Box fontWeight="300">
                        <div
                            className="footer-links"
                            dangerouslySetInnerHTML={{
                                __html: content.description || '',
                            }}
                        />
                    </Box>
                )}
                <VStack alignItems="flex-start" spacing="1rem">
                    {column?.items?.map(
                        (item, idx) =>
                            item?.text && (
                                <Text key={`description[${idx}]`} fontWeight="300">
                                    {renderLink(item)}
                                </Text>
                            )
                    )}
                </VStack>
            </Box>
        </Box>
    )
}
