import { CurrencyEnum } from '_app/graphql/generated/graphql'

const getCurrency = (locale?: string) => {
  switch (locale) {
    case 'en':
      return CurrencyEnum.Eur
    case 'de':
    case 'fr':
      return CurrencyEnum.Eur
    default:
      return CurrencyEnum.Pln
  }
}

export default getCurrency
