import { useEffect, useState } from 'react'

const useDisplayReactPlayer = (videoUrl?: string) => {
  const [userAgent, setUserAgent] = useState('')

  useEffect(() => {
    if (window?.navigator?.userAgent) {
      setUserAgent(window?.navigator?.userAgent)
    }
  }, [])

  const isVimeo = videoUrl?.includes('vimeo')
  const isIosDevice =
    userAgent?.includes('iPhone') || userAgent?.includes('iPad')

  return [isIosDevice, isVimeo]
}

export default useDisplayReactPlayer
