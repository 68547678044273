import LangEnum from '../../../enums/LangEnum/LangEnum'

const getLocale = (locale?: string) => {
  let lang = locale
  if (locale) {
    lang = lang === LangEnum.GLOBAL_LANG ? 'en' : lang
    lang = lang === 'pl-PL' ? 'pl' : lang
  }
  return lang ?? LangEnum.DEFAULT_LANG
}

export default getLocale
