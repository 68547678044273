import styled from '@emotion/styled'
import React from 'react'

interface RichTextProps {
  html: string;
}

const StyledDiv = styled.div`
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
  }
  h3 {
    line-height: 1.4;
    font-weight: 500;
  }
  ol, ul {
    padding-left: 2em;
  }
  b, strong {
    font-weight: 500;
  }
  a {
    text-decoration: underline;
  }
`

const RichText = ({ html }: RichTextProps) => {
  return (
    <StyledDiv
      className="ck-content"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

export default RichText
