const RequestEnum = {
    CMS_PUBLIC: 'cms-public',
    CMS_PRIVATE: 'cms-private',
}

const CMS_CONTEXT = typeof window === 'undefined' ? RequestEnum.CMS_PRIVATE : RequestEnum.CMS_PUBLIC

export default {
    ...RequestEnum,
    CMS_CONTEXT,
    MICROSERVICE: 'microservice',
}