import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function BusIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle
        data-name="Ellipse 244"
        cx="2"
        cy="2"
        r="2"
        transform="translate(5.5 15.5)"
        stroke="#000"
        fill="none"
      />
      <circle
        data-name="Ellipse 245"
        cx="2"
        cy="2"
        r="2"
        transform="translate(15.5 15.5)"
        stroke="#000"
        fill="none"
      />
      <path
        data-name="Path 525"
        d="M5.5 17.5h-2v-11a1 1 0 0 1 1-1h9v12m-4 0h6m4 0h2v-6h-8m0-5h5l3 5"
        stroke="#000"
        fill="none"
      />
    </Icon>
  )
}
