import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function HeartFilledIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        style={{
          fill: '#000',
          outlineColor: '#fff',
        }}
        d="M19.5 13.572 12 21l-7.5-7.428m0 0A5 5 0 1 1 12 7.006a5 5 0 1 1 7.5 6.572"
      />
    </Icon>
  )
}
