import { Box, Flex, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ComponentNavigationTopMenu } from '_app/graphql/cms/generated/graphql'
import CmsLink from '_app/components/Link/CmsLink'
import NavBarDropDown from './NavBarDropDown'
import {useRouter} from '../../hooks/useRouter'

export interface NavigationProps {
  topMenu: ComponentNavigationTopMenu[];
}

const Navigation: FC<NavigationProps> = ({ topMenu }) => {
  const { locale } = useRouter()
  return (
    <Flex display={{ base: 'none', lg: 'flex' }} mx={-5} className="topMenu">
      {
          topMenu?.map(item => {
              const link: any = { ...item.link }
              link.addLocale = true
              link.locale = locale
              return (
                  <Box role="group" key={item.id} cursor="pointer" px={4}>
                      <CmsLink link={link}>
                          <Text
                              pb="24px"
                              position="relative"
                              textAlign="center"
                              _groupHover={{
                                  _after: {
                                      content: '""',
                                      position: 'absolute',
                                      bottom: 0,
                                      left: 0,
                                      height: '5px',
                                      width: '100%',
                                      bg: 'black',
                                      zIndex: 1,
                                  },
                              }}
                              sx={{ color: item?.title?.toLowerCase() === 'valentinstag' ? 'red' : 'auto'}}
                          >
                              {item.title}
                          </Text>
                      </CmsLink>
                      <NavBarDropDown
                          id={item.id}
                          columns={item.columns}
                          image={item.image}
                      />
                  </Box>
              )
        })
      }
    </Flex>
  )
}

export default Navigation
