import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    FormLabelProps,
    Radio as ChakraRadio,
    RadioGroup as ChakraRadioGroup,
    Spinner,
} from '@chakra-ui/react'
import {get} from 'lodash-es'
import React, {FC} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import FormErrorMessage from '_app/components/Form/FormErrorMessage'
import {BaseInputProps} from '_app/types/form'
import {buttonStyles, defaultStyles} from './styles'

interface Option {
    value: string;
    label: string | JSX.Element;
    body?: JSX.Element;
    isDisabled?: boolean;
}

interface RadioProps extends BaseInputProps {
    options: Option[] | undefined;
    disabled?: boolean;
    labelProps?: FormLabelProps;
    enableButtons?: boolean;
    style?: any;
}

export const Radio: FC<RadioProps> = ({
                                          name,
                                          style,
                                          label,
                                          labelProps = {},
                                          defaultValue,
                                          options = [],
                                          isRequired,
                                          disabled,
                                          enableButtons,
                                      }) => {
    const {
        formState: {errors},
        control,
    } = useFormContext()

    const errorMessage: any = get(errors, name)?.message
    const direction = enableButtons ? 'row' : 'column'
    const {radioStyles, textStyles, boxStyles} = enableButtons
        ? buttonStyles
        : defaultStyles

    return (
        <FormControl isInvalid={!!(errorMessage)} mb={4} isRequired={isRequired}>
            <FormLabel htmlFor={name} {...labelProps}>
                {label}
            </FormLabel>
            <Controller
                name={name}
                defaultValue={defaultValue}
                control={control}
                render={({field}) => (
                    <ChakraRadioGroup
                        name={name}
                        value={field.value}
                        onChange={field.onChange}
                        mt={2}
                    >
                        <Flex gap={enableButtons ? 0 : 4} direction={direction}>
                            {
                                options?.length > 0 ? options?.map(
                                    ({value, label: optionLabel, body, isDisabled}, index) => {
                                        return (
                                            <Box key={value} position="relative" {...boxStyles}>
                                                <ChakraRadio
                                                    value={value}
                                                    isDisabled={disabled || isDisabled}
                                                    {...radioStyles}
                                                >
                                                    <div
                                                        style={style}
                                                        color={disabled || isDisabled ? 'input' : 'black'}
                                                        {...textStyles}
                                                    >
                                                        {optionLabel}
                                                    </div>
                                                </ChakraRadio>
                                                {body}
                                            </Box>
                                        )
                                    }
                                ) : (
                                    <Spinner
                                        sx={{
                                            marginLeft: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                )
                            }
                        </Flex>
                    </ChakraRadioGroup>
                )}
            />
            <Box id={errorMessage}>
                <FormErrorMessage name={name}>{errorMessage}</FormErrorMessage>
            </Box>
        </FormControl>
    )
}
