import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function KeyIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 64 64" {...props}>
      <ellipse
        cx="10.5"
        cy="11"
        rx="10.5"
        ry="11"
        transform="translate(10.978 29.027)"
      />
      <path transform="translate(29.5 10.659)" d="M0 21.927 21.177 0" />
      <path transform="translate(48.5 13.586)" d="m0 0 5 4.914" />
      <path transform="translate(40.5 21.586)" d="m0 0 5 4.914" />
    </Icon>
  )
}
