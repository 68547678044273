import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from 'lodash-es'
import { FC, useEffect } from 'react'

import FormErrorMessage from '_app/components/Form/FormErrorMessage'
import { BaseInputProps } from '_app/types/form'

interface NumberProps extends BaseInputProps {
  withStepper?: boolean;
}

export const Number: FC<NumberProps> = ({
  label,
  name,
  isRequired,
  defaultValue,
  placeholder,
  withStepper,
  hidden,
  borderColor = 'black',
  color = 'black',
}) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext()

  useEffect(() => {
    if (defaultValue) setValue(name, defaultValue)
  }, [defaultValue, setValue, name])

  const errorMessage = get(errors, name)?.message

  return (
    <FormControl isInvalid={!!errorMessage} mb={4} isRequired={isRequired}>
      <FormLabel color={color} htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <NumberInput
            name={name}
            value={field.value ?? ''}
            onChange={e => field.onChange(e === '' ? null : e)}
            step={typeof withStepper === 'number' ? withStepper : 1}
            errorBorderColor="#707070"
          >
            <NumberInputField
              border="none"
              borderRadius="0"
              borderBottom={`1px solid ${errorMessage ? 'alert' : borderColor}`}
              p={0}
              placeholder={placeholder}
              _focus={{}}
              color={color}
              _hover={{ borderColor: borderColor }}
              hidden={hidden}
              outline="transparent"
            />
            {withStepper && (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            )}
          </NumberInput>
        )}
      />
      <FormErrorMessage name={label}>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default Number
