import {useRouter as useNextRouter} from 'next/router'
import {TransitionOptions, Url} from '_app/types/router'
import type {ParsedUrlQueryInput} from 'querystring'
import URLHelper from '../../helpers/URLHelper/URLHelper'
import LangEnum from '../../enums/LangEnum/LangEnum'

export const useRouter = () => {
  const router = useNextRouter()
  const locale = !router.locale ? LangEnum.DEFAULT_LANG : router.locale
  const search = async (params: ParsedUrlQueryInput = {}) => {
    const { asPath, locale } = router
    const basePath = `${locale ? `/${locale}` : ''}${asPath.split('?')[0]}`
    await router.push({ pathname: basePath, query: params })
  }

  const push = async (url: Url, as?: Url, options?: TransitionOptions) => {
    if (typeof url === 'string') {
      url = URLHelper.translateHref(url, locale)
    }
    await router.push(url, as, options)
  }
  return {
    ...router,
    search,
    push,
    locale,
  }
}
