import { setLocale } from 'yup'

export const setupI18NFormErrors = () =>
  setLocale({
    mixed: {
      required: 'errors.mixed.required',
      wrongData: 'errors.mixed.wrongData',
    },
    string: {
      email: 'errors.string.email',
      min: ({ min }) => ({ key: 'errors.string.min', values: { min } }),
      max: ({ max }) => ({ key: 'errors.string.max', values: { max } }),
    },
  })
