import { FormErrorMessage as ChakraFormErrorMessage } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

const FormErrorMessage: any = ({ name, children }) => {
  const { t }: any = useTranslation('errors')

  return (
    <ChakraFormErrorMessage color="alert">
      {t(children?.key || children, {
        path: name,
        ...(children?.values || {}),
      })}
    </ChakraFormErrorMessage>
  )
}

export default FormErrorMessage
