import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ClockIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="105 105 541 541" fill="#000000" {...props}>
      <path d="m376 165.26c116.2 0 210.74 94.543 210.74 210.74 0 116.2-94.543 210.75-210.74 210.75-116.2 0-210.75-94.543-210.75-210.75 0-116.2 94.543-210.74 210.75-210.74zm0 397.81c103.14 0 187.07-83.918 187.07-187.07-0.003906-103.14-83.922-187.06-187.07-187.06-103.15 0-187.07 83.918-187.07 187.06 0 103.15 83.918 187.07 187.07 187.07zm0-346.5c6.5391 0 11.84 5.3008 11.84 11.84v142.56l77.66 74.645c4.7109 4.5312 4.8594 12.027 0.33203 16.742-2.3242 2.4219-5.4258 3.6367-8.5391 3.6367-2.9492 0-5.9102-1.0938-8.2031-3.3008l-81.301-78.145c-2.3164-2.2383-3.6289-5.3164-3.6289-8.543v-147.59c0-6.543 5.2969-11.84 11.84-11.84z" />
    </Icon>
  )
}
