import { extendTheme } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'

const theme = extendTheme()

export const Button: any = {
  baseStyle: {
    fontWeight: '600',
    borderRadius: '0',
    minW: '13rem',
    minWidth: '13rem',
    height: '3.125rem',
    minHeight: '3.125rem',
    padding: '1rem 5.25rem 1rem 5.25rem',
    transitionDuration: 'inherit',
    _disabled: {
      opacity: 0.5,
    },
  },
  variants: {
    link: {
      minW: '10px',
      minWidth: '10px',
    },
    outline: {
      color: 'currentColor.500',
    },
    ghost: {
      textDecoration: 'underline',
    },
    icon: {
      border: 'none',
      w: '1.5rem',
      minWidth: '1.5rem',
      h: '1.5rem',
      minHeight: '1.5rem',
      padding: '1.25rem',
    },
    thin: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.outline(props),
      height: '2.375rem',
      minHeight: '2.375rem',
      minWidth: 'auto',
    }),
  },
  defaultProps: {
    variant: 'solid',
    size: 'sm',
  },
}
