import { useFormContext } from 'react-hook-form'
import { BundleProduct, Money, SimpleProduct } from '_app/types/api'

const isBundleProduct = (
  product: BundleProduct | SimpleProduct
): product is BundleProduct =>
  typeof (product as BundleProduct).items !== 'undefined'

export const useDynamicProductPrice = (
  product: BundleProduct | SimpleProduct,
  hideSelectedOptions?: boolean,
) => {
  const final_price = product?.price_range?.minimum_price?.final_price
  const regular_price = product?.price_range?.minimum_price?.regular_price
  let displayedPrice = 0
  let selectedOptionsIds: any = []
  let optionsPrice: any = []

  if (!isBundleProduct(product)) {
    const isPromotion = final_price?.value !== regular_price?.value
    return { price: final_price, regularPrice: regular_price, isPromotion }
  }
  if (!hideSelectedOptions) {
    const { watch } = useFormContext()
    const selectedOptions = watch('selected_options')

    selectedOptionsIds = Array.isArray(selectedOptions)
      ? selectedOptions.flat()
      : []

    const allAvailableOptions =
      product.items?.flatMap(item =>
        item?.options?.map(option => ({
          price:
            option?.product?.price_range?.minimum_price?.final_price?.value || 0,
          uid: option?.uid,
          quantity: option?.quantity || 0,
        }))
      ) || []

    optionsPrice = allAvailableOptions.reduce((sum, option) => {
      if (option?.price && selectedOptionsIds.includes(option.uid)) {
        return sum + option.price * option.quantity
      }

      return sum
    }, 0)
  }

  displayedPrice =
    selectedOptionsIds?.length > 0 && selectedOptionsIds.every(id => typeof id === 'string') ? optionsPrice : final_price.value || 0

  const promotionAmount = (regular_price.value || 0) - (final_price.value || 0)
  const isPromotion = promotionAmount > 0
  const currency = regular_price.currency

  const price: Money = {
    value: displayedPrice,
    currency,
  }

  const regularPrice: Money = {
    value: product.price_range.minimum_price.regular_price.value,
    currency,
  }

  return { price, regularPrice, isPromotion }
}
