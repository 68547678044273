import { FC } from 'react'
import { clamp } from 'lodash-es'
import { baseTheme, Box, BoxProps } from '@chakra-ui/react'

export interface BadgeProps extends BoxProps {
  value?: number;
  maxValue?: number;
  inline?: boolean;
  right?: number;
  hide?: boolean;
}

const Badge: FC<BadgeProps> = ({
   children,
   value,
   maxValue = 99,
   inline = false,
   hide = false,
   right = 0,
   ...boxProps
}) => {

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      position="relative"
      {...boxProps}
    >
      {children}
      {
        !hide && (
          <span
            style={{
              width: '20px',
              height: '20px',
              background: '#fcca9d',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: baseTheme.fonts.body,
              zIndex: 1,
              borderRadius: '50%',
              fontSize: '11px',
              position: 'absolute',
              top: '-7px',
              right: '-14px',
            }}
          >
          {value && clamp(value, maxValue)}
        </span>
        )
      }
    </Box>
  )
}

export default Badge
