import {Accordion, Box, Container, Grid, HStack, IconButton, Text,} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'
import styled from '@emotion/styled'
import {useRouter} from '_app/hooks/useRouter'
import {useGetLayoutQuery} from '_app/graphql/cms/generated/graphql'
import theme from 'theme'
import {langOptions} from '_app/utils/localized/getLangOptions'
import {getSocialIcon} from './helpers'
import {FooterGroup} from './FooterGroup'
import FlagHelper from '../../../helpers/FlagHelper/FlagHelper'
import RequestEnum from '../../../enums/RequestEnum/RequestEnum'
import URLHelper from '../../../helpers/URLHelper/URLHelper'

const StyledGrid = styled(Grid)`
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: ${theme.breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`

const Footer = ({ data }) => {
  const { t } = useTranslation()
  if (!data?.footer?.data?.attributes) return null
  const { columns, company, contact, social_media } = data.footer.data.attributes
  return (
    <Box borderTop="1px solid #e5e5e5">
      <Container>
        <Accordion allowToggle>
          <StyledGrid>
            {columns?.map(
              (column, idx) =>
                column && (
                  <FooterGroup
                    key={`footer[${idx}]`}
                    column={column}
                    withAccordion
                  />
                )
            )}
          </StyledGrid>
          <StyledGrid>
            {company && (
              <FooterGroup
                content={{ title: t('COMPANY_DATA'), description: company }}
              />
            )}
            {contact && (
              <FooterGroup
                content={{ title: t('CONTACT'), description: contact }}
              />
            )}

            {social_media && !!social_media.length && (
              <Box>
                <Text mb="1.5rem">{t('FIND_US_ON')}</Text>
                <HStack spacing="1rem">
                  {social_media.map(
                    (social, idx) =>
                      social?.text && (
                        <Box cursor="pointer" key={`socials[${idx}]`}>
                          <a href={URLHelper.createURL(social)} aria-label={social.text} target="_blank">
                            {getSocialIcon(social.text)}
                          </a>
                        </Box>
                      )
                  )}
                </HStack>
              </Box>
            )}
            <Box>
              <Text mb="0.75rem">{t('LOCATION')}</Text>
              <Box
                display="grid"
                columnGap="3"
                gridTemplateColumns="repeat(auto-fit, 28px)"
                gridTemplateRows="2"
              >
                {langOptions.map(option => {
                  return (
                    <IconButton
                      variant="unstyled"
                      minWidth="0"
                      minHeight="0"
                      px="0"
                      onClick={() => {
                        if (option.countryCode === 'AT') {
                          window.location.assign('https://lilouparis.de')
                        } else {
                          window.location.assign(option.url)
                        }
                      }}
                      key={option.countryCode}
                      aria-label={`Flag-${option.countryCode}`}
                      icon={
                        <Box border="1px solid black" width="28px">
                          {FlagHelper.get(option.countryCode)}
                        </Box>
                      }
                    />
                  )
                })}
              </Box>
            </Box>
          </StyledGrid>
        </Accordion>
      </Container>
      <Box bg="main" py="1.5rem">
        <Container>
          <Text fontSize="0.875rem">{t('COPYRIGHT_INFO')}</Text>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
