import { useEffect, useMemo, useState } from 'react'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { v4 as uuid } from 'uuid'
import { isArray } from 'lodash-es'
import { BundleProduct } from '_app/graphql/generated/graphql'
import {useGetProductsListQuery} from '../graphql/queries/category/GetProductsList'

interface ViewProductProps {
  url_key?: string;
}

interface ViewProduct {
  lastViewedList: BundleProduct[];
}

const useViewProduct = ({ url_key }: ViewProductProps): ViewProduct => {
  const cookies = parseCookies() ?? {}
  const [lastViewedList, setLastViewedList] = useState<any>([])
  const [sortedList, setSortedList] = useState<string[]>([])
  const [productUrlKey, setProductUrlKey] = useState(url_key)
  const allLastViewedCookies = useMemo(
    () =>
      Object.entries(cookies)
        .filter(([key]) => key.includes('lastViewed-'))
        .map(([key, value]) => ({
          key,
          value,
        })),
    [cookies]
  )

  const lastViewedSkusToDisplay = Object.entries(cookies)
    .filter(
      ([key, value]) =>
        key.includes('lastViewed-') &&
        JSON.parse(value)?.url_key !== productUrlKey
    )
    .map(([_, value]) => JSON.parse(value)?.url_key)

  const { data } = useGetProductsListQuery({
    variables: {
      search: '',
      pageSize: 8,
      currentPage: 1,
      filter: { url_key: { in: lastViewedSkusToDisplay } },
    },
    skip: lastViewedList?.length > 0,
  })

  useEffect(() => {
    if (url_key && productUrlKey) {
      const viewed = Object.entries(parseCookies())
        .filter(([key]) => key.includes('lastViewed-'))
        .map(([key, value]) => ({
          key,
          value,
        }))
        .find(({ value }) => JSON.parse(value)?.url_key === url_key)

      if (viewed) {
        destroyCookie(null, `${viewed.key}`)
      }

      // setSortedList(
      //   Object.entries(cookies)
      //     .filter(
      //       ([key, value]) =>
      //         key.includes('lastViewed-') &&
      //         JSON.parse(value)?.url_key !== productUrlKey
      //     )
      //     .sort(
      //       (
      //         a: [key: string, value: string],
      //         b: [key: string, value: string]
      //       ) => {
      //         const ageA = JSON.parse(a[1])?.createdAt
      //         const ageB = JSON.parse(b[1])?.createdAt
      //
      //         return ageA > ageB ? 1 : -1
      //       }
      //     )
      //     .map(([_, value]) => JSON.parse(value)?.url_key)
      // )
    }
  }, [productUrlKey])

  const setCookies = (cookieSku: string) => {
    const currentCookie =
      isArray(allLastViewedCookies) &&
      allLastViewedCookies?.find(item => item.value === cookieSku)?.key
    if (allLastViewedCookies.length < 8) {
      if (currentCookie) destroyCookie(null, currentCookie)
      setCookie(
        null,
        `lastViewed-${uuid()}`,
        JSON.stringify({ url_key: cookieSku, createdAt: new Date() }),
        { maxAge: 20 * 365 * 24 * 60 * 60 }
      )
    } else {
      const theOldestCookie = allLastViewedCookies.sort((a, b) => {
        const ageA = JSON.parse(a.value)?.createdAt
        const ageB = JSON.parse(b.value)?.createdAt

        return ageA > ageB ? 1 : -1
      })[0]

      destroyCookie(null, `${theOldestCookie.key}`)

      if (!currentCookie) {
        setCookie(
          null,
          `lastViewed-${uuid()}`,
          JSON.stringify({ url_key: cookieSku, createdAt: new Date() }),
          { maxAge: 20 * 365 * 24 * 60 * 60 }
        )
      }
    }
  }

  useEffect(() => {
    if (data?.products?.items) {
      setLastViewedList(data?.products?.items)
    }
  }, [data])

  const changeProduct = async () => {
    if (productUrlKey && productUrlKey !== url_key) {
      setCookies(productUrlKey)
    }
    setProductUrlKey(url_key)
  }

  useEffect(() => {
    changeProduct()
  }, [url_key])

  useEffect(() => {
    return () => {
      if (url_key) setCookies(url_key)
    }
  }, [])

  return {
    lastViewedList,
  }
}

export default useViewProduct
