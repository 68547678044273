import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react'
import {
  Maybe,
  ComponentPartsLink,
  ComponentNavigationColumn,
} from '_app/graphql/cms/generated/graphql'

import { NavigationListItem } from './NavigationListItem'

interface SubitemListProps {
  subitem: Maybe<ComponentNavigationColumn>;
}

export const SubitemListItem = ({ subitem }: SubitemListProps) => {
  if (subitem?.title === 'Polecane') {
    return (
      <>
        {subitem?.items?.map((suggestions: Maybe<ComponentPartsLink>) => (
          <NavigationListItem
            key={suggestions?.id}
            text={suggestions?.text}
            link={suggestions}
            isBold
            showBorderBottom
          />
        ))}
      </>
    )
  }

  return (
    <Accordion defaultIndex={[0]}>
      <AccordionItem key={subitem?.id}>
        <AccordionButton height={14} pl={0}>
          <Box flex="1" textAlign="left" sx={{ color: subitem?.title?.toLowerCase() === 'special prices' ? 'red' : 'auto'}}>
            {subitem?.title}
          </Box>
        </AccordionButton>
        <AccordionPanel pt={0} pb={0}>
          {subitem?.items?.map(categories => (
            <NavigationListItem
              key={categories?.id}
              text={categories?.text}
              link={categories}
            />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
