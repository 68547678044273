import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function JoyIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <circle
        data-name="Ellipse 240"
        cx="12"
        cy="12"
        r="12"
        transform="translate(4 4)"
      />
      <path data-name="Line 287" transform="translate(12 12)" d="M0 0h0" />
      <path data-name="Line 288" transform="translate(20 12)" d="M0 0h0" />
      <path
        data-name="Path 576"
        d="M8 13a3.987 3.987 0 0 0 7.974 0m0 0H8"
        transform="translate(4.013 5.007)"
      />
    </Icon>
  )
}
