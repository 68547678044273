import { createContext, ReactNode, useContext, useMemo } from 'react'

export enum FLAGS {
  ENGRAVABLE_BASE = 'ENGRAVABLE_BASE',
}

type FlagshipContext = {
  [key in FLAGS]: boolean;
};

export const FlagshipContext = createContext<FlagshipContext | null>(null)

const parseEnvFlagToBoolean = (env: string | undefined) => Number(env) === 1

interface Props {
  children: ReactNode;
}
export const FlagshipProvider = ({ children }: Props) => {
  const value = useMemo(
    () => ({
      [FLAGS.ENGRAVABLE_BASE]: false,
    }),
    []
  )

  return (
    <FlagshipContext.Provider value={value}>
      {children}
    </FlagshipContext.Provider>
  )
}

export const useFlagshipContext = () => {
  const context = useContext(FlagshipContext)

  if (!context) {
    throw new Error(
      'useFlagshipContext must be used within a FlagshipProvider'
    )
  }

  return context
}
