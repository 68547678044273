import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function SavedIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M13.5 7.5a2 2 0 0 1 2 2v12l-5-3-5 3v-12a2 2 0 0 1 2-2z" />
      <path d="M9.5 5.5c.358-.62 1.284-2 2-2h6a2 2 0 0 1 2 2v12l-1-1" />
    </Icon>
  )
}
