import { parseCookies } from 'nookies'
import { useEffect } from 'react'
import stringHash from 'string-hash'
import aa from 'search-insights'
import useCustomer from '_app/hooks/useCustomer'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

export const useUserToken = () => {
  const { customer } = useCustomer()
  const { _ALGOLIA } = parseCookies() ?? {}
  const userToken = customer?.email ? stringHash(customer.email).toString() : _ALGOLIA
  useEffect(() => {
    try {
      aa('setUserToken', userToken)
      LocalStorageHelper.set('algoliaTokenUser', userToken)
    } catch (error) {
      console.error('Error setting user token:', error)
    }
  }, [userToken])
  return userToken
}