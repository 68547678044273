import {Box, Container, Text, VStack} from '@chakra-ui/react'
import {Swiper, SwiperSlide} from 'swiper/react'
import React, {useState} from 'react'
import CarouselSection from '_app/components/CarouselSection'
import {ImgProps} from '_app/types/content'
import {StorySlide} from './StorySlide'

interface StoryCarouselProps {
  title: string;
  items: {
    description?: string;
    year: number;
    img: ImgProps;
  }[];
}

const StoryCarousel = ({ title, items = [] }: StoryCarouselProps) => {
  let slides: React.ReactNode[] = []

  if (!!items.length) {
    slides = items.map(item => (
      <VStack as="span" w="100%" key={`${item.year}`} spacing="30px" mt={8}>
        <StorySlide {...item} />
      </VStack>
    ))
  }

  const [activeSlide, setActiveSlide] = useState(0)

  return (
    <>
      <Box
        display={{
          base: 'block',
          lg: 'none',
        }}
      >
        <Container pr={{ base: 0, md: '16px' }}>
          {title && (
            <Text mb={6} textStyle="sectionHeader">
              {title}
            </Text>
          )}
          <Swiper
            slidesPerView={1.5}
            spaceBetween={30}
            onSlideChange={swiper => setActiveSlide(swiper?.activeIndex)}
          >
            {items.map((item, index) => (
              <SwiperSlide key={`item-slide-[${index}]`}>
                <StorySlide {...item} isActive={activeSlide === index} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
      <Box
        m="auto"
        display={{
          base: 'none',
          lg: 'block',
        }}
      >
        <CarouselSection
          slidesPerView={3}
          withLayer
          swiperProps={{ spaceBetween: 120 }}
          title={<Text textStyle="sectionHeader">{title}</Text>}
          slides={slides}
        />
      </Box>
    </>
  )
}

export default StoryCarousel
