import configuration from '../../assets/json/configuration.json'

export default {
    getConfiguration: (name: string, router: any = null) => {
        const tempConfiguration: any = []
        configuration?.forEach((el: any) => {
            if (el?.storeCode === router?.locale) {
                tempConfiguration.push({ storeCode: el?.storeId, storeId: el?.storeId, key: 'storeData' })
                if (el?.settings) {
                    el.settings?.forEach((element: any) => {
                        tempConfiguration.push(element)
                    })
                }
            }
        })
        const foundConfig = tempConfiguration?.filter((el: { key: string }) => el.key === name)[0]
        return foundConfig ? foundConfig.value : null
    },
}