import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function UserIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle
        id="Ellipse_8"
        data-name="Ellipse 8"
        cx="4"
        cy="4"
        r="4"
        transform="translate(8 3)"
      />
      <path
        id="Path_391"
        data-name="Path 391"
        d="M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"
      />
    </Icon>
  )
}
