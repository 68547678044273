import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function CertificateIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle
        data-name="Ellipse 246"
        cx="3"
        cy="3"
        r="3"
        transform="translate(12 12)"
      />
      <path data-name="Path 529" d="M13 17.5V22l2-1.5 2 1.5v-4.5" />
      <path
        data-name="Path 530"
        d="M10 19H5a2 2 0 0 1-2-2V7a2.006 2.006 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-1 1.73"
      />
      <path data-name="Line 286" transform="translate(6 9)" d="M0 0h12" />
      <path data-name="Line 287" transform="translate(6 12)" d="M0 0h3" />
      <path data-name="Line 288" transform="translate(6 15)" d="M0 0h2" />
    </Icon>
  )
}
