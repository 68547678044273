import { CategoryInterface, Maybe } from '_app/graphql/generated/graphql'

type Categories = Maybe<Array<Maybe<CategoryInterface>>>;

const doesMatchSomeCategoryName = (categories: Categories, name: string) => {
  if (!Array.isArray(categories)) {
    return false
  }
  return categories?.some(cat => cat?.name?.toLowerCase() === name)
}

export const isBraceletProductType = (
  categories: Categories,
  locale?: string
) => {
  let name: string
  switch (locale) {
    case 'en':
      name = 'bracelets'
      break
    case 'de':
      name = 'armbänder'
      break
    case 'fr':
      name = 'bracelets'
      break
    default:
      name = 'bransoletki'
      break
  }

  return doesMatchSomeCategoryName(categories, name)
}

export const isNecklaceProductType = (
  categories: Categories,
  locale?: string
) => {
  let name: string
  switch (locale) {
    case 'en':
      name = 'necklaces'
      break
    case 'de':
      name = 'halsketten'
      break
    case 'fr':
      name = 'colliers'
      break
    default:
      name = 'naszyjniki'
      break
  }

  return doesMatchSomeCategoryName(categories, name)
}

export const isRingProductType = (categories: Categories, locale?: string) => {
  let name: string
  switch (locale) {
    case 'en':
      name = 'rings'
      break
    case 'de':
      name = 'ringe'
      break
    case 'fr':
      name = 'bagues'
      break
    default:
      name = 'pierścionki'
      break
  }

  return doesMatchSomeCategoryName(categories, name)
}
