import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ChevronUpIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M4.923 9 0 4.376 4.923 0" transform="rotate(90 3.461 13.039)" />
    </Icon>
  )
}
