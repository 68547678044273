import {Box, Button, Center, Container, FormLabel, Text} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {Configure, InstantSearch} from 'react-instantsearch'
import {useRecoilValue} from 'recoil'
import {showTopBarAtom} from '_app/state/atoms'
import searchClient from '_app/utils/getAlgoliaSearchClient'
import getAlgoliaIndex from '_app/utils/localized/getAlgoliaIndex'
import SearchHits from './SearchHits'
import {useUserToken} from './useUserToken'
import ElementsSizesEnum from '../../../enums/ElementsSizesEnum/ElementsSizesEnum'
import {GTM_EVENTS} from '../../utils/dataLayer'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

interface SearchCollapseProps {
    expanded: {
        isExpanded: boolean;
        setExpanded: Dispatch<SetStateAction<boolean>>;
    };
    collapse: { getCollapseProps(): object; getToggleProps(...{}): void };
    filter?: boolean;
    enableInfiniteScroll?: boolean;
}
const SearchCollapse = ({
                            expanded: {isExpanded, setExpanded},
                            collapse: {getCollapseProps},
                            filter = true,
                            enableInfiniteScroll,
                        }: SearchCollapseProps) => {
    const {events, locale} = useRouter()
    const {t} = useTranslation()
    const ref = useRef<HTMLInputElement | null>(null)
    const showTopBar = useRecoilValue(showTopBarAtom)
    const userToken = useUserToken()
    const indexName = getAlgoliaIndex(locale)
    const additionalSpace = showTopBar ? '2rem' : '0px'
    const [searchQuery, setSearchQuery] = useState('')
    const [searchHits, setSearchHits] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [isLastPage, setIsLastPage] = useState(false)
    const getIds = (hits: any) => {
        const tempIds: any = []
        hits?.map((el: any) => {
            if (typeof el?.sku === 'object') {
                el?.sku?.forEach(element => {
                    tempIds.push(element)
                })
            } else {
                tempIds.push(el.sku)
            }
        })
        return tempIds
    }
    const handleSearch = () => {
        if (searchQuery.length > 0) {
            searchClient
                .search([
                    {
                        indexName,
                        params: {
                            query: searchQuery,
                            filters: 'categoryIds:1268',
                            hitsPerPage: filter ? 100 : 48,
                            clickAnalytics: true,
                            userToken,
                        },
                    },
                ])
                .then((response: any) => {
                    const hits = response.results[0].hits
                    setSearchHits(hits)
                    setIsLastPage(response.results[0].nbPages === response.results[0].page + 1)
                    GTM_EVENTS.search(searchQuery, hits?.length, getIds(hits), hits)
                })
                .catch((error) => {
                    console.error('Search error:', error)
                })
        }
    }
    useEffect(() => {
        const setExpandedFalse = () => setExpanded(false)
        events.on('routeChangeStart', setExpandedFalse)
        return () => {
            events.off('routeChangeStart', setExpandedFalse)
        }
    }, [])

    useEffect(() => {
        if (isExpanded) {
            const algoliaSearchInput = document.getElementsByClassName('algolia-search-input') as HTMLCollectionOf<HTMLElement>
            if (algoliaSearchInput !== null && algoliaSearchInput[0]) {
                algoliaSearchInput[0].focus({preventScroll: true})
            }
        }
    }, [isExpanded])
    useEffect(() => {
        if (isExpanded) {
            setTimeout(() => {
                if (ref.current) {
                    const inputElement = ref.current as HTMLInputElement
                    inputElement.focus()
                    inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length)
                }
            }, 100)
        }
    }, [isExpanded])
    return (
        <Box
            {...getCollapseProps()}
            width="100%"
            position="fixed"
            left={0}
            right={0}
            top={{
                base: ElementsSizesEnum.NAVBAR_HEIGHT.base,
                lg: `calc(${ElementsSizesEnum.NAVBAR_HEIGHT.lg} + ${additionalSpace})`,
            }}
            bottom={0}
            maxH={{
                base: `calc(100vh - ${ElementsSizesEnum.NAVBAR_HEIGHT.base})`,
                lg: `calc(100vh - ${ElementsSizesEnum.NAVBAR_HEIGHT.lg})`,
            }}
            zIndex={1000}
            display="flex"
            flexDirection="column"
            overflowY="scroll"
            bg="white"
        >
            <Box py={{base: 4, md: 6}}>
                <Container width="100%" data-insights-index={indexName} position="relative" paddingTop="30">
                    <Box
                        position="absolute"
                        right="-4px"
                        top="10px"
                        marginTop="10px"
                        paddingRight="5"
                        paddingLeft="5"
                        transform="translateY(-50%)"
                        cursor="pointer"
                        onClick={() => {
                            setExpanded(false)
                        }}
                        display="flex"
                        alignItems="center"
                    >
                        <Text fontSize="16">{t('CLOSE_SEARCH')}</Text>
                    </Box>
                    {isExpanded && (
                        <InstantSearch
                            searchClient={searchClient}
                            indexName={indexName}
                            future={{preserveSharedStateOnUnmount: true}}
                        >
                            {
                                filter ? (
                                    <Configure
                                        filters={'categoryIds:1268'}
                                        hitsPerPage={100}
                                        clickAnalytics
                                        userToken={userToken}
                                    />
                                ) : (
                                    <Configure
                                        filters={'categoryIds:1268'}
                                        hitsPerPage={48}
                                        clickAnalytics
                                        userToken={userToken}
                                    />
                                )
                            }
                            <Center mt={{base: 0, md: 6}} mb={{base: 4, md: 12}}>
                                <Box sx={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: BreakpointsHelper.isMobile() ? 'column': 'row'}}>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            width: BreakpointsHelper.isMobile() ? '100%' : 'auto',
                                        }}
                                    >
                                        <FormLabel as={Text}>{t('FIND_PRODUCT')}</FormLabel>
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: BreakpointsHelper.isMobile() ? '100%' : 'auto',
                                            }}
                                        >
                                            <input
                                                ref={ref}
                                                className="algolia-search-input"
                                                placeholder={t('WHAT_YOU_ARE_LOOKING_FOR')}
                                                value={searchQuery}
                                                onChange={(e) => {
                                                    if (e.target.value?.length >= 3) {
                                                        setDisabled(false)
                                                    } else {
                                                        setDisabled(true)
                                                    }
                                                    setSearchQuery(e.target.value)}
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSearch()
                                                    }
                                                }}
                                                style={{
                                                    width: '100%',
                                                    padding: '10px',
                                                    fontSize: '16px',
                                                }}
                                            />
                                            {searchQuery && (
                                                <Box
                                                    position="absolute"
                                                    right="10px"
                                                    top="50%"
                                                    paddingRight="5"
                                                    paddingLeft="5"
                                                    transform="translateY(-50%)"
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        setSearchQuery('')
                                                    }}
                                                >
                                                    <Text fontSize="24">&times;</Text>
                                                </Box>
                                            )}
                                        </div>
                                    </Box>
                                    <Button
                                        sx={{
                                            marginLeft: BreakpointsHelper.isMobile() ? '0' : '20px',
                                            height: '58px',
                                            width: BreakpointsHelper.isMobile() ? '100%' : 'auto',
                                            marginTop: BreakpointsHelper.isMobile() ? '20px' : '0',
                                            opacity: disabled ? .5 : 1,
                                            cursor: disabled ? 'auto' : 'pointer'
                                        }}
                                        disabled={disabled}
                                        onClick={handleSearch}
                                    >
                                        {t('SEARCH')}
                                    </Button>
                                </Box>
                            </Center>
                            <SearchHits
                                hits={searchHits}
                                enableInfiniteScroll={enableInfiniteScroll}
                                isLastPage={isLastPage}
                                onLoadMore={handleSearch}
                            />
                        </InstantSearch>
                    )}
                </Container>
            </Box>
        </Box>
    )
}

export default SearchCollapse
