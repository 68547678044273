import { Text } from '@chakra-ui/react'

export const DisplayDescription = ({
  description,
}: {
  description?: string;
}) => {
  return (
    <Text whiteSpace="pre-line" color="inherit" mb={8} sx={{ fontWeight: 600, maxWidth: '520px', minHeight: '80px' }}>
      {description}
    </Text>
  )
}
