export const ourValues = [
  {
    img: '/images/about/about_values_1.jpg',
    img_mobile: '/images/about/about_values_mobile_1.webp',
    title: 'QUALITY',
    description: 'QUALITY_DESC',
  },
  {
    img: '/images/about/about_values_2.webp',
    img_mobile: '/images/about/about_values_mobile_2.webp',
    title: 'UNIQUENESS',
    description: 'UNIQUENESS_DESC',
  },
  {
    img: '/images/about/about_values_3.webp',
    img_mobile: '/images/about/about_values_mobile_3.webp',
    title: 'HANDICRAFT',
    description: 'HANDICRAFT_DESC',
  },
  {
    img: '/images/about/about_values_4.webp',
    img_mobile: '/images/about/about_values_mobile_4.webp',
    title: 'FEMININITY',
    description: 'FEMININITY_DESC',
  },
]
