import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function LockIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 64 64" {...props}>
      <rect width="36.609" height="27" rx="2" transform="translate(14 29)" />
      <path
        d="M8 26.146V12.732a10.732 10.732 0 1 1 21.464 0"
        transform="translate(13.268 2.366)"
      />
    </Icon>
  )
}
