import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function Minus(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        data-name="Line 12"
        transform="translate(6.5 12.5)"
        stroke="#242424"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1px"
        d="M0 0h12"
      />
    </Icon>
  )
}
