import { Box } from '@chakra-ui/react'
import { useEffect, useState, useRef, ChangeEvent } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { useOutsideClick } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'next-i18next'
import theme from 'theme'
import { GET_COUNTRIES } from '_app/graphql/queries/customer/GetCountries'
import { handleAvailableSuggestions, Suggestion } from 'modules/checkout/utils'
import Input from '_app/components/Form/Input'
import toast from '_app/utils/toast'
import { useRouter } from '_app/hooks/useRouter'
import { getRegionFromAutoComplete } from 'modules/checkout/utils/regions'
import useLoadGooglePlacesApi from '_app/hooks/useLoadGooglePlacesApi'

interface PlacesAutocompleteProps {
  name: string;
  defaultValue?: string | null;
  streetKey?: string;
  streetNumberKey?: string;
  cityKey?: string;
  countryKey?: string;
  postcodeKey?: string;
  regionKey?: string;
}

const PlacesAutocomplete = ({
  name,
  defaultValue,
  streetNumberKey,
  cityKey,
  countryKey,
  postcodeKey,
  regionKey,
}: PlacesAutocompleteProps) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const [focused, setFocused] = useState(false)
  const [addressComponents, setAddressComponents] = useState<any>(null)
  const [availableSuggestions, setAvailableSuggestions] = useState<
    Suggestion[]
  >([])
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const { data: availableCountriesData } = useQuery(GET_COUNTRIES)

  const { setValue: setFormValue, trigger, control } = useFormContext()

  const ref = useRef<HTMLInputElement>(null)
  useOutsideClick({
    ref,
    handler: onBlur,
  })

  const {
    value,
    suggestions: { status, data },
    setValue,
    init,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
    },
    initOnMount: false,
  })
  useLoadGooglePlacesApi(init)

  const countryCode = useWatch({
    control,
    name: `${countryKey}`,
  })

  useEffect(() => {
    if (addressComponents) {
      const streetNumber = addressComponents.find((component: any) =>
        component?.types?.includes('street_number')
      )?.long_name

      if (streetNumberKey) setFormValue(streetNumberKey, streetNumber ?? '')

      const city = addressComponents.find((component: any) =>
        component?.types?.includes('locality')
      )?.long_name

      if (cityKey) setFormValue(cityKey, city ?? '')

      const country = addressComponents.find((component: any) =>
        component?.types?.includes('country')
      )?.short_name

      if (countryKey) setFormValue(countryKey, country ?? '')

      const postcode = addressComponents.find((component: any) =>
        component?.types?.includes('postal_code')
      )?.long_name

      if (postcodeKey)
        setFormValue(
          postcodeKey,
          postcode
            ? country !== 'PL' || postcode.length === 6
              ? postcode
              : ''
            : ''
        )

      const regionName = addressComponents.find((component: any) =>
        component?.types?.includes('administrative_area_level_1')
      )?.long_name
      const region = getRegionFromAutoComplete(
        availableCountriesData?.countries,
        regionName,
        country
      )

      if (regionKey) setFormValue(regionKey, region?.id ?? undefined)

      const fieldsToUpdate: string[] = [];

      [
        {
          key: postcodeKey,
          value:
            country !== 'PL'
              ? postcode
              : postcode?.length === 6
              ? postcode
              : false,
        },
        { key: cityKey, value: city },
        { key: countryKey, value: country },
        { key: streetNumberKey, value: streetNumber },
        { key: regionKey, value: region?.id?.toString() },
      ].forEach(({ key, value }: { key?: string; value?: string }) => {
        if (typeof key === 'string' && typeof value === 'string')
          fieldsToUpdate.push(key)
      })

      if (fieldsToUpdate.length > 0) trigger(fieldsToUpdate)
    }
  }, [
    addressComponents,
    cityKey,
    postcodeKey,
    countryKey,
    setFormValue,
    streetNumberKey,
    regionKey,
    trigger,
    availableCountriesData?.countries,
  ])

  const handleSelect = (option: any) => async () => {
    onBlur()
    let address
    try {
      address = await geocodeByPlaceId(option?.place_id)
      setAddressComponents(address?.[0]?.address_components ?? null)
    } catch (error) {
      toast(error)
    }

    const street = address?.[0]?.address_components?.find((component: any) =>
      component?.types?.includes('route')
    )?.long_name

    setFormValue(name, street ?? '')
    setValue(street ?? '', false)
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValue(name, e.target.value)
    setValue(e.target.value)
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue, setValue])

  useEffect(() => {
    setAvailableSuggestions(
      handleAvailableSuggestions({
        data: availableCountriesData,
        destinationCode: countryCode,
        suggestions: data,
        locale,
      })
    )
  }, [availableCountriesData, data, countryCode])

  const renderSuggestions = () =>
    availableSuggestions.map(({ suggestion }) => (
      <Box
        p={2}
        as="li"
        border="none"
        cursor="pointer"
        key={suggestion.place_id}
        _hover={{ color: 'white', bg: theme.colors.hoverDark }}
        onClick={handleSelect(suggestion)}
      >
        {suggestion.description}
      </Box>
    ))

  return (
    <Box width="100%" position="relative" ref={ref}>
      <Input
        placeholder={t('STREET_PLACEHOLDER')}
        name={name}
        defaultValue={defaultValue || ''}
        label={t('STREET_LABEL')}
        value={value}
        onChange={handleInput}
        onFocus={onFocus}
        isRequired
      />
      {status === 'OK' && focused && availableSuggestions.length > 0 && (
        <Box
          py={2}
          width="100%"
          bg="white"
          zIndex={9}
          border="1px solid black"
          position="absolute"
          listStyleType="none"
          as="ul"
        >
          {renderSuggestions()}
        </Box>
      )}
    </Box>
  )
}

export default PlacesAutocomplete
