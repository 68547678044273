import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ShareIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect width="18" height="14" rx="2" transform="translate(3.5 5.5)" />
      <path d="M3.5 7.5l9 6 9-6" />
    </Icon>
  )
}
