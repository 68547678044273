import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ArrowRightIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path data-name="Line 27" transform="translate(4.5 12.5)" d="M14 0H0" />
      <path data-name="Line 28" transform="translate(14.5 12.5)" d="M4 0 0 4" />
      <path data-name="Line 29" transform="translate(14.5 8.5)" d="M4 4 0 0" />
    </Icon>
  )
}
