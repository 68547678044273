import NextLink from 'next/link'
import {useRouter} from '_app/hooks/useRouter'
import URLHelper from '../../../helpers/URLHelper/URLHelper'

const Link: any = (props: any) => {
  const { locale } = useRouter()
  const { href } = props
  const translatedHref = URLHelper.translateHref(href, locale)
  return <NextLink {...props} href={translatedHref} />
}

export default Link
