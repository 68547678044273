import {
  Box,
  Flex,
  HStack,
  ListItem,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
} from '@chakra-ui/react'
import Image from 'next/legacy/image'
import { useTranslation } from 'next-i18next'
import Modal from '_app/components/Modal'
import { AskMoreQuestions } from './AskMoreQuestions'

type RingProps = {
  isOpen: boolean;
  onClose: () => void;
};

const standardRingSizes = [
  {
    diameter: '15,6',
    size: '9',
  },
  {
    diameter: '15,9',
    size: '10',
  },
  {
    diameter: '16,2',
    size: '11',
  },
  {
    diameter: '16,6',
    size: '12',
  },
  {
    diameter: '16,9',
    size: '13',
  },
  {
    diameter: '17,2',
    size: '14',
  },
  {
    diameter: '17,5',
    size: '15',
  },
  {
    diameter: '17,8',
    size: '16',
  },
  {
    diameter: '18,1',
    size: '17',
  },
  {
    diameter: '18,5',
    size: '18',
  },
  {
    diameter: '18,8',
    size: '19',
  },
]

const regulatedRingSizes = [
  {
    diameter: '15,6 – 15,9',
    size: 'XS',
  },
  {
    diameter: '16,2 – 16,6',
    size: 'S',
  },
  {
    diameter: '16,9 – 17,2',
    size: 'M',
  },
  {
    diameter: '17,5 – 17,8',
    size: 'L',
  },
  {
    diameter: '18,1 – 18,5',
    size: 'XL',
  },
  {
    diameter: '18,8 – 19,1',
    size: 'XXL',
  },
]

export const Ring = ({ isOpen, onClose }: RingProps) => {
  const { t } = useTranslation('product')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCloseButton isInfoSize>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        lineHeight={1.38}
      >
        <Flex
          textStyle="bodyLight"
          flexWrap="wrap"
          px={{ base: 0, lg: 10 }}
          height="100%"
          py={6}
        >
          <Box p={6} maxW={{ base: 'unset', md: '80%' }}>
            <Text textStyle="h3" my={8}>
              {t('HOW_TO_CHOOSE_RING_SIZE')}
            </Text>
            <Text>{t('CHOOSE_RING_SIZE_BY_DIAMETER')}</Text>
            <Text>{t('DIAMETER_EXPLANATION')}</Text>

            <HStack spacing="40px" alignItems="flex-start" my={8}>
              <Text textStyle="h5">{t('PREPARE')}</Text>
              <UnorderedList>
                <ListItem>{t('YOUR_RING_WITH_MATCHING_SIZE')}</ListItem>
                <ListItem>{t('RULER')}</ListItem>
                <ListItem>{t('PIECE_OF_PAPER')}</ListItem>
              </UnorderedList>
            </HStack>
          </Box>
          <Flex
            flex={{ base: '100%', lg: '50%' }}
            p={4}
            pr={{ base: 4, lg: 14 }}
            flexDirection="column"
          >
            <Text textStyle="h5" mb={4}>
              {t('METHOD_1')}:
            </Text>
            <Box
              my={4}
              position="relative"
              width={{ base: '20%', lg: '30%' }}
              alignSelf={{ base: 'center', lg: 'flex-start' }}
            >
              <Image
                src="/images/product/finger_measure.webp"
                alt="Finger_measure"
                layout="responsive"
                height={117}
                width={84}
              />
            </Box>
            <Box as="ol" p={4}>
              <Text as="li" lineHeight={1.38}>
                {t('WRAP_STRIP_OF_PAPER_AROUND_FINGER')}
              </Text>
              <Text as="li" lineHeight={1.38}>
                {t('MAKE_SURE_PAPER_IS_PULLED_TIGHT')}
              </Text>
              <Text as="li" lineHeight={1.38}>
                {t('MARK_PAPER_CONTACT_POINT_AND_MEASURE')}
              </Text>
              <Text as="li" lineHeight={1.38}>
                {t('USE_TABLE_TO_DETERMINE_RING_SIZE')}
              </Text>
            </Box>
          </Flex>
          <Flex
            flex={{ base: '100%', lg: '50%' }}
            p={4}
            pr={{ base: 4, lg: 14 }}
            flexDirection="column"
          >
            <Text textStyle="h5" mb={4}>
              {t('METHOD_2')}:
            </Text>
            <Box
              py={4}
              position="relative"
              width={{ base: '40%', lg: '60%' }}
              alignSelf={{ base: 'center', lg: 'flex-start' }}
            >
              <Image
                src="/images/product/ring_measure.webp"
                alt="Ring_meaasure"
                layout="responsive"
                height={106}
                width={176}
              />
            </Box>
            <Box as="ol" p={4}>
              <Text as="li" lineHeight={1.38}>
                {t('PUT_RING_ON_RULER')}
              </Text>
              <Text as="li" lineHeight={1.38}>
                {t('MEASURE_TWO_FARTHEST_POINTS_INSIDE_RING')}
              </Text>
              <Text as="li" lineHeight={1.38}>
                {t('ROUND_TO_NEAREST_MILLIMETER')}
              </Text>
              <Text as="li" lineHeight={1.38}>
                {t('USE_TABLE_TO_DETERMINE_RING_SIZE')}
              </Text>
            </Box>
          </Flex>
          <Box
            flex={{ base: '100%', lg: '50%' }}
            p={4}
            pr={{ base: 4, lg: 14 }}
          >
            <Text textStyle="h5" mb={4}>
              {t('SIZE_TABLE_STANDARD_RINGS')}
            </Text>
            <Table variant="simple" style={{ tableLayout: 'fixed' }}>
              <Tbody>
                <Tr>
                  <Td border="none" p="10px 0">
                    {t('DIAMETER')}
                  </Td>
                  <Td border="none" p="10px 0">
                    {t('LILOU_SIZE')}
                  </Td>
                </Tr>
                {standardRingSizes.map((size, idx) => (
                  <Tr key={idx}>
                    <Td border="none" p="5px 0">
                      {size.diameter} mm
                    </Td>
                    <Td border="none" p="5px 0">
                      {size.size}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            flex={{ base: '100%', lg: '50%' }}
            p={4}
            pr={{ base: 4, lg: 14 }}
          >
            <Text textStyle="h5" mb={4}>
              {t('SIZE_TABLE_ADJUSTABLE_RINGS')}
            </Text>
            <Table variant="simple" style={{ tableLayout: 'fixed' }}>
              <Tbody>
                <Tr>
                  <Td border="none" p="10px 0">
                    {t('DIAMETER')}
                  </Td>
                  <Td border="none" p="10px 0">
                    {t('LILOU_SIZE')}
                  </Td>
                </Tr>
                {regulatedRingSizes.map((size, idx) => (
                  <Tr key={idx}>
                    <Td border="none" p="5px 0">
                      {size.diameter} mm
                    </Td>
                    <Td border="none" p="5px 0">
                      {size.size}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            flex={{ base: '100%', lg: '50%' }}
            p={4}
            pr={{ base: 4, lg: 14 }}
            lineHeight={1.38}
          >
            <Text textStyle="h5" mb={4}>
              {t('TIPS')}
            </Text>
            <Text pb={4}>{t('ROUND_TO_NEAREST_WHOLE_MILLIMETER')}</Text>
            <Text pb={4}>{t('ADJUST_SIZE_TO_THICKEST_FINGER')}</Text>
            <Text pb={4}>{t('MEASURE_FINGERS_AT_END_OF_DAY')}</Text>
            <Text>{t('COLD_CAN_SHRINK_FINGER_SIZE')}</Text>
          </Box>
        </Flex>

        <AskMoreQuestions />
      </Flex>
    </Modal>
  )
}
