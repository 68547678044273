import {UrlObject} from 'url'
import {TRANSLATED_ROUTES} from '../../routes'
import URLHelper from './URLHelper'
import qs from 'qs'
import type {ParsedUrlQueryInput} from 'querystring'

export default {
  formatTarget: (target: string = '_self') => target && target[0] !== '_' ? `_${target}` : target,
  getSearchParams() {
    if (typeof window !== 'undefined') {
      return qs.parse(location.search?.replace('?', '')) as ParsedUrlQueryInput
    }
    return {}
  },
  translation: (href: string, locale: string) => {
      const newHref = href.startsWith('/') || href.includes('//') ? href : `/${href}`
      const parts = newHref.replace('.html', '').split('/')
      const prefix = parts.shift()
      if (prefix === '') {
        let objPaths: { [key: string]: any } = { ...TRANSLATED_ROUTES }
        parts.forEach((part, idx) => {
          if (part) {
            if (part === 'sklep') {
              part = 'shop'
            }
            if (Object.keys(objPaths).includes(part)) {
              const partObj = objPaths[part]
              if (typeof partObj === 'object') {
                objPaths = { ...partObj }
              }
            } else {
              const pathFn = Object.values(objPaths).find(
                  value => typeof value === 'function'
              ) as (args: string) => Record<string, unknown>
              if (pathFn) {
                const args = parts.slice(idx).join('/')
                objPaths = { ...pathFn(args) }
              }
            }
          }
        })
        const localePart = objPaths[locale ?? '']
        if (localePart) {
          return `/${locale}${localePart}`
        }
      }
      return href
  },
  translateHref: (href: UrlObject | string, locale: string) => {
      if (typeof href === 'string') return URLHelper.translation(href, locale)
      if (typeof href === 'object' && href.pathname) {
        const { pathname, ...rest } = href
        return {
          pathname: URLHelper.translation(pathname, locale),
          ...rest,
        }
      }
      return href
  },
  get: (url: string, locale: string) => {
    try {
      const { pathname } = new URL(url)
      let ret = pathname
          .replace(`/${locale}/`, '')
          .replace(/\/$/, '')
          .replace('.html', '')
      const chunks = ret?.split('/')
      if (chunks?.length !== 0) {
        ret = chunks[chunks?.length - 1]
      }
      return ret
    } catch (err) {
      console.error(err)
    }
    return ''
  },
  createURL: (url: { href?: any, external_href?: any , absolute?: any, addLocale?: boolean, locale?: any}) => {
    let tempLink = ''
    if (url?.absolute) {
      return url?.href || url?.external_href
    } else {
      if (url?.external_href) {
        return url?.external_href
      } else {
        if (url?.href) {
          const localeTemp = (Boolean(url?.addLocale) ? `/${url?.locale}` : '')
          if (url?.href[url?.href?.length - 1] === '/') {
            tempLink = url?.href?.slice(0, -1)?.search('.html') >= 0 ? localeTemp + url?.href?.slice(0, -1) : localeTemp + url?.href?.slice(0, -1)  + '.html'
          } else {
            tempLink = url?.href?.search('.html') >= 0 ? localeTemp + url?.href : localeTemp + url?.href + '.html'
          }
          return tempLink
        }
      }
    }
  }
}
