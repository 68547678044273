import React, { Component, ErrorInfo } from 'react'
import Custom500 from 'pages/500'
import logger from '_app/utils/logger'

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
  error: Error | null;
};

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    error: null,
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`${errorInfo} ${String(error)}`)
    logger.error(`${errorInfo} ${String(error)}`)
    this.setState({ error })
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <Custom500 error={this.state.error} />
    }

    const { children } = this.props
    return <>{children}</>
  }
}

export default ErrorBoundary
