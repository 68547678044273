import { BoxProps, ButtonProps } from '@chakra-ui/react'
import { Enum_Componentsectionsbanner_Alignment } from '_app/types/cms'
import { ImgProps } from '_app/types/content'
import React from 'react'

export enum BANNER_ALIGNMENT {
  LEFT = 'start',
  RIGHT = 'end',
}

export interface ImageBannerProps extends BannerProps {
  short_description?: string;
  alt?: string;
  imgRatio?: number | { base?: number; md?: number; lg?: number };
  children?: JSX.Element;
  img: ImgProps;
  img_mobile?: ImgProps;
  priority?: boolean;
}

export interface CtaProps extends ButtonProps {
  text?: string;
  reversed?: boolean;
  link?: {
    external_href?: string;
    href?: string;
    target?: string;
  };
}

export interface BannerProps {
  title?: string;
  description?: string;
  section_title?: string;
  cta?: CtaProps;
  cta2?: CtaProps;
  alignment?: BANNER_ALIGNMENT | Enum_Componentsectionsbanner_Alignment;
  color?: string;
  bg?: string;
  link?: {
    text?: string;
    link?: {
      href?: string;
      target?: string;
    };
  };
  boxProps?: BoxProps;
  children?: React.ReactNode;
}
