import getDomain from './getDomain'

const languages = ['PL', 'DE', 'FR', 'AT', 'EU'] as const
const languageNames = ['PL', 'DE', 'FR'] as const
const getCountryCode = (lang: typeof languages[number]): any => {
  return lang
}
const getCountryName = (lang: typeof languages[number]): string => {
  switch (lang) {
    case 'PL':
      return 'Polski'
    case 'DE':
      return 'Deutch'
    case 'FR':
      return 'Français'
    case 'AT':
      return 'Austria'
    default:
      return 'English'
  }
}

const getUrl = (lang: typeof languages[number]) => {
  return getDomain(lang)
}

export const langOptions = languages.map(lang => ({
  countryCode: getCountryCode(lang),
  url: getUrl(lang),
}))

export const siteLanguages = languageNames.map(lang => ({
  value: lang,
  label: getCountryName(lang),
}))

export const getDefaultSiteLanguage = (locale: string) =>
  siteLanguages.find(language => language.value === locale.toUpperCase())
