import { Center, IconButton, Text, Box, BoxProps } from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/legacy/image'
import { PlayIcon } from '_app/components/Icons'
import VideoPlayer from './VideoPlayer'

const StyledWrapper = styled.div<{
  isVimeo?: boolean;
}>`
  max-height: calc(100vh - 130px);
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  .react-player {
    ${({ isVimeo }) => isVimeo && 'padding-top: 39%;'}
    position: relative;
    width: 100%;
  }

  .react-player > div {
    top: 0;
    left: 0;
    position: absolute;
    .player .vp-video {
      box-shadow: 0 0 calc(1.5 * 10px) calc(-0.5 * 10px) #000;
    }
  }
`

interface VideoSectionProps extends BoxProps {
  copyDesktop?: string;
  desktopUrl?: string;
  mobileUrl?: string;
}

const VideoSection = ({
  copyDesktop,
  mobileUrl,
  desktopUrl,
}: VideoSectionProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isWatched, setIsWatched] = useState(false)
  const [progress, setProgress] = useState(0)
  const [height, setHeight] = useState(780)
  const [width, setWidth] = useState(1000)
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '300px 0px',
  })
  const { t } = useTranslation()
  const [visibilityState, setVisibilityState] = useState(true)
  const isVimeo = mobileUrl?.includes('vimeo')
  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document?.visibilityState === 'visible')
  }, [])

  useEffect(() => {
    document?.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document?.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const handleScroll = () => {
    window?.innerWidth &&
      height !== window?.innerWidth / 0.5 &&
      setHeight(window?.innerWidth / 0.5)
    window?.innerWidth &&
      width !== window?.innerWidth &&
      setWidth(window?.innerWidth)

    const div = document.getElementById('video-box')
    if (div && !isWatched) {
      const videoTop = div.getBoundingClientRect().top
      const videoBottom = Math.floor(
        div.getBoundingClientRect().top + div.getBoundingClientRect().height
      )
      if (
        (videoTop <= 100 && videoBottom >= window.innerHeight) ||
        (videoBottom <= window.innerHeight && videoBottom >= 100)
      ) {
        setIsPlaying(true)
      } else {
        setIsPlaying(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  if (!visibilityState) return null

  return (
    <Box ref={ref} id="video-wrapper">
      {(!inView || !isLoaded) && (
        <Center>
          <Image
            src="/loader.gif"
            width={99}
            height={66}
            layout="fixed"
            alt="Loader"
            unoptimized
          />
        </Center>
      )}
      {inView ? (
        <>
          {desktopUrl && (
            <Box display={{ base: mobileUrl ? 'none' : 'block', md: 'block' }}>
              <StyledWrapper id="video-box" isVimeo={isVimeo}>
                <VideoPlayer
                  playing={isPlaying}
                  height="100%"
                  width="100%"
                  url={desktopUrl}
                  iframeFrameBorder="0"
                  playOverlay={
                    <Center
                      position="absolute"
                      top="0"
                      left="0"
                      width="100%"
                      height="100%"
                    >
                      {!isPlaying && (
                        <Center flexDirection={['column', null, 'row']}>
                          <Text
                            fontSize={['18px', null, '46px']}
                            mb={[2, null, 0]}
                            fontWeight={500}
                            textAlign="center"
                            lineHeight={1.22}
                            color="white"
                            mr={[0, null, 10]}
                          >
                            {copyDesktop}
                          </Text>

                          <IconButton
                            onClick={() => setIsPlaying(true)}
                            variant="icon"
                            icon={
                              <PlayIcon
                                fontSize={['2rem', null, '3rem']}
                                color="white"
                              />
                            }
                            aria-label={t('PLAY', { ns: 'common' })}
                          />
                        </Center>
                      )}
                    </Center>
                  }
                  onReady={() => {
                    setIsLoaded(true)
                  }}
                  onProgress={({ playedSeconds }) => {
                    if (playedSeconds) {
                      if (Math.ceil(playedSeconds) >= progress) {
                        setProgress(Math.ceil(playedSeconds))
                      } else {
                        setProgress(Math.ceil(playedSeconds))
                        setIsWatched(true)
                        setIsPlaying(false)
                      }
                    }
                  }}
                />
              </StyledWrapper>
            </Box>
          )}
          {mobileUrl && (
            <Box display={{ base: 'block', md: 'none' }}>
              <StyledWrapper isVimeo={isVimeo}>
                <VideoPlayer
                  controls={false}
                  playing={isPlaying}
                  height="100%"
                  width="100%"
                  iframeHeight={height}
                  iframeWidth={width}
                  url={mobileUrl}
                  iframeFrameBorder="0"
                  onReady={() => {
                    setIsLoaded(true)
                  }}
                  onProgress={({ playedSeconds }) => {
                    if (playedSeconds) {
                      if (Math.ceil(playedSeconds) >= progress) {
                        setProgress(Math.ceil(playedSeconds))
                      } else {
                        setProgress(Math.ceil(playedSeconds))
                        setIsWatched(true)
                        setIsPlaying(false)
                      }
                    }
                  }}
                />
              </StyledWrapper>
            </Box>
          )}
        </>
      ) : null}
    </Box>
  )
}

export default VideoSection
