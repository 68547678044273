import {useFixedPosition} from '_app/hooks/useFixedPosition'

const useSimpleHeaderTopBarPosition = (hasTopBar: boolean) => {
  const { isFixed } = useFixedPosition({
    breakpoint: 10,
    onlyMobile: false,
  })

  const additionalSpace = '2rem'

  if (!hasTopBar) {
    return {
      base: 0,
      lg: 0,
    }
  }

  return {
    base: isFixed ? 0 : additionalSpace,
    lg: isFixed ? 0 : additionalSpace,
  }
}

export default useSimpleHeaderTopBarPosition
