export const styles = {
  global: {
    '.widget-modal': {
      maxHeight: 'unset !important',
    },
    '.swiper-pagination-bullet': {
      backgroundColor: 'white',
      width: '6px',
      height: '6px',
    },
    form: {
      width: '100%',
    },
    '.chakra-checkbox': {
      '.chakra-checkbox__control': {
        border: '1px solid',
        borderColor: 'input',
        borderRadius: 'unset',
        boxShadow: 'none',
      },
    },
    '.swiper-slide': {
      height: 'auto',
    },
  },
}
