import winston from 'winston'
import 'setimmediate'

const logger = winston.createLogger({
  level: 'info',
  silent: process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'preprod',
  transports: [new winston.transports.Console()],
})

export default logger
