import { useTranslation } from 'next-i18next'
import { Box, Center, Text, useDisclosure } from '@chakra-ui/react'
import useProductOptions, { OptionType } from '_app/hooks/useProductOptions'
import RadioOption from '_app/components/Form/RadioOption'
import SizingOptions from '../SizingOptions'
import { any } from 'prop-types'

type SizeProps = {
  sizes: { value: string; label: string }[];
  name: string;
  optionType: OptionType;
  productType: string | undefined;
  showSizeTable?: boolean;
  showSizeTitle?: boolean;
};

const Size = ({
  sizes,
  name,
  optionType,
  productType,
  showSizeTable,
  showSizeTitle,
}: SizeProps) => {
  const { t } = useTranslation('product')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getHeader } = useProductOptions({})

  // passing through only "size"
  // otherwise options like "other" would make the paintings options that are used for "Add painting" to be listed here as well
  if (optionType !== 'size') return null

  const withSizeTable =
    showSizeTable ??
    (productType === 'necklace' ||
      productType === 'ring' ||
      productType === 'bracelet')

  return (
    <Box mb={4}>
      {withSizeTable && (
        <SizingOptions
          isOpen={isOpen}
          onClose={onClose}
          optionType={productType}
        />
      )}
      <Center justifyContent="space-between" mb={4}>
        {(showSizeTitle ?? true) && productType && (
          <Text textStyle="productOption">{getHeader(productType)}</Text>
        )}
        {withSizeTable && (
          <Text
            cursor="pointer"
            textDecoration="underline"
            fontSize={12}
            onClick={onOpen}
          >
            {t('SIZE_TABLE')}
          </Text>
        )}
      </Center>
      <RadioOption
        isArray
        name={name}
        options={sizes}
        cardProps={{
          opacity: 0.5,
          _checked: {
            opacity: 1,
            borderColor: '#000',
          },
        }}
        content={option => (
          <Center height="2rem" p={3}>
            <Text
              fontSize={14}
              lineHeight={1.14}
              fontWeight={300}
            >
              {option?.label}
            </Text>
          </Center>
        )}
      />
    </Box>
  )
}

export default Size
