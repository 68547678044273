import { Container, Box, Center, Flex, Text } from '@chakra-ui/react'
import React from 'react'

import CtaButton from '_app/components/Buttons/CtaButton'
import ImageCms from '_app/components/Content/ImageCms'
import RichText from '_app/components/Content/RichText'

const ContentSection = ({
  title,
  description,
  img,
  img_mobile,
  img_position,
  bg_color,
  cta,
  gap,
  textPx,
  my,
  textWith,
}: any) => {
  const imgAttr = img?.data?.attributes
  const mobileImgAttr = img_mobile?.data?.attributes

  return (
    <Box bg={bg_color}>
      <Container px={0}>
        <Flex
          as="section"
          my={
            my || {
              base: '3rem',
              lg: '6rem',
            }
          }
          gap={
            gap || {
              base: '1.5rem',
              lg: '9.375rem',
            }
          }
          flexDir={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Box
            flex={1}
            my={{ base: 'unset', lg: 'auto' }}
            order={{
              base: 0,
              lg: img_position === 'right' ? 1 : 0,
            }}
          >
            {imgAttr?.url && (
              <Box
                display={{
                  base: mobileImgAttr?.url ? 'none' : 'block',
                  lg: 'block',
                }}
              >
                <ImageCms src={''} attributes={imgAttr} alt={''} />
              </Box>
            )}
            {mobileImgAttr?.url && (
              <Box
                display={{
                  base: 'block',
                  lg: 'none',
                }}
              >
                <ImageCms src={''} attributes={mobileImgAttr} alt={''} />
              </Box>
            )}
          </Box>
          <Center
            justifyContent={
              textWith && img_position === 'right' ? 'flex-start' : 'center'
            }
            flex={1}
            p={4}
          >
            <Box px={textPx} width={textWith}>
              <Text textStyle="sectionHeader" as="h2">
                {title}
              </Text>
              {description && <RichText html={description} />}
              {cta && <CtaButton cta={{ ...cta, mt: '20px' }} />}
            </Box>
          </Center>
        </Flex>
      </Container>
    </Box>
  )
}

export default ContentSection
