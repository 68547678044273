import {useEffect, useState} from 'react'
import {ProductInterface} from '_app/graphql/generated/graphql'

interface UseProductAvailability {
    availableProductAmount?: number;
}

interface Props {
    salable_qty?: number;
    itemsOptions?: any;
    selectedOptions?: string[];
    product?: any;
}

const useProductAvailability = ({
        itemsOptions,
        selectedOptions,
        salable_qty = 0,
        product
    }: Props): UseProductAvailability => {
    const [availableProductAmount, setAvailableProductAmount]: any = useState(undefined)
    const checkedSelectedOptions = (options) => {
        if (typeof options === 'object') {
            let isExist = true
            options?.forEach((el) => {
                if (!el) {
                    isExist = false
                }
            })
            return isExist
        }
        return options
    }
    useEffect(() => {
        const outOfStock = product?.stock_status === 'OUT_OF_STOCK'
        if (outOfStock) {
            setAvailableProductAmount(0)
        } else {
            if (product?.type_id === 'bundle' && product?.in_stock && typeof product?.in_stock === 'number') {
                setAvailableProductAmount(product?.in_stock)
            } else {
                if (product?.__typename === 'BundleProduct') {
                    const allOptions = (itemsOptions ?? []).reduce((prev: any, item: any) => {
                        return [...prev, ...(item?.options ?? [])]
                    }, [])
                    let options_salable_qty = []
                    if (checkedSelectedOptions(selectedOptions)) {
                        options_salable_qty = allOptions
                            ?.filter((option: {
                                uid?: string;
                                product: ProductInterface
                            }) => option?.uid && selectedOptions?.includes(option?.uid))
                            .map((option: { uid?: string; product: ProductInterface }) => option?.product?.salable_qty)
                    } else {
                        options_salable_qty = allOptions
                            .map((option: { uid?: string; product: ProductInterface }) => option?.product?.salable_qty)
                    }
                    if (!!options_salable_qty?.length) {
                        setAvailableProductAmount(
                            options_salable_qty.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                        )
                    } else {
                        setAvailableProductAmount(0)
                    }
                } else {
                    if (product?.in_stock) {
                        setAvailableProductAmount(product?.in_stock)
                    } else {
                        if (salable_qty !== undefined) {
                            setAvailableProductAmount(salable_qty)
                        }
                    }
                }
            }
        }
    }, [itemsOptions, selectedOptions, salable_qty])

    return {availableProductAmount}
}

export default useProductAvailability
