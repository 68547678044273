import React from 'react'
import { BANNER_ALIGNMENT, ImageBannerProps } from './types'
import ImageBanner from './ImageBanner'

const Hero = (props: ImageBannerProps) => {
  return (
    <ImageBanner
      boxProps={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      priority={true}
      alignment={BANNER_ALIGNMENT.LEFT}
      {...props}
    />
  )
}

export default Hero
