import { ReactNode } from 'react'
import Link from 'next/link'
import { ButtonProps } from '_app/components/Buttons/Button'
import dynamic from 'next/dynamic'

interface PaginationButtonProps extends ButtonProps {
  href: string;
}
interface WrapperProps extends PaginationButtonProps {
  children: ReactNode;
}

const DynamicButton = dynamic(() => import('_app/components/Buttons/Button'), {
  ssr: false,
})

const Wrapper = ({ children, isDisabled, href }: WrapperProps) => {
  if (isDisabled) {
    return <>{children}</>
  }
  return (
    <Link href={href} passHref>
      {children}
    </Link>
  )
}

export const PaginationButton = ({
  isActive,
  isDisabled,
  href,
  ...props
}: PaginationButtonProps) => {
  return (
    <Wrapper href={href} isDisabled={isDisabled}>
       <DynamicButton
        as="button"
        variant={isActive ? 'outline' : 'solid'}
        minWidth="2rem"
        minHeight="2rem"
        boxSize="2rem"
        fontSize="0.875rem"
        fontWeight={400}
        reversed={!isActive}
        isDisabled={isDisabled}
        {...props}
       />
    </Wrapper>
  )
}
