import {AspectRatio, Box, Button, Flex, IconButton, Spinner, Text, Tooltip, useBreakpointValue} from '@chakra-ui/react'
import React, {useEffect, useMemo, useState} from 'react'
import {Pagination} from 'swiper'
import type {UrlObject} from 'url'
import {omit} from 'lodash-es'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {FormProvider, useForm} from 'react-hook-form'
import theme from 'theme'
import Link from '_app/components/Link/Link'
import {BundleProduct} from '_app/graphql/generated/graphql'
import {AddProductsToCartDocument} from '_app/graphql/mutations/cart/AddProductToCart'
import FavoriteButton from '_app/components/Buttons/FavoriteButton'
import CloudflareImage from '_app/components/CloudflareImage'
import VideoPlayer from '_app/components/VideoPlayer'
import {APP_ROUTES} from '_app/routes'
import {getProductImages, getProductMainImage} from 'modules/product/utils/gallery'
import {EdroneEvents, getUrl} from '../../_app/utils/edrone'
import {ShoppingCartIcon} from '../../_app/components/Icons'
import {getCurrencyCode, getDimension4, GTM_EVENTS} from '../../_app/utils/dataLayer'
import useProductOptions from '../../_app/hooks/useProductOptions'
import {getDefaultSize, getNecklaceCheapestSize} from '../product/utils/size'
import Size from '../product/components/ProductForm/Size'
import dynamic from 'next/dynamic'
import {cart, cartIdVar, isCartOpenVar} from '../../_app/cache'
import getPrice from '../../_app/utils/localized/getPrice'
import {useDynamicProductPrice} from '../product/hooks/useDynamicProductPrice'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import {useReactiveVar} from '@apollo/client'
import CartHelper from '../../helpers/CartHelper/CartHelper'
import RequestHelper from '../../helpers/RequestHelper/RequestHelper'
import {IS_CART_ACTIVE} from '../../_app/graphql/queries/cart/IsCartActive'
import {parseCookies} from 'nookies'
import ProductHelper from '../../helpers/ProductHelper/ProductHelper'
import ImageEnum from '../../enums/ImageEnum/ImageEnum'
import {initializeApollo} from '../../helpers/ApolloHelper/ApolloHelper'
import toast from '../../_app/utils/toast'
import InfoCart from '../../assets/svg/infoCart.svg'
import useProductAvailability from '../../_app/hooks/useProductAvailability'

const DynamicCarousel = dynamic(() => import('_app/components/Carousel'), {
    ssr: false,
})

type Url = string | UrlObject;

const SimpleItem = (props: any) => {
    const {
        boxProps,
        isFavoritesList,
        item,
        itemOptions,
        onClick,
        showCarousel,
        hideCart,
    }: any = props
    const {image, name, sku, url_key}: any = item
    const [hide, setHide] = useState(hideCart || false)
    const omnibus_price = item?.omnibus_price
    const {price, regularPrice} = useDynamicProductPrice(item, true)
    const router = useRouter()
    const [quantity, setQuantity] = useState(0)
    const [showOptionModal, setShowOptionModal] = useState(false)
    const {locale}: any = router
    const cartId = useReactiveVar(cartIdVar) || parseCookies().cartId
    const {t} = useTranslation()
    const style = {
        box: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0',
            padding: BreakpointsHelper.isMobile() ? '0 10px' : '0',
        },
        text: {
            maxWidth: {base: 'full', lg: '310px'},
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            minHeight: '50px',
            paddingRight: '5px',
            marginBottom: 0,
        },
        price: {
            maxWidth: {base: 'full', lg: '310px'},
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: 1.4,
            marginBottom: 0,
        },
        priceLowest: {
            maxWidth: {base: 'full', lg: '310px'},
            fontSize: '20px',
            fontWeight: 600,
            color: 'red',
            lineHeight: 1.4,
            marginBottom: 0,
        },
        textSmall: {
            maxWidth: {base: 'full', lg: '310px'},
            fontSize: '15px',
            fontWeight: 400,
            color: '#6a5d5d',
            lineHeight: '24px',
            marginBottom: 0,
        },
        textSmallDiscount: {
            maxWidth: {base: 'full', lg: '310px'},
            fontSize: '15px',
            fontWeight: 400,
            color: 'red',
            lineHeight: '24px',
            marginBottom: 0,
            marginLeft: '5px',
        },
        priceDiscount: {
            maxWidth: {base: 'full', lg: '310px'},
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '24px',
            marginBottom: 0,
            color: '#605d5d',
            textDecoration: 'line-through'
        },
    }
    const cheapestSizeOption = getNecklaceCheapestSize(item as BundleProduct)
    const methods = useForm()
    const productInfo = {
        product: item,
        upsellProducts: item.upsell_products,
    } as any
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const productOptions = useProductOptions(productInfo)
    const {requiredOptions, productType} = productOptions || {}
    const defaultOptions: any = requiredOptions
        ?.filter(option => option.options.length === 1)
        .map(option => option.options[0].value)
    const neededOptions = requiredOptions?.filter(
        option => option.options.length > 1
    )
    const defaultOptionValues: any = useMemo(
        () =>
            requiredOptions?.map(option => {
                const {type, options} = option || {}
                return type === 'size'
                    ? getDefaultSize(options, cheapestSizeOption?.toString())
                    : options?.[0]?.value
            }),
        [requiredOptions, cheapestSizeOption]
    )
    const itemHref: Url = APP_ROUTES.PRODUCTS.BY_URL_KEY(encodeURIComponent(url_key || ''))
    const filteredMediaGallery = getProductImages(item)
    const {availableProductAmount} = useProductAvailability({
        salable_qty: item?.salable_qty ?? 0,
        itemsOptions: item?.items,
        product: item,
    })
    const hover = filteredMediaGallery.find(image => image.types?.includes('hover'))?.url
    const mainImage = image || item?.thumbnail || filteredMediaGallery[0] || {
        url: ImageEnum.MAIN_IMAGE_PLACEHOLDER,
        label: ''
    }
    const hoverImage = hover ? {url: hover, label: image?.label} : undefined
    const showGalleryCarousel =
        useBreakpointValue({base: true, lg: false}) && showCarousel
    const addToCart = async ({
                                 requireOption,
                                 selectedOption,
                             }: {
        requireOption?: boolean;
        selectedOption?: string[][];
    }) => {
        const apolloClient = initializeApollo(null, {locale})
        if (requireOption) {
            setShowOptionModal(!showOptionModal)
        } else {
            setShowOptionModal(false)

            let res: any = null
            if (cartId?.length > 0) {
                res = await apolloClient.query({
                    query: IS_CART_ACTIVE,
                    variables: {
                        masked_cart_id: cartId,
                    },
                })
            }

            const isActive = cartId ? res?.data?.isCartActive?.is_active : false
            const cartIdTemp: string = await CartHelper.createEmptyCart(apolloClient, cartId, isActive)
            const variables = {
                cartId: cartIdTemp,
                cartItems: [
                    {
                        sku: Array.isArray(sku) ? sku[0] : sku || '',
                        quantity: 1,
                        selected_options: [
                            ...(selectedOption?.flat() || []),
                            ...(defaultOptions || []),
                        ]?.length > 0
                            ? [...(selectedOption?.flat() || []), ...(defaultOptions || [])]
                            : defaultOptions
                                ? defaultOptions
                                : [],
                    },
                ],
            }

            const edronePayload = {
                productId: item.id,
                productSku: item.sku as string,
                productUrl: getUrl(item.url_key),
                productTitle: item.name as string,
                productImages: getProductMainImage(item),
                productCategoryIds: '',
                productCategoryNames: '',
                productAvailability: item.salable_qty ?? 1,
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (Object.values(item?.price)?.[0]?.default === 0 || item?.price?.regularPrice?.amount?.value === 0) {
                toast({description: t('NOT_POSSIBLE_TO_BUY'), status: 'error'})
                return null
            }

            return apolloClient
                .mutate({
                    mutation: AddProductsToCartDocument,
                    variables,
                })
                .then(async (res) => {
                    const response = RequestHelper.onComplete(res, 'addProductsToCart', locale)
                    if (response) {
                        GTM_EVENTS.addToCart({
                            type:
                                item?.__typename === 'SimpleProduct'
                                    ? 'standard_product'
                                    : item?.__typename === 'BundleProduct'
                                        ? ProductHelper.isPersonalizedProduct(item?.product)
                                            ? 'personalized_product'
                                            : 'configured_products'
                                        : '',
                            origin: 'product page',
                            ecommerce: {
                                currencyCode: getCurrencyCode(item),
                                add: {
                                    products: [
                                        {
                                            id: sku as string,
                                            name: name as string,
                                            quantity: 1,
                                            price:
                                                item?.produt?.price_range?.minimum_price?.final_price
                                                    ?.value as number,
                                            dimension4: getDimension4(item?.categories),
                                            category1: item?.produt?.main_category?.[0]?.name,
                                            brand: 'Lilou',
                                            dimension8: ProductHelper.isPersonalizedProduct(
                                                item?.product
                                            )
                                                ? 'Personalizowany'
                                                : 'Standard',
                                        },
                                    ],
                                },
                            },
                        })

                        const edroneAddToCartPayload = omit(
                            edronePayload,
                            'productAvailability'
                        )
                        EdroneEvents.addToCart(edroneAddToCartPayload, locale)
                        await cart(res?.data?.addProductsToCart)
                        setQuantity(quantity + 1)
                        isCartOpenVar(true)
                    }
                }).catch((error) => RequestHelper.internalServerError(error))
        }
    }
    const slides = useMemo(() => {
        const images = filteredMediaGallery.map((item, idx) => {
            return {
                id: idx,
                content: (
                    <Box aria-label={name ?? ''} bg="main">
                        <AspectRatio ratio={1} w="full">
                            <CloudflareImage
                                src={item?.url ?? ImageEnum.MAIN_IMAGE_PLACEHOLDER}
                                alt={item?.label || ''}
                                layout="fill"
                                sizes={`
                      (max-width: ${theme.breakpoints.lg}) 50vw,
                      20.5rem,
                      `}
                            />
                        </AspectRatio>
                    </Box>
                ),
            }
        })

        const video = item.vimeo_videos
            ? {
                id: filteredMediaGallery.length,
                content: (
                    <>
                        <Spinner
                            position="absolute"
                            zIndex="-1"
                            left="calc(50% - 0.5 * var(--spinner-size))"
                            top="calc(50% - 0.5 * var(--spinner-size))"
                        />
                        <AspectRatio ratio={1} w="full" pointerEvents="none">
                            <VideoPlayer url={item.vimeo_videos}/>
                        </AspectRatio>
                    </>
                ),
            }
            : null

        return video ? [...images, video] : images
    }, [filteredMediaGallery])
    useEffect(() => {
        if (availableProductAmount === 0) {
            setHide(true)
        }
    }, [availableProductAmount])
    return (
        <Box
            width="full"
            maxW={{
                base: 'full',
                lg: '20.5rem',
            }}
            bg={{base: theme.colors.lightPink, lg: 'transparent'}}
            onClick={onClick}
            position='relative'
            {...boxProps}
            className="isHitsItem"
        >
            {showGalleryCarousel ? (
                <Link href={itemHref} passHref>
                    <Box as="span" rel="nofollow">
                        <DynamicCarousel
                            modules={[Pagination]}
                            slidesPerView={1}
                            paginationColor="black"
                            pagination={{clickable: true}}
                            longSwipes={false}
                            speed={400}
                            slides={slides}
                        />
                    </Box>
                </Link>
            ) : (
                <a href={`/${locale}/${itemHref.toString()}.html`}>
                    <Box className="result" data-objectid={props.dataInsightsObjectId}
                         data-indexname={props.dataInightsIndexName} data-position={props.dataInsightsPosition}
                         data-queryid={props.dataInsightsQueryId}>
                        <Box
                            as="span"
                            role="group"
                            position="relative"
                            overflow="hidden"
                            cursor="pointer"
                            _hover={{transform: 'scale(1.1)'}}
                            transition="all 0.3s"
                            rel="nofollow"
                            aria-label={name ?? ''}
                            id={item.analyticsId}
                            display={{
                                base: showGalleryCarousel ? 'none' : 'block',
                                lg: 'block',
                            }}
                        >
                            <Box bg="main">
                                <AspectRatio ratio={1} w="full">
                                    <CloudflareImage
                                        src={mainImage?.url ?? ImageEnum.MAIN_IMAGE_PLACEHOLDER}
                                        alt={mainImage?.label || ''}
                                        layout="fill"
                                        sizes={`
                    (max-width: ${theme.breakpoints.lg}) 50vw,
                    20.5rem,
                    `}
                                    />
                                </AspectRatio>
                            </Box>
                            {hoverImage && (
                                <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                                    <Box
                                        opacity={0}
                                        transition="opacity 0.3s ease-in-out"
                                        _groupHover={{
                                            opacity: 1,
                                        }}
                                        bg="main"
                                    >
                                        <AspectRatio ratio={1} w="full">
                                            <CloudflareImage
                                                src={hoverImage?.url || ''}
                                                alt={hoverImage?.label || ''}
                                                layout="fill"
                                                sizes={`
                      (max-width: ${theme.breakpoints.lg}) 50vw,
                      20.5rem,
                      `}
                                            />
                                        </AspectRatio>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </a>
            )}
            {showOptionModal && (
                <Box
                    w="full"
                    position="absolute"
                    marginTop="-7rem"
                    background="white"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    zIndex={1}
                >
                    <FormProvider {...methods}>
                        {defaultOptionValues?.map(
                            (option: any, index: string | number | any) => {
                                return neededOptions?.[index]?.type && (
                                    <Size
                                        showSizeTitle={false}
                                        showSizeTable={false}
                                        name={`selected_options[${index}]`}
                                        key={`selected_options-${index}`}
                                        sizes={neededOptions?.[index].options ?? []}
                                        optionType={neededOptions?.[index].type}
                                        productType={productType}
                                    />
                                )
                            }
                        )}
                        {
                            !hide && (
                                <Button
                                    w="full"
                                    px={{base: 3, lg: 5}}
                                    disabled={!methods.getValues().selected_options}
                                    onClick={() => {
                                        return addToCart({
                                            selectedOption: methods.getValues().selected_options
                                        })
                                    }}
                                    aria-label="CART"
                                >
                                    {t('ADD_TO_CART')}
                                </Button>
                            )
                        }
                    </FormProvider>
                </Box>
            )}
            <Box sx={style.box}>
                <Text sx={style.text}>{name}</Text>
            </Box>
            <Box sx={style.box}>
                {
                    price?.value && (
                        <Box sx={{display: 'flex', width: '100%'}}>
                            {
                                regularPrice?.value && regularPrice.value !== price?.value && regularPrice.value > price?.value ? (
                                    <Text
                                        sx={style.priceLowest}>{getPrice(locale, price?.value, price?.currency, 2, 2)}</Text>
                                ) : (
                                    <Text sx={style.price}>{getPrice(locale, price?.value, price?.currency, 2, 2)}</Text>
                                )
                            }
                        </Box>
                    )
                }
            </Box>
            {
                regularPrice?.value && price?.value !== null && price?.value !== undefined && regularPrice.value > price?.value && (
                    <>
                        <Box sx={style.box}>
                            <Text sx={style.textSmall}>{t('PRICE_REGULAR')}</Text>
                            <Box sx={{display: 'inline-flex'}}>
                                <Text sx={style.priceDiscount}>
                                    {getPrice(locale, regularPrice.value, regularPrice.currency, 2, 2)}
                                </Text>
                                <Text
                                    sx={style.textSmallDiscount}>{'(- ' + ((regularPrice.value - price?.value) / regularPrice?.value * 100).toFixed(0) + ' %)'}</Text>
                            </Box>
                        </Box>
                        <Box sx={style.box}>
                            <Text sx={style.textSmall}>{t('OMNIBUS_PRICE')}</Text>
                            <Text
                                sx={style.priceDiscount}>{getPrice(locale, (omnibus_price && omnibus_price !== 0) ? omnibus_price : regularPrice.value, regularPrice.currency, 2, 2)}</Text>
                        </Box>
                    </>
                )
            }
            <Flex
                alignItems="center"
                pl={{base: 2, lg: 'auto'}}
                pb={{base: 2, lg: 'auto'}}
                pr={{base: 3, lg: 'auto'}}
            >
                {itemOptions}
                <Flex>
                    {
                        defaultOptions && !hide && (
                            <Box>
                                <IconButton
                                    variant="icon"
                                    w={{base: 'auto', lg: '1.5rem'}}
                                    px={{base: 3, lg: 5}}
                                    sx={{marginRight: '10px'}}
                                    icon={<ShoppingCartIcon/>}
                                    onClick={() => {
                                        if (props?.item?.__typename === 'BundleProduct' || Array.isArray(sku)) {
                                            router.push(itemHref)
                                        } else {
                                            addToCart({
                                                requireOption: !!neededOptions?.length,
                                            })
                                        }
                                    }}
                                    aria-label="CART"
                                />
                            </Box>
                        )
                    }
                    {
                        hide && hideCart !== true && (
                            <Tooltip isDisabled={false} hasArrow label={t('INFO_CART')}>
                                <Box
                                    sx={{ width: '23px', height: '23px', position: 'relative', top: '2px', marginRight: '20px', cursor: 'pointer' }}
                                    onClick={() => router.push(itemHref)}
                                >
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 37.2 35.5">
                                        <path style={{fillRule: 'evenodd', clipRule: 'evenodd'}}
                                              d="M18.4,8.7c0.7-0.1,1.3,0.3,1.5,1c0.4,2.1-3.3,2.1-2.9,0C17.2,9.1,17.7,8.6,18.4,8.7L18.4,8.7z"/>
                                        <path style={{fillRule: 'evenodd', clipRule: 'evenodd'}} d="M22.7,21.6c0,0.1-0.1,0.1-0.1,0.2c-0.7,1.1-1.5,2.2-2.4,3.1c-0.5,0.5-1.1,0.9-1.8,0.9
                                                    c-0.7-0.1-1.1-0.8-1.3-1.4c-0.7-2.3-0.5-4.8,0.6-6.9c0.3-0.5,0.9-0.8,1.1-1.4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.7-0.6-1.4-1.3-1.4
                                                    c-1.1,0-1.8,1.4-2.2,2.3c-0.5,1.1-0.8,2.4-0.9,3.6c-0.3,3,0.4,5.7,2.3,6.5c1,0.3,2.1,0.3,3-0.2c0.8-0.5,1.6-1.2,2.4-1.8L22.7,21.6
                                                    z"/>
                                        <ellipse style={{
                                            fillRule: 'evenodd',
                                            clipRule: 'evenodd',
                                            fill: 'None',
                                            stroke: '#000000',
                                            strokeWidth: 1.4173,
                                            strokeMiterlimit: 10
                                        }} cx="18.6" cy="17.7" rx="17.4" ry="16.5"/>
                                    </svg>
                                </Box>
                            </Tooltip>
                        )
                     }
                    {
                        !isFavoritesList && (
                            <FavoriteButton sku={sku || ''} item={item}/>
                        )
                    }
                </Flex>
            </Flex>
        </Box>
    )
}

export default SimpleItem
