export const textStyles = {
  fontSizes: {
    '2xs': '0.5rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  h1: {
    fontSize: ['45px'],
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  h2: {
    fontSize: ['32px'],
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  h3: {
    fontSize: ['24px'],
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  h4: {
    fontSize: ['20px'],
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: 'normal',
  },
  h5: {
    fontSize: ['18px'],
    fontWeight: 600,
    lineHeight: 1.56,
    letterSpacing: 'normal',
  },
  body: {
    fontSize: ['16px'],
    fontWeight: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
  },
  bodyLight: {
    fontSize: ['16px'],
    fontWeight: 300,
    lineHeight: 1.88,
    letterSpacing: 'normal',
  },
  bodyCheckout: {
    fontSize: ['16px'],
    fontWeight: 300,
    lineHeight: 1.38,
    letterSpacing: 'normal',
  },
  body2: {
    fontSize: ['14px'],
    fontWeight: 300,
    lineHeight: 1.71,
    letterSpacing: 'normal',
  },
  productOption: {
    fontSize: ['12px'],
    fontWeight: 'normal',
    lineHeight: 0.83,
    letterSpacing: 'normal',
    textTransform: 'uppercase',
  },
  productDetail: {
    fontSize: 'md',
    fontWeight: 'light',
    color: '#242424',
  },
  pageHeader: {
    fontSize: { base: 26, lg: 45 },
    marginBottom: { base: 4, lg: 8 },
    fontWeight: 600,
    lineHeight: 1.22,
    color: 'inherit',
    whiteSpace: {
      base: 'normal',
      lg: 'pre-line',
    },
  },
  sectionHeader: {
    fontSize: { base: 24, lg: 32 },
    fontWeight: 600,
    lineHeight: 1.22,
  },
}
