import toast from '../../_app/utils/toast'
import {GTM_EVENTS} from '../../_app/utils/dataLayer'

export default {
    internalServerError: (log: any) => {
        const lines = log?.toString()?.trim()?.split('\n')
        const error = {
            type: lines[0].split(':')[0],
            message: lines[0].split(':')[1].trim(),
            stack: lines.slice(1).map(line => {
                const match = line.match(/at (.+?) \((.+?):(\d+):(\d+)\)/)
                if (match) {
                    return {
                        function: match[1],
                        file: match[2],
                        line: parseInt(match[3], 10),
                        column: parseInt(match[4], 10)
                    }
                } else {
                    const anonMatch = line.match(/at (.+?) \((.+?)\)/)
                    if (anonMatch) {
                        return {
                            function: anonMatch[1],
                            file: anonMatch[2],
                            line: null,
                            column: null
                        }
                    }
                }
                return null
            }).filter(Boolean)
        }
        const lang = document.documentElement.lang || 'pl'
        if (error?.message === "Can't assign cart to store in different website.") {
            let message = ''
            switch (lang) {
                case 'en':
                    message = 'This product is temporarily unavailable. Please try again later'
                    break
                case 'fr':
                    message = 'Ce produit est temporairement indisponible. Veuillez essayer à nouveau plus tard'
                    break
                case 'de':
                    message = 'Dieses Produkt ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später noch einmal'
                    break
                default:
                    message = 'Ten produkt jest chwilowo niedostępny. Zapraszamy do zakupu za jakiś czas'
                    break
            }
            toast({
                description: message,
                status: 'error',
            })
        }
        if (error?.message?.toLowerCase().includes('internal server error')) {
            let message = ''
            switch (lang) {
                case 'en':
                    message = 'This product is temporarily unavailable. Please try again later.'
                    break
                case 'fr':
                    message = 'Ce produit est temporairement indisponible. Veuillez essayer à nouveau plus tard.'
                    break
                case 'de':
                    message = 'Dieses Produkt ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später noch einmal.'
                    break
                default:
                    message = 'Ten produkt jest chwilowo niedostępny. Zapraszamy do zakupu za jakiś czas.'
                    break
            }
            GTM_EVENTS.errors({
                message,
                path: null,
                res: null,
                locale: lang,
            })
            toast({
                description: message,
                status: 'error',
            })
            setTimeout(() => {
                window?.open('/maintenance.html', '_self')
            }, 5000)
        }
    },
    onComplete: (res: any, path: string | number, locale?: string) => {
        if (res?.data?.[path]?.user_errors?.length > 0 || res[path]?.user_errors?.length > 0) {
            (res?.data?.[path] || res[path])?.user_errors?.forEach((el: { message: any }) => {
                GTM_EVENTS.errors({
                    message: el?.message,
                    path,
                    res,
                    locale,
                })
                toast({
                    description: el?.message,
                    status: 'error',
                })
            })
            return null
        } else {
            return res?.data?.[path] || res?.[path]
        }
    }
}