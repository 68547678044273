import { Container, Box, Center, Text } from '@chakra-ui/react'

import CtaButton from '_app/components/Buttons/CtaButton'
import ImageCms from '_app/components/Content/ImageCms'
import { ImageBannerProps } from '../Banner/types'
import { StyledFlex, StyledImageBox } from './BossBanner.styled'

const BossBanner = ({
  img,
  img_mobile,
  alt,
  bg = 'transparent',
  ...props
}: ImageBannerProps) => {
  return (
    <Box bg={bg} pb={{ base: 8, lg: 0 }}>
      <Container>
        <StyledFlex as="section">
          <Box flex={1}>
            {img && (
              <StyledImageBox mobile={false} imageMobile={!!img_mobile}>
                <ImageCms
                  src={''}
                  attributes={img.data?.attributes}
                  layout="fill"
                  alt={alt}
                />
              </StyledImageBox>
            )}
            {img_mobile && (
              <StyledImageBox mobile={true} imageMobile={!!img_mobile}>
                <ImageCms
                  attributes={img_mobile.data?.attributes}
                  src={''}
                  layout="fill"
                  alt={alt}
                />
              </StyledImageBox>
            )}
          </Box>
          <Center flex={1}>
            <Box maxW="450px">
              {props?.description && (
                <Text
                  fontStyle="italic"
                  mb={{ base: 6, lg: '2.5rem' }}
                  fontWeight="normal"
                  fontSize={{ base: 20, lg: 24 }}
                  lineHeight={1.42}
                >
                  {props.description}
                </Text>
              )}
              {props?.title && (
                <>
                  <Text textStyle="body">{props.title?.split('|')[0]}</Text>
                  <Text textStyle="bodyLight">
                    {props.title?.split('|')[1]}
                  </Text>
                </>
              )}
              {props?.cta && <CtaButton cta={{ ...props.cta, mt: '1.5rem' }} />}
            </Box>
          </Center>
        </StyledFlex>
      </Container>
    </Box>
  )
}

export default BossBanner
