import {Fragment} from 'react'
import {Box, BoxProps, HStack} from '@chakra-ui/react'
import {SearchResultPageInfo} from '_app/types/api'
import {ChevronLeftIcon, ChevronRightIcon} from '_app/components/Icons'
import {useRouter} from '_app/hooks/useRouter'
import {appendQueryParams, asPathSplit, paramsToUrl} from '_app/utils/params'
import {PaginationButton} from './PaginationButton'
import URLHelper from '../../../helpers/URLHelper/URLHelper'

const PAGES_AROUND = 2

type PaginationProps = BoxProps & SearchResultPageInfo;

const Pagination = ({
        current_page,
        total_pages,
        ...boxProps
    }: PaginationProps) => {
    const router = useRouter()
    const currentPage = current_page || 1
    const totalPages = total_pages || 1
    const pages: number[] = []
    for (
        let i = currentPage - PAGES_AROUND;
        i <= currentPage + PAGES_AROUND;
        i++
    ) {
        if (i > 0 && i <= totalPages) {
            pages.push(i)
        }
    }

    if (pages[0] >= 2) {
        pages.unshift(1)
    }

    if (pages.length > 0 && pages[pages.length - 1] <= totalPages - 1) {
        pages.push(totalPages)
    }

    const getHref = (index: number) => {
        const {search, pathname} = asPathSplit(router.asPath)
        const href = URLHelper.translateHref(pathname, router.locale) as string
        const params = appendQueryParams(search, {p: index})
        if (index <= 1) params.delete('p')
        return paramsToUrl(href, params)
    }
    const canPrev = currentPage > 1
    const canNext = currentPage < totalPages
    return (
        <HStack
            w="full"
            justifyContent="center"
            spacing={{
                base: 0,
                xs: 2,
            }}
            {...boxProps}
        >
            <PaginationButton
                isDisabled={!canPrev}
                aria-label="prev"
                href={getHref(currentPage - 1)}
            >
                <ChevronLeftIcon fontSize="2rem"/>
            </PaginationButton>
            {pages.map((p, i) => (
                <Fragment key={`p-${p}`}>
                    {pages[i - 1] && p - pages[i - 1] > 1 && (
                        <Box lineHeight="1.2">...</Box>
                    )}
                    <PaginationButton
                        isActive={currentPage === p}
                        aria-label={`page ${p}`}
                        href={getHref(p)}
                    >
                        {p}
                    </PaginationButton>
                </Fragment>
            ))}
            <PaginationButton
                isDisabled={!canNext}
                aria-label="next"
                href={getHref(currentPage + 1)}
            >
                <ChevronRightIcon fontSize="2rem"/>
            </PaginationButton>
        </HStack>
    )
}

export default Pagination
