import { gql } from '@apollo/client'

export const ADD_PRODUCTS_TO_WISHLIST = gql`
  mutation AddProductsToWishlist(
    $wishlistId: ID!
    $wishlistItems: [WishlistItemInput!]!
  ) {
    addProductsToWishlist(
      wishlistId: $wishlistId
      wishlistItems: $wishlistItems
    ) {
      wishlist {
        id
      }
    }
  }
`
export default ADD_PRODUCTS_TO_WISHLIST
