import {Box} from '@chakra-ui/react'
import URLHelper from '../../../helpers/URLHelper/URLHelper'
import {useRouter} from '../../hooks/useRouter'

const CmsLink = ({ link, id, ...rest }: any) => {
  const { locale } = useRouter()
  const style = {
      root: {
        width: '100%',
        '@media (minWidth: ${theme.breakpoints.lg}': {
          width: 'unset',
          flex: 1,
        }
      }
    }
    return (
      <>
        {
          link?.external_href || link?.href ? (
            <a
              style={style.root}
              href={URLHelper.createURL({ href: link?.href, external_href: link?.external_href, absolute: link?.absolute , addLocale: true, locale: locale })}
              target={`_${link.target || '_self'}`}
              id={id}
              rel={link?.rel}
            >
              <Box as="span" {...rest} />
            </a>
          ) : (
            <Box as="span" {...rest} />
          )
        }
      </>
    )
}

export default CmsLink
