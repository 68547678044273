import {useCallback, useMemo} from 'react'
import {reduce} from 'lodash-es'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {ProductCustomOptions, ProductItem, ProductOption, ProductOptionKey,} from '_app/types/product'
import useAttributesMetadata from '_app/hooks/useAttributesMetadata'
import {BundleItemOption, BundleProduct, SimpleProduct} from '_app/graphql/generated/graphql'
import {isBraceletProductType, isNecklaceProductType, isRingProductType} from '../utils/localized/getProductType'
import AttributesEnum from '../../enums/AttributesEnum/AttributesEnum'

export type OptionType = 'ring' | 'necklace' | 'size' | 'other' | 'bracelet';

export type RequiredOptions = {
  type: OptionType;
  options: { label: string; value: string }[];
}[];
interface UseProductOptions {
  productType: string;
  options?: ProductCustomOptions;
  requiredOptions?: RequiredOptions;
  getHeader(productType: string): string;
}

interface Props {
  product?: BundleProduct | SimpleProduct;
  upsellProducts?: Pick<ProductItem, 'url_key' | any>[];
}

const useProductOptions = ({
  product,
  upsellProducts,
}: Props): UseProductOptions => {
  const { metaData } = useAttributesMetadata({})
  const { t } = useTranslation()
  const { locale } = useRouter()
  const productType = useMemo(() => {
    const categories = product?.categories || []
    let productType = 'other'
    if (isNecklaceProductType(categories, locale)) {
      productType = 'necklace'
    }
    if (isRingProductType(categories, locale)) {
      productType = 'ring'
    }
    if (isBraceletProductType(categories, locale)) {
      productType = 'bracelet'
    }
    return productType
  }, [product?.categories])

  const getHeader = (productType: OptionType): string =>
    productType === 'necklace'
      ? t('CHAIN_SIZE_CM')
      : productType === 'other'
      ? t('OPTIONS')
      : t('SIZE')

  const allOptions: any = useMemo(() => {
    if (upsellProducts) return [product, ...upsellProducts]
    return [product]
  }, [product, upsellProducts])

  const filtered = allOptions.filter(option => {
    let result = 0
      AttributesEnum.CUSTOM_ATTRIBUTES.forEach((attr: ProductOptionKey) => {
      result += option?.[attr] == product?.[attr] ? 1 : 0
    })
    return result === AttributesEnum.CUSTOM_ATTRIBUTES.length - 1
  })

  const getProductOptions = useCallback(
    (attr: any) => {
      const availableOptions: ProductOption[] = []
      const attribute = metaData[attr]
      const allProducts = [product, ...filtered]

      allProducts.filter(item => item?.[attr]).map(option => {
          const optionAttribute = attribute?.find(({ value }) =>
              value && option[attr] && Number(value) === Number(option[attr])
          )
          const { image, label: value } = optionAttribute || {}
          return { url_key: option.url_key || '', image, value }
        })
        .forEach(
          product =>
            !availableOptions?.find(({ value }) => value === product?.value) &&
            availableOptions.push(product as ProductOption)
        )
      return availableOptions.sort((a, b) =>
        a?.value && b?.value && a.value > b.value ? 1 : -1
      )
    },
    [filtered, metaData, product]
  )

  const getAllOptions = useCallback(
    (attr: any) => {
      const availableOptions: Array<
        ProductOption & { swatch: number | undefined }
      > = []

      const attribute = metaData[attr]
      allOptions
        .filter((option: { [x: string]: any }) => !!option?.[attr])
        .map((option: { [x: string]: any; url_key: any }) => {
          const optionAttribute = attribute?.find(
            ({ value }) =>
              value && option[attr] && Number(value) === Number(option[attr])
          )
          const { image, label: value, value: swatch } = optionAttribute || {}
          return {
            url_key: option.url_key || '',
            image,
            value,
            swatch: swatch ? Number(swatch) : undefined,
          }
        })
        .forEach(
            (product: ProductOption & { swatch: number | undefined }) =>
            !availableOptions?.find(({ value }) => value === product?.value) &&
            availableOptions.push(product)
        )

      return availableOptions.sort((a, b) =>
        a?.value && b?.value && a.value > b.value ? 1 : -1
      )
    },
    [allOptions, metaData]
  )

  const options = reduce(
      AttributesEnum.CUSTOM_ATTRIBUTES,
    (acc, key: string ) => {
      if (key === 'rozmiar_swatch') return false
      if (!product?.[key]) {
        return { ...acc, [key as ProductOptionKey]: [] }
      }
      const productOptions = getProductOptions(key)
      const allOptionsForKey = getAllOptions(key)
      const keyOptions = allOptionsForKey.map((item, index) => ({
        value: item?.value,
        url_key:
          productOptions.find(({ value }) => value === item?.value)?.url_key ??
          `disabled=${index}`,
        image: item?.image ?? '',
        swatch: item.swatch,
      }))
      return { ...acc, [key as ProductOptionKey]: keyOptions }
    },
    {}
  ) as ProductCustomOptions
  const requiredOptions: { type: OptionType; options: { label: string; value: string }[] }[] = []
  product?.options?.filter(({ dropDown }: any) => !!dropDown).forEach(({ title, dropDown, categories }: any) => {
      const getSizeType = (): OptionType => {
        if (title?.toLowerCase().includes('rozmiar')) {
          if (categories?.some((category: string) => category?.toLowerCase().includes('pierścionki'))) {
            return 'ring'
          } else if (categories?.some((category: string) => category?.toLowerCase().includes('naszyjniki'))) {
            return 'necklace'
          } else return 'size'
        } else return 'other'
      }
      const type = getSizeType()
      requiredOptions.push({
        type,
        options: dropDown
          ? dropDown
              .map((option: any, index: number) => ({
                label: option?.sku ?? '',
                value: option?.uid ?? `option-${index}`,
              }))
              .filter((item: any) => item.label) ?? []
          : [],
      })
    })
  if (product?.__typename === 'SimpleProduct') {
    if (productType === 'necklace' && product?.rozmiar_swatch) {
      requiredOptions.push({
        type: 'size',
        options: [
          {
            label:
              (metaData &&
                metaData.rozmiar_swatch?.find(
                  ({ value }) => value === `${product?.rozmiar_swatch}`
                )?.label) ??
              '',
            value: '',
          },
        ],
      })
    }
  }

  if (product?.__typename === 'BundleProduct') {
    product?.items?.forEach(({ title, options }: any) => {
      const isSizeOption = (options ?? []).some((option: any) => option?.product?.rozmiar_swatch)
      const getSizeType = (): OptionType => {
        const isSizeTpe = title?.toLowerCase().includes('rozmiar')
        if (isSizeTpe) return 'size'
        return 'other'
      }
      requiredOptions.push({
        type: getSizeType(),
        options: isSizeOption
          ? ((options || []) as BundleItemOption[])
              .map(({ product, uid }) => ({
                label:
                  (metaData &&
                    metaData?.rozmiar_swatch?.find(
                      ({ value }) => value === `${product?.rozmiar_swatch}`
                    )?.label) ??
                  '',
                value: uid,
              }))
              .filter(item => item.label)
              .sort((a, b) => {
                if (isNaN(parseInt(a.label))) return 1
                else return parseInt(a.label) > parseInt(b.label) ? 1 : -1
              })
          : ((options || []) as BundleItemOption[])
              .map(({ product, uid }) => ({
                label: product?.name ?? '',
                value: uid,
              }))
              .filter(item => item.label)
              .sort((a, b) => {
                if (isNaN(parseInt(a.label))) {
                  return 1
                } else {
                  return parseInt(a.label) > parseInt(b.label) ? 1 : -1
                }
              }),
      })
    })
  }
  return {
    options,
    requiredOptions,
    getHeader,
    productType,
  }
}

export default useProductOptions
