import { Center } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { CloudflareImage } from '../../../_app/components'
import { useRouter } from 'next/router'

const MagicLoader = (props: any) => {
  const { children, loadingOutSide } = props
  const [isLoading, setIsLoading] = useState(true)
  const router = useRouter()

  const clickableTags = [
    'A', // Linki
    'IMG', // Zdjęcia
    'BUTTON', // Przyciski
    'INPUT', // Pola tekstowe
    'SELECT', // Pola wyboru
    'OPTION', // Opcje wyboru
    'LABEL', // Labelki
    'TEXTAREA', // Obszary tekstowe
    'CHECKBOX', // Checkbox
    'RADIO', // Radio button
  ]

  useEffect(() => {
    if (loadingOutSide) setIsLoading(loadingOutSide)
  }, [loadingOutSide])

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true)
    }
    const handleRouteChangeComplete = () => {
      setIsLoading(false)
    }
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeComplete)
    }
  }, [router.events])

  useEffect(() => {
    if (isLoading && document) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'initial'
    setIsLoading(false)
    return () => {
      document.body.style.overflow = 'initial'
    }
  }, [isLoading])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (clickableTags.includes(target.tagName.toUpperCase())) {
        setIsLoading(true)
        setTimeout(() => setIsLoading(false), 8000)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
      <>
        {isLoading && (
            <Center
                position="fixed"
                top={0}
                bottom={0}
                left={0}
                right={0}
                zIndex={10000}
            >
              <CloudflareImage
                  priority={true}
                  src="/loader.gif"
                  width={99}
                  height={66}
                  layout="fixed"
                  alt="Lilou"
                  unoptimized
              />
            </Center>
        )}
        {children}
      </>
  )
}

export default MagicLoader
