import {Box, Button, Center, HStack, Text} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {FormProvider, useForm} from 'react-hook-form'

import Input from '_app/components/Form/Input'
import Checkbox from '_app/components/Form/Checkbox'
import {useGetLayoutQuery} from '_app/graphql/cms/generated/graphql'
import CmsLink from '_app/components/Link/CmsLink'
import {getSocialIcon} from '_app/components/Footer/helpers'
import FormTextarea from '_app/components/Form/FormTextarea'
import toast from '_app/utils/toast'
import {useRouter} from '_app/hooks/useRouter'
import {initializeApollo} from '../../../helpers/ApolloHelper/ApolloHelper'
import {CONTACT_FROM} from '../../graphql/mutations/contact/ContactForm'
import RequestEnum from '../../../enums/RequestEnum/RequestEnum'

const schema = yup.object().shape({
    name: yup.string().required('errors.string.first_name'),
    email: yup.string().email().required('errors.string.email'),
    message: yup.string().required('errors.string.message'),
    consent: yup
        .boolean()
        .required('errors.checkbox.consent')
        .oneOf([true], 'errors.checkbox.consent'),
})

interface ContactFormProps {
    name: string;
    email: string;
    message: string;
    consent: boolean;
}

interface ComponentProps {
    setMsgSent: (isMsgSent: boolean) => void;
}

const ContactForm = ({setMsgSent}: ComponentProps) => {
    const {t} = useTranslation('contact')
    const router = useRouter()
    const {locale} = router
    const {data: dataFooter} = useGetLayoutQuery({
        context: {
            clientName: RequestEnum.CMS_CONTEXT,
        },
        variables: {
            locale,
        },
    })
    const methods = useForm<ContactFormProps>({
        resolver: yupResolver(schema),
    })

    const {
        handleSubmit,
        formState: {isSubmitting},
    } = methods

    const sendMessage = async ({...rest}: ContactFormProps) => {
        const apolloClient = initializeApollo(null, {locale})
        await apolloClient.mutate({
            mutation: CONTACT_FROM,
            variables: {...rest},
        }).then(() => {
            methods.reset({name: '', email: '', message: '', consent: false})
            setMsgSent(true)
        }).catch((error) => {
            toast({
                description: error.message,
                status: 'error',
            })
        })
    }
    return (
        <Box width="100%" maxWidth={{base: '100%', lg: '450px'}}>
            <Text textStyle="sectionHeader">{t('WRITE_TO_US')}</Text>
            <Text
                fontSize={16}
                fontWeight={300}
                lineHeight={1.38}
                my={{base: 4, lg: 6}}
            >
                {t('LEAVE_A_MESSAGE_AND_WE_WILL_CONTACT_YOU')}
            </Text>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(sendMessage)}>
                    <Input
                        placeholder={t('NAME_EXAMPLE')}
                        name="name"
                        label={t('NAME')}
                        isRequired
                    />
                    <Input
                        type="email"
                        placeholder={t('EMAIL_EXAMPLE')}
                        name="email"
                        label={t('EMAIL', {ns: 'common'})}
                        isRequired
                    />
                    <FormTextarea
                        placeholder={t('MESSAGE_QUESTION')}
                        name="message"
                        label={`${t('MESSAGE')} *`}
                    />

                    <Checkbox name="consent">
                        <Text fontSize={14} fontWeight={300} lineHeight={1.71}>
                            {t('DATA_PROCESSING_APPROVAL')}
                        </Text>
                    </Checkbox>

                    <Button
                        minWidth={{base: '50%', lg: '200px'}}
                        type="submit"
                        isLoading={isSubmitting}
                        mt={2}
                        width="full"
                    >
                        {t('SEND_MESSAGE')}
                    </Button>
                </form>
            </FormProvider>

            {dataFooter?.footer?.data?.attributes?.social_media &&
                !!dataFooter?.footer?.data?.attributes?.social_media.length && (
                    <Center py={6}>
                        <HStack spacing="16px">
                            {dataFooter?.footer?.data?.attributes?.social_media.map(
                                (social, idx) =>
                                    social?.text && (
                                        <Box cursor="pointer" key={`socials[${idx}]`}>
                                            <CmsLink link={social}>
                                                {getSocialIcon(social.text, 24)}
                                            </CmsLink>
                                        </Box>
                                    )
                            )}
                        </HStack>
                    </Center>
                )}
        </Box>
    )
}

export default ContactForm
