import {
  Textarea as ChakraUITextarea,
  TextareaProps as ChakraUITextareaProps,
} from '@chakra-ui/react'
import { isFunction } from '@chakra-ui/utils'
import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

interface TextareaProps extends ChakraUITextareaProps {
  resize?: 'none';
  focusBorderColor?: 'none';
}

const Textarea: FC<TextareaProps> = ({ onChange, ...props }) => {
  const textareaElement = useRef<HTMLTextAreaElement>(null)
  const [textareaLength, setTextareaLength] = useState(
    (props.value
      ? props.value
      : props.defaultValue
      ? props.defaultValue
      : ''
    ).toString().length
  )
  const autoHeight = useCallback(
    (element: EventTarget & HTMLTextAreaElement) => {
      if (element.value.length < textareaLength) {
        element.style.minHeight = 'unset'
        element.style.overflowY = 'unset'
      }
      element.style.minHeight = `${element.scrollHeight}px`
      element.style.overflowY = 'hidden'
      setTextareaLength(element.value.length)
    },
    [textareaLength]
  )
  const changeHandler: ChangeEventHandler<HTMLTextAreaElement> = e => {
    autoHeight(e.target)

    if (isFunction(onChange)) {
      onChange(e)
    }
  }

  useEffect(() => {
    if (textareaElement.current) {
      autoHeight(textareaElement.current)
    }
  }, [textareaElement, autoHeight, props.defaultValue])

  return (
    <ChakraUITextarea
      ref={textareaElement}
      resize="none"
      focusBorderColor="none"
      fontSize="1rem"
      borderRadius="0"
      borderTop="none"
      borderRight="none"
      borderLeft="none"
      borderBottom="solid 1px #707070"
      _hover={{ borderBottom: 'solid 1px #707070' }}
      px="0"
      pt="0.25rem"
      pb="0.5rem"
      minH="none"
      {...props}
      onChange={changeHandler}
    />
  )
}

export default Textarea
