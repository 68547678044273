import {Box, Center, Container} from '@chakra-ui/react'
import Image from 'next/legacy/image'
import PageSimpleHeader from '_app/components/Page/PageSimpleHeader'
import ElementsSizesEnum from '../../enums/ElementsSizesEnum/ElementsSizesEnum'
import SeoHelper from '../../helpers/SeoHelper/SeoHelper'

const ImageWithForm: any = ({ children }: any) => (
  <PageSimpleHeader
      seo={{
        title: SeoHelper.getTitle(),
        description: SeoHelper.getDescription(),
      }}
  >
    <Container overflow="hidden">
      <Center
        height={{ base: 'auto', lg: `calc(100vh - ${ElementsSizesEnum.HEADER_HEIGHT})` }}
        width="100%"
        py={{ base: 12, lg: 'auto' }}
      >
        <Box
          position="relative"
          flexShrink={0}
          flexGrow={0}
          flex={{ base: 1, md: '50%' }}
          width="43.125rem"
          height="52.5rem"
          maxHeight="100%"
          maxWidth="50%"
          display={{ base: 'none', lg: 'block' }}
        >
          <Image
            src="/images/login-photo.webp"
            alt="Portrait"
            sizes="50vw"
            layout="fill"
            objectFit="cover"
            quality={100}
            priority={true}
          />
        </Box>
        <Center
          flex={{ base: '100%', lg: '50%' }}
          width={{ base: '100%', lg: '50%' }}
          height="100%"
          flexDirection="column"
          overflowY="auto"
        >
          <Box
            maxHeight="100%"
            maxW={{ base: '100%', lg: '20.625rem' }}
            width="100%"
          >
            {children}
          </Box>
        </Center>
      </Center>
    </Container>
  </PageSimpleHeader>
)

export default ImageWithForm
