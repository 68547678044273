import {ProductInterface} from '../../_app/graphql/generated/graphql'
import ProductHelper from './ProductHelper'
import {ProductAvailability} from '../../_app/types/dataLayer'

export default {
    isPersonalizedProduct: (product: any) => {
        return (product?.options as any[])?.some(option =>
            option?.__typename === 'CustomizableEngraveOption' &&
            ['malowanie_1', 'malowanie_2'].includes(option?.value?.code || '')
        )
    },
    isPersonalizedProducts: (products: any[]) => {
        return products?.some(product =>
            (product?.options as any[])?.some(option =>
                option?.__typename === 'CustomizableEngraveOption' &&
                ['malowanie_1', 'malowanie_2'].includes(option?.value?.code || '')
            )
        )
    },
    getNameAvailable: (quantity) => {
        if (quantity && quantity >= 0) {
            switch (quantity) {
                case 0:
                    return ProductAvailability.OutOfStock
                case 1:
                    return ProductAvailability.InStock13
                case 2:
                    return ProductAvailability.InStock13
                case 3:
                    return ProductAvailability.InStock13
                default:
                    return ProductAvailability.InStock
            }
        }
    },
    getCheckedSelectedOptions: (options: any[]) => {
        if (typeof options === 'object') {
            let isExist = true
            options?.forEach((el) => {
                if (!el) {
                    isExist = false
                }
            })
            return isExist
        }
        return options
    },
    getAvailableAmount: (itemsOptions, selectedOptions, salable_qty = 0, product) => {
        let availableProductAmount: any = undefined
        const outOfStock = product?.stock_status === 'OUT_OF_STOCK'
        if (outOfStock) {
            availableProductAmount = 0
        } else {
            if (product?.__typename === 'BundleProduct') {
                const allOptions = (itemsOptions ?? []).reduce((prev: any, item: any) => {
                    return [...prev, ...(item?.options ?? [])]
                }, [])
                let options_salable_qty = []
                if (ProductHelper.getCheckedSelectedOptions(selectedOptions)) {
                    options_salable_qty = allOptions
                        ?.filter((option: {
                            uid?: string;
                            product: ProductInterface
                        }) => option?.uid && selectedOptions?.includes(option?.uid))
                        .map((option: { uid?: string; product: ProductInterface }) => option?.product?.salable_qty)
                } else {
                    options_salable_qty = allOptions
                        .map((option: { uid?: string; product: ProductInterface }) => option?.product?.salable_qty)
                }
                if (!!options_salable_qty?.length) {
                    availableProductAmount = options_salable_qty.sort((a: string, b: string) => parseInt(a) < parseInt(b) ? -1 : 1)[0]
                } else {
                    availableProductAmount = 0
                }
            } else {
                if (salable_qty !== undefined) {
                    availableProductAmount = salable_qty
                }
            }
        }
        return availableProductAmount
    }
}