import { Center } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import useGiftPriceThreshold from './useGiftPriceThreshold'

export const GiftBar = () => {
  const { amountLeft, shouldSkip, isCartEmpty } = useGiftPriceThreshold()
  const { t } = useTranslation()

  if (shouldSkip() || isCartEmpty()) return null

  return (
    <Center bg="black" h="2rem" color="white" mx="-1rem">
      {t('GIFT_BAR_TEXT', { amountLeft: amountLeft.toFixed(2) })}
    </Center>
  )
}

export default GiftBar
