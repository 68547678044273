import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function CloseIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g transform="rotate(45 12 12)">
        <path transform="translate(12 5.636)" d="M0 0v12.728" />
        <path transform="translate(5.636 12)" d="M0 0h12.728" />
      </g>
    </Icon>
  )
}
