import {Grid, GridItem} from '@chakra-ui/react'
import GridContent from './GridContent'
import {BannerGridProps} from './types'
import {generateItemId} from '.'

const BannerGridDesktop = ({ items = [], id }: BannerGridProps) => {
  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(4, 1fr)"
      gap="30px"
      display={{
        base: 'none',
        lg: 'grid',
      }}
    >
      {items.map((item, index) => (
        <GridItem
          key={index}
          rowSpan={index === 0 ? 2 : 1}
          colSpan={index === 0 ? 2 : 1}
        >
          <GridContent
            sizes={
              index === 0
                ? '(min-width: 1443px) 690px, (max-width: 1442px) 50vw,'
                : '(min-width: 1443px) 330px, (max-width: 1442px) 25vw,'
            }
            {...item}
            analyticsId={generateItemId(id, index)}
          />
        </GridItem>
      ))}
    </Grid>
  )
}

export default BannerGridDesktop
