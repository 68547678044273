import FontHelper from '../../../helpers/FontHelper/FontHelper'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import URLHelper from '../../../helpers/URLHelper/URLHelper'

export const StyledCtaButton = styled.a`
  width: 100%;
  font-size: 14px;
  padding: 16px 12px;
  background: #ffffff;
  color: black;
  display: inline-flex;
  outline: 2px solid transparent;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  max-width: ${FontHelper.getPxFromRem(13)};
  font-weight: 600;
  line-height: 1.2;
  transition: background 0.3s;
  &:hover {
    background: #d3d3d3;
  }
`

const CtaButton = ({ cta }: any) => {
  const { link, text, ...rest } = cta
  return (
    <>
      {
        cta && link && (
          <StyledCtaButton
            suppressHydrationWarning={true}
            href={URLHelper.createURL(link)}
            {...rest}
          >
            {text}
          </StyledCtaButton>
        )
      }
    </>
  )
}

export default CtaButton
