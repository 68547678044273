import React, {useMemo} from 'react'
import {Autoplay, Pagination} from 'swiper'
import Carousel from '_app/components/Carousel'
import {ImageBanner} from '_app/components/Banner'
import {ComponentSectionsBanner} from '_app/types/cms'
import ImageEnum from '../../../enums/ImageEnum/ImageEnum'

interface ContentSliderProps {
  items?: Array<ComponentSectionsBanner | undefined>;
}

const ContentSlider = ({ items = [] }: ContentSliderProps) => {
  const slides = useMemo(
    () =>
      items
        .filter((item): item is ComponentSectionsBanner => !!item)
        .map((item, idx) => {
          return {
            id: idx,
            content: (
              <ImageBanner
                boxProps={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                imgRatio={ImageEnum.BANNER_IMAGE_RATIO}
                {...item}
              />
            ),
          }
        }),
    [items]
  )

  return (
    <Carousel
      modules={[Pagination, Autoplay]}
      slidesPerView={1}
      pagination={{ clickable: true }}
      longSwipes={false}
      speed={400}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      slides={slides}
    />
  )
}

export default ContentSlider
