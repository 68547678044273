import React from 'react'
import dynamic from 'next/dynamic'
import {NextSeo, NextSeoProps} from 'next-seo'
import {Box, Center, Spinner} from '@chakra-ui/react'
import {useRouter} from '_app/hooks/useRouter'
import useVisitorEvent from '_app/hooks/useVisitorEvent'
import usePageData from './usePageData'
import URLHelper from '../../../helpers/URLHelper/URLHelper'
import ElementsSizesEnum from '../../../enums/ElementsSizesEnum/ElementsSizesEnum'

const DynamicTopBar = dynamic(() => import('_app/components/TopBar/TopBar'))
const DynamicNavBar = dynamic(
    () => import('_app/components/Navigation/NavBar'),
)
const DynamicFooter = dynamic(() => import('_app/components/Footer/Footer'))

interface PageProps {
    seo?: NextSeoProps;
    isLoading?: boolean;
    isNavBarTransparent?: boolean;
    bg?: string;
    hasNoIndex?: boolean;
    hasNoFollow?: boolean;
    children?: any;
    customCanonical?: string;
}

const Page: React.FC<PageProps> = ({
                                       children,
                                       seo,
                                       isLoading,
                                       isNavBarTransparent,
                                       bg,
                                       hasNoIndex,
                                       hasNoFollow,
                                       customCanonical = null,
                                   }) => {
    const router = useRouter()
    const canonicalUrl = URLHelper.translateHref(router?.asPath === '/' ? '' : router?.asPath, router?.locale) as string
    const {data, bannerItems} = usePageData()
    useVisitorEvent()
    return (
        <>
            {
                seo?.title && (
                    <NextSeo
                        {...{
                            canonical: customCanonical || canonicalUrl,
                            ...seo,
                            openGraph: {
                                url: customCanonical || canonicalUrl,
                                ...seo?.openGraph,
                            },
                            noindex: hasNoIndex,
                            nofollow: hasNoFollow,
                        }}
                    />
                )
            }
            <Box>
                <DynamicTopBar items={bannerItems}/>
                <DynamicNavBar data={data} isTransparent={isNavBarTransparent}/>
                <Box
                    as="main"
                    mt={ElementsSizesEnum.NAVBAR_HEIGHT}
                    minH={{
                        base: `calc(100vh - ${ElementsSizesEnum.NAVBAR_HEIGHT.base} - ${ElementsSizesEnum.FOOTER_HEIGHT.base})`,
                        lg: `calc(100vh - ${ElementsSizesEnum.NAVBAR_HEIGHT.lg} - ${ElementsSizesEnum.FOOTER_HEIGHT.lg})`,
                    }}
                    bg={bg}
                >
                    {isLoading ? (
                        <Center height="18.75rem" width="100%">
                            <Spinner my={8}/>
                        </Center>
                    ) : (
                        children
                    )}
                </Box>
                <DynamicFooter data={data}/>
            </Box>
        </>
    )
}

export default Page
