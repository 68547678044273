import { Icon as ChakraIcon, IconProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const Icon = styled((props: Omit<IconProps, 'css'>) => (
  <ChakraIcon fontSize="24px" {...props} />
))`
  path,
  rect,
  circle,
  ellipse {
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: ${props => props.fill || 'none'};
    stroke: currentColor;
    stroke-width: ${props => props.strokeWidth || '1.1px'};
  }
`
