import React, { FC } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  FormControl,
  Checkbox,
  CheckboxGroup as ChakraCheckboxGroup,
  CheckboxProps,
} from '@chakra-ui/react'
import { get } from 'lodash-es'

interface CheckboxOption {
  label: React.ReactNode;
  value: string | number;
}

interface CheckboxGroupProps {
  name: string;
  options: CheckboxOption[];
  optionProps?: CheckboxProps;
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  name,
  options,
  optionProps,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const isInvalid = Boolean(get(errors, name))

  return (
    <FormControl isInvalid={isInvalid} width="auto">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <ChakraCheckboxGroup value={value} onChange={e => onChange(e)}>
            {options.map(o => (
              <Checkbox
                key={`${name}.${o.value}`}
                value={o.value}
                {...optionProps}
              >
                {o.label}
              </Checkbox>
            ))}
          </ChakraCheckboxGroup>
        )}
      />
    </FormControl>
  )
}

export default CheckboxGroup
