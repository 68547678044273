import React, { useRef } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Text,
  useOutsideClick,
} from '@chakra-ui/react'
import ChevronDownIcon from '_app/components/Icons/ChevronDownIcon'

interface DropdownProps extends BoxProps {
  text: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  onToggle,
  onClose,
  text,
  children,
  ...dropdownProps
}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  return (
    <div ref={ref}>
      <Button
        variant="unstyled"
        textAlign="left"
        px={0}
        borderBottomWidth={1}
        borderColor="black"
        onClick={onToggle}
        minH="2.375rem"
        width="100%"
        minWidth="3.125rem"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text as="span" textStyle="body">
            {text}
          </Text>
          <ChevronDownIcon
            sx={{
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
              transition: 'transform 0.2s',
            }}
          />
        </Flex>
      </Button>
      <Box
        position="absolute"
        left={0}
        right={0}
        borderWidth={1}
        borderColor="black"
        padding="2.5rem"
        mt={4}
        background="white"
        zIndex={10}
        display={isOpen ? 'block' : 'none'}
        {...dropdownProps}
      >
        {children}
      </Box>
    </div>
  )
}

export default Dropdown
