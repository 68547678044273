import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function GiftIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect
        id="Rectangle_1318"
        width="18"
        height="4"
        rx="1"
        transform="translate(3.5 8.5)"
      />
      <path id="Line_177" transform="translate(12.5 8.5)" d="M0 0v13" />
      <path id="Path_424" d="M19.5 12.5v7a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2v-7" />
      <path
        id="Path_425"
        d="M7.5 8.5c-1.381 0-2-1.619-2-3s.619-2 2-2c1.974-.034 4.26 1.949 5 5 .74-3.051 2.026-5.034 4-5 1.381 0 3 .619 3 2a3.439 3.439 0 0 1-3 3"
      />
    </Icon>
  )
}
