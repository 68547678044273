import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function SendDataIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        data-name="Path 13723"
        d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2"
        stroke="#000"
        fill="none"
      />
      <path data-name="Path 13724" d="m7 9 5-5 5 5" stroke="#000" fill="none" />
      <path
        data-name="Line 339"
        transform="translate(12 4)"
        stroke="#000"
        fill="none"
        d="M0 0v12"
      />
    </Icon>
  )
}
