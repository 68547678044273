import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  ResponsiveValue,
  ThemeTypings,
} from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface ButtonProps extends ChakraButtonProps {
  reversed?: boolean;
}

const buttonProps = (reversed?: any, variant?: any) => {
  return reversed
    ? {
        color: variant === 'outline' ? 'white' : 'black',
        colorScheme: 'light',
      }
    : {}
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, reversed, variant, disabled, ...props },
  ref
) {
  return (
    <ChakraButton
      {...props}
      {...buttonProps(reversed, variant)}
      ref={ref}
      isDisabled={disabled}
      variant={variant}
    >
      {children}
    </ChakraButton>
  )
})

export default Button
