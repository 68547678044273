import React from 'react'
import {Box, Container, Flex, Text} from '@chakra-ui/react'
import styled from '@emotion/styled'
import {HeaderProps} from '_app/types/content'
import theme from 'theme'
import ImageCms from '_app/components/Content/ImageCms'
import ElementsSizesEnum from '../../../enums/ElementsSizesEnum/ElementsSizesEnum'

const StyledWrapper = styled(Flex)`
  align-items: center;
  position: relative;
  height: 100%;

  @media (min-width: ${theme.breakpoints.lg}) {
    position: absolute;
    inset: 0;
  }
`

const Header = ({
  img,
  img_mobile,
  bg,
  color,
  title,
  subtitle,
  cover_navbar,
}: HeaderProps) => {
  const imgAttr = img?.data?.attributes
  const mobileImgAttr = img_mobile?.data?.attributes

  return (
    <Box
      mt={{
        base: 0,
        lg: cover_navbar ? `-${ElementsSizesEnum.NAVBAR_HEIGHT.lg}` : 0,
      }}
      bg={bg}
      color={color}
      position="relative"
    >
      {imgAttr?.width && imgAttr?.height && imgAttr?.url && (
        <Box sx={{ height: '450px', display: 'flex' }}>
          <ImageCms src={''} attributes={imgAttr} width={imgAttr?.width} height={imgAttr?.height} objectFit="cover" alt={''} />
        </Box>
      )}
      {mobileImgAttr?.url && (
        <Box
          display={{
            base: 'block',
            lg: 'none',
          }}
        >
          <ImageCms src={''} attributes={mobileImgAttr}  width={imgAttr?.width} height={imgAttr?.height} layout="fill" objectFit="cover" alt={''} />
        </Box>
      )}
      <StyledWrapper>
        <Container
          pt={{
            base: '2rem',
            lg: cover_navbar ? '3rem' : 0,
          }}
          pb={{
            base: '2rem',
            lg: 0,
          }}
        >
          <Box>
            <Text as="h1" textStyle="pageHeader">{title}</Text>
            {subtitle && (
              <Text color="inherit" mt={4}>
                {subtitle}
              </Text>
            )}
          </Box>
        </Container>
      </StyledWrapper>
    </Box>
  )
}

export default Header
