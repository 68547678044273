import type { Colors } from '@chakra-ui/react'

const baseColors = {
  black: '#000000',
  white: '#ffffff',
  mercury: '#e5e5e5',
  hoverDark: '#646464',
  hoverLight: '#d3d3d3',
  disabled: '#8e8e8e',
  input: '#989898',
  alert: '#ff003b',
  divider: '#dddddd',
  main: '#fff8f4',
  veryLightPink: '#fdf6f0',
  darkGray: '#999999',
  lightPink: '#FFF8F4',
  saffronSunset: '#DA9A35',
  focusTransparent: '#DA9A3533',
  focus: '#DA9A35',
  borderLight: '#ede7e1',
  grey: '#707070',
}

const dark = {
  50: baseColors.hoverLight,
  200: baseColors.black,
  500: baseColors.black,
  600: baseColors.hoverDark,
}

const light = {
  50: baseColors.hoverDark,
  500: baseColors.white,
  600: baseColors.hoverLight,
}

export const colors: Colors = {
  ...baseColors,
  dark,
  light,
}
