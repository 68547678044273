import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function RefreshIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        id="Path_593"
        data-name="Path 593"
        d="M4.05,11a8,8,0,1,1,.5,4m-.5,5V15h5"
        fill="none"
        stroke="#000"
      />
    </Icon>
  )
}
