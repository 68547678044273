import { UseToastOptions } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'

const { toast } = createStandaloneToast()

const displayedToasts = new Set<string>()

export default (
    arg: {
      message?: string,
      description: string,
      status?: 'info' | 'warning' | 'success' | 'error' | 'loading' | undefined,
    } | Error | UseToastOptions,
    params?: UseToastOptions,
) => {
  const description = arg instanceof Error ? arg.message : arg?.description

  if (description && displayedToasts.has(description?.toString())) {
    return
  }

  if (description) {
    displayedToasts.add(description?.toString())
    setTimeout(() => {
      displayedToasts.delete(description?.toString())
    }, 5000)
  }

  if (arg instanceof Error) {
    return toast({
      isClosable: true,
      position: 'top',
      duration: 8000,
      ...params,
      description: arg.message,
      status: 'error',
    })
  }
  if (arg?.description) {
    return toast({
      isClosable: true,
      position: 'top',
      duration: 5000,
      title: arg.description,
      status: arg?.status || 'success',
    })
  }
}