import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function MenuIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path transform="translate(4.5 6.5)" d="M0 0h16" />
      <path transform="translate(4.5 12.5)" d="M0 0h16" />
      <path transform="translate(4.5 18.5)" d="M0 0h16" />
    </Icon>
  )
}
