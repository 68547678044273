import {Box, Link, Text} from '@chakra-ui/react'
import URLHelper from '../../../helpers/URLHelper/URLHelper'
import {useRouter} from '../../hooks/useRouter'

export const DisplayNews = ({
  link,
}: {
  link: {
    text?: string;
    link?: {
      href?: string;
      target?: string;
    };
  };
}) => {
  const { locale } = useRouter()
  if (!link?.text) return null
  const text = link?.text
  const href = link?.link?.href || '/'
  const target = URLHelper.formatTarget(link?.link?.target)
  return (
    <Box textAlign="center" py={{ base: 4, md: 0 }}>
      <Link href={`/${locale}${href}`} target={target}>
        <Text
          textDecoration="underline solid white 0.125rem"
          cursor="pointer"
        >
          {text}
        </Text>
      </Link>
    </Box>
  )
}
