import {Box, Container, Flex, Text} from '@chakra-ui/react'
import Image from 'next/legacy/image'
import Page from '_app/components/Page/Page'
import SeoHelper from '../helpers/SeoHelper/SeoHelper'
import {useRouter} from '../_app/hooks/useRouter'
import {useEffect} from 'react'
import CookiesHelper from '../helpers/CookiesHelper/CookiesHelper'
import LocalStorageHelper from '../helpers/LocalStorageHelper/LocalStorageHelper'
import {cart, cartIdVar, customerTokenVar, emailOnCart, isAuthenticatedVar, wishlistIdVar} from '../_app/cache'
import {GTM_EVENTS} from '../_app/utils/dataLayer'

const Custom500 = (props: any) => {
    const { error } = props
    const { locale } = useRouter()
    const translations = {
        pl: {
            'SOMETHING_WENT_WRONG': 'Coś poszło nie tak',
            'WE_ARE_WORKING_ON_SOLVING_THE_PROBLEM': 'Pracujemy nad rozwiązaniem problemu'
        },
        de: {
            'SOMETHING_WENT_WRONG': 'Etwas ist schief gelaufen',
            'WE_ARE_WORKING_ON_SOLVING_THE_PROBLEM': 'Wir arbeiten an der Lösung des Problems'
        },
        fr: {
            'SOMETHING_WENT_WRONG': 'Quelque chose s\'est mal passé',
            'WE_ARE_WORKING_ON_SOLVING_THE_PROBLEM': 'Nous travaillons pour résoudre le problème'
        },
        en: {
            'SOMETHING_WENT_WRONG': 'Something went wrong',
            'WE_ARE_WORKING_ON_SOLVING_THE_PROBLEM': 'We are working on solving the problem'
        }
    }
    useEffect(() => {
        GTM_EVENTS.errors({
            message: 'Error 500',
            path: window?.location?.href,
            error: error,
            locale,
        })
        CookiesHelper.clear()
        LocalStorageHelper.clear()
        isAuthenticatedVar(false)
        customerTokenVar(null)
        wishlistIdVar(null)
        cartIdVar(null)
        emailOnCart(null)
        cart(null)
    }, [])
    return (
        <Page
            seo={{
                title: SeoHelper.getTitle('500'),
                description: SeoHelper.getDescription(),
            }}
            hasNoFollow={true}
            hasNoIndex={true}
        >
            <Container px={4}>
                <Flex alignItems="center" flexDirection={{base: 'column', md: 'row'}}>
                    <Box order={{base: 2, md: 1}}>
                        <Text
                            mt={4}
                            lineHeight={1}
                            fontSize={{
                                base: '90px',
                                lg: '140px',
                            }}
                        >
                            500
                        </Text>
                        <Text textStyle="sectionHeader">{translations?.[locale]?.['SOMETHING_WENT_WRONG']}</Text>
                        <Text textStyle="bodyLight" whiteSpace="pre-line" mt="24px" mb={4}>
                            {translations?.[locale]?.['WE_ARE_WORKING_ON_SOLVING_THE_PROBLEM']}
                        </Text>
                    </Box>
                    <Box
                        ml={{base: 0, md: '64px'}}
                        order={{base: 1, md: 2}}
                        flex={1}
                        width={{base: '100%', md: undefined}}
                    >
                        <Image
                            src="/images/500.png"
                            width={1313}
                            height={876}
                            layout="responsive"
                            alt="500"
                        />
                    </Box>
                </Flex>
            </Container>
        </Page>
    )
}

export default Custom500