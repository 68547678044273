import ConfigurationHelper from '../ConfigurationHelper/ConfigurationHelper'
import {useRouter} from '../../_app/hooks/useRouter'

export default {
    getTitle: (name: any = '') => {
        const router = useRouter()
        const title = name || ConfigurationHelper.getConfiguration('default_page_title', router)
        let prefix = ConfigurationHelper.getConfiguration('head_title_prefix', router) || ''
        let suffix = ConfigurationHelper.getConfiguration('head_title_suffix', router) || ''
        prefix = prefix.trim()
        suffix = suffix.trim()
        const formattedPrefix = prefix ? `${prefix} ` : ''
        const formattedSuffix = suffix ? ` ${suffix}` : ''
        return `${formattedPrefix || ''}${title || ''}${formattedSuffix || ''}`
    },
    getDescription: (name: any = '') => {
        const router = useRouter()
        return name || ConfigurationHelper.getConfiguration('default_page_description', router)
    }
}