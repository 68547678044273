import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ShoppingCartIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="m7 10 5-6 5 6" />
      <path d="m21 10-2 8a2.158 2.158 0 0 1-2 2H7a2.158 2.158 0 0 1-2-2l-2-8z" />
    </Icon>
  )
}
