import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function InfoIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle data-name="Ellipse 234" cx="12" cy="12" r="9" />
      <path data-name="Line 211" transform="translate(12 8)" d="M0 0h.01" />
      <path data-name="Path 468" d="M11 12h1v4h1" />
    </Icon>
  )
}
