import * as Apollo from '@apollo/client'
import {GetProductsListQuery, GetProductsListQueryVariables} from '../../generated/graphql'
import {gql} from '@apollo/client'

const defaultOptions = {} as const

export function useGetProductsListQuery(baseOptions: Apollo.QueryHookOptions<GetProductsListQuery, GetProductsListQueryVariables> & ({ variables: GetProductsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetProductsListQuery, GetProductsListQueryVariables>(GetProductsListDocument, options)
}

export const GetProductsListDocument = gql`
    query GetProductsList($pageSize: Int!, $currentPage: Int!, $search: String, $filter: ProductAttributeFilterInput = {}) {
        products(
            pageSize: $pageSize
            currentPage: $currentPage
            search: $search
            filter: $filter
        ) {
            items {
                id
                uid
                name
                image {
                    url
                    label
                }
                hover
                main_category {
                    name
                    url_key
                }
                categories {
                    name
                }
                price {
                    regularPrice {
                        amount {
                            currency
                            value
                        }
                    }
                    minimalPrice {
                        amount {
                            currency
                            value
                        }
                    }
                }
                price_range {
                    minimum_price {
                        regular_price {
                            currency
                            value
                        }
                        final_price {
                            currency
                            value
                        }
                    }
                }
#                related_products {
#                    color
#                    rozmiar_swatch
#                }
#                upsell_products {
#                    material_swatch
#                    rozmiar_swatch
#                }
                ... on BundleProduct {
                    items {
                        title
                        options {
                            uid
                            id
                            quantity
                            product {
                                id
                                sku
                                url_key
                                name
#                                rozmiar_swatch
#                                kolor3_swatch
#                                material_swatch
                                salable_qty
                            }
                        }
                    }
                }
#                ... on CustomizableProductInterface {
#                    options {
#                        title
#                        required
#                        sort_order
#                        uid
#                        ... on CustomizableDropDownOption {
#                            dropDown: value {
#                                sku
#                                price
#                                uid
#                            }
#                        }
#                    }
#                }
                url_key
#                material_swatch
#                rozmiar_swatch
                sku
                salable_qty
            }
#            filters {
#                filter_items {
#                    items_count
#                    label
#                    value_string
#                    ... on LayerFilterItem {
#                        items_count
#                        label
#                        value_string
#                    }
#                    ... on SwatchLayerFilterItem {
#                        items_count
#                        label
#                        value_string
#                        swatch_data {
#                            type
#                            value
#                        }
#                    }
#                }
#                filter_items_count
#                name
#                request_var
#            }
        }
    }
`