import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function LightbulbIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M3,12H4m8-9V4m8,8h1M5.6,5.6l.7.7m12.1-.7-.7.7"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M9,16a5,5,0,1,1,6,0,3.5,3.5,0,0,0-1,3,2,2,0,0,1-4,0,3.5,3.5,0,0,0-1-3"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="4.6"
        transform="translate(9.7 17)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </Icon>
  )
}
