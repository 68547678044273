import { gql } from '@apollo/client'

export const REMOVE_PRODUCTS_FROM_WISHLIST = gql`
  mutation RemoveProductsFromWishlist(
    $wishlistId: ID!
    $wishlistItemsIds: [ID!]!
  ) {
    removeProductsFromWishlist(
      wishlistId: $wishlistId
      wishlistItemsIds: $wishlistItemsIds
    ) {
      wishlist {
        id
      }
    }
  }
`
export default REMOVE_PRODUCTS_FROM_WISHLIST
