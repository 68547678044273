import React, {useMemo} from 'react'
import {Text, VStack} from '@chakra-ui/react'
import {uniqueId} from 'lodash-es'
import {useRouter} from 'next/router'
import CarouselSection from '_app/components/CarouselSection'
import Item from 'modules/Listing/Item'
import {dropMaybe} from '_app/utils/dropMaybe'

const ProductsCarousel = ({
                              title,
                              titleProps,
                              items = [],
                              rows = 1,
                              swiperProps,
                              hideCart,
                          }: any) => {
    const {asPath} = useRouter()
    const listName = useMemo(() => asPath.replace('.html', ''), [asPath])
    const id = useMemo(() => uniqueId('products-carousel'), [])

    let slides: React.ReactNode[] = []

    const generateItem = (index, item, hideCart) => {
        return (
            <Item
                key={`${index}-${item.name}`}
                item={item}
                hideCart={hideCart}
                category={dropMaybe(item?.main_category?.[0])}
                position={index + 1}
                listName={listName}
            />
        )
    }

    if (rows === 1) {
        slides = items.map((item, index) => generateItem(index, item, hideCart))
    } else {
        const groups = items
            .map((e, i) => {
                return i % rows === 0 ? items.slice(i, i + rows) : null
            })
            .filter(e => {
                return e
            })
        slides = groups.map((group, i) => (
            <VStack as="span" w="100%" key={`${id}-${i}`} spacing="30px">
                {group?.map((item, index) => generateItem(index, item, hideCart))}
            </VStack>
        ))
    }
    // disable header to instagram section
    return (
        <>
            {
                items?.length > 0 && (
                    <CarouselSection
                        title={
                            title && (
                                <Text as="h2" textStyle="h3" {...titleProps}>
                                    {title}
                                </Text>
                            )
                        }
                        slides={slides}
                        swiperProps={swiperProps}
                    />
                )
            }
        </>
    )
}

export default ProductsCarousel
