import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ChevronDownIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        data-name="Path 10"
        d="M4.923 0 0 4.624 4.923 9"
        transform="rotate(-90 10.962 3.461)"
      />
    </Icon>
  )
}
