import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ThirtyDaysIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 25.8 26.2" {...props}>
      <style type="text/css">
        {`.st0{fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st1{fill:none;stroke:#000000;stroke-width:0.3;stroke-miterlimit:10;}`}
      </style>
      <path
        className="st0"
        d="M3.3,17.8c1.3-0.7,3.8-1.8,5-1.8c1.6,0,7.1,0.9,7.1,0.9s2.7,0.2,3,1s0.6,2-1,2h-6"
      />
      <path
        className="st0"
        d="M3.6,24.9c0,0,3.5-1.4,4.8-1s3.2,1.4,5.7,0s11.3-6.5,11.3-7.2s-0.8-2.4-3.2-1.2s-3.9,2.2-3.9,2.2"
      />
      <circle className="st0" cx="15.5" cy="6.6" r="6.2" />
      <path
        className="st0"
        d="M1,16.9h1.7c0.3,0,0.6,0.3,0.6,0.6v7.7c0,0.3-0.3,0.6-0.6,0.6H1c-0.3,0-0.6-0.3-0.6-0.6v-7.7  C0.4,17.2,0.7,16.9,1,16.9z"
      />
      <path
        className="st0"
        d="M21.4,14.9c0,0-1.4-0.3-3.2,0.9c-0.7,0.5-1.4,0.9-1.9,1.2"
      />
      <path className="st0" d="M17.4,14.9c0,0-1.1-0.5-3.5,1.8" />
      <polyline className="st1" points="12.5,5.2 15.3,5.2 12.5,8.9 15.4,8.9 " />
      <line className="st1" x1="17.3" y1="3.1" x2="17.3" y2="9.1" />
      <line className="st1" x1="16.3" y1="6.3" x2="18.3" y2="5.2" />
    </Icon>
  )
}
