import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function FacebookIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path
        d="M7 12.33v5.331h4v9.33h5.33v-9.33h4l1.333-5.331H16.33V9.664a1.333 1.333 0 0 1 1.333-1.333h4V3h-4A6.664 6.664 0 0 0 11 9.664v2.666H7"
        transform="translate(2.33 .999)"
      />
    </Icon>
  )
}
