import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ArrowLeftIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path transform="translate(5 12)" d="M0 0h14" />
      <path transform="translate(5 12)" d="m0 0 4 4" />
      <path transform="translate(5 8)" d="m0 4 4-4" />
    </Icon>
  )
}
