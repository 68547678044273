import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react'
import { ComponentNavigationTopMenu } from '_app/graphql/cms/generated/graphql'
import theme from 'theme'
import { SubitemListItem } from './SubitemListItem'

interface NavBarAccordionItemProps {
  item: ComponentNavigationTopMenu;
}

export const NavBarAccordionItem = ({ item }: NavBarAccordionItemProps) => {
  return (
    <AccordionItem>
      <AccordionButton height={14} _expanded={{ bg: theme.colors.lightPink }}>
        <Box flex="1" textAlign="left" sx={{ color: item?.title?.toLowerCase() === 'special prices' ? 'red' : 'auto'}}>
          {item.title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel py={0}>
        {item.columns?.map(subitem => {
          return <SubitemListItem key={subitem?.id} subitem={subitem} />
        })}
      </AccordionPanel>
    </AccordionItem>
  )
}
