import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function MailIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 64 64" {...props}>
      <path
        d="M27 41.331H8.333A5.333 5.333 0 0 1 3 36V9.333A5.333 5.333 0 0 1 8.333 4h37.331A5.333 5.333 0 0 1 51 9.333v20"
        transform="translate(5 6.666)"
      />
      <path d="m3 6 24 16L51 6" transform="translate(5 9.999)" />
      <path d="M15 18h16" transform="translate(24.998 29.997)" />
      <path d="m18 15 8 8-8 8" transform="translate(29.997 24.998)" />
    </Icon>
  )
}
