import {
  AbundanceIcon,
  HealthIcon,
  HeartIcon,
  JoyIcon,
  ProtectionIcon,
} from '../Icons'

export const getIntentionIcon = (key: string) => {
  switch (key) {
    case 'joy':
      return <JoyIcon />
    case 'love':
      return <HeartIcon />
    case 'abundance':
      return <AbundanceIcon />
    case 'health':
      return <HealthIcon />
    case 'protection':
      return <ProtectionIcon />
    default:
      return null
  }
}
