import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { APP_ROUTES } from '_app/routes'

export const AskMoreQuestions = (props: BoxProps) => {
  const { t } = useTranslation('product')

  return (
    <Box bg="main" px={{ base: 4, lg: 14 }} py={{ base: 4, lg: 6 }} {...props}>
      <Text textStyle="body" mb={2}>
        {t('DO_YOU_HAVE_QUESTIONS')}
      </Text>
      <Flex>
        <Text textStyle="bodyLight" lineHeight={1.38}>
          {t('CS_IS_AT_YOUR_DISPOSAL')}{' '}
          <Text as="span" textDecoration="underline">
            <NextLink href={APP_ROUTES.CONTACT}>{t('HERE')}</NextLink>
          </Text>
          .
        </Text>
      </Flex>
    </Box>
  )
}
