import {ApolloProvider} from '@apollo/client'
import {ChakraProvider} from '@chakra-ui/react'
import {SessionProvider} from 'next-auth/react'
import {appWithTranslation} from 'next-i18next'
import {RecoilRoot} from 'recoil'
import theme from 'theme'
import {initializeApollo} from 'helpers/ApolloHelper/ApolloHelper'
import SuccessPopup from '_app/components/SuccessPopup'
import {FlagshipProvider} from '_app/providers/FlagshipProvider'
import {useRouter} from '_app/hooks/useRouter'
import {ErrorBoundary} from '_app/components/ErrorBoundary'
import '_app/recoil'
import {setupI18NFormErrors} from '../../i18n'
import '../assets/scss/ckContent.scss'
import {createStandaloneToast} from '@chakra-ui/toast'
import '../assets/scss/index.scss'
import type {AppProps} from 'next/app'
import MagicLoader from '../components/atoms/MagicLoader/MagicLoader'
import {useEffect} from 'react'
import LocalStorageHelper from '../helpers/LocalStorageHelper/LocalStorageHelper'
import TagManager from 'react-gtm-module'
import GTMService from '../services/GTMService/GTMService'
import SeoHelper from '../helpers/SeoHelper/SeoHelper'
import Head from 'next/head'
import CartHelper from '../helpers/CartHelper/CartHelper'
import CookiesHelper from '../helpers/CookiesHelper/CookiesHelper'
import {IS_CART_ACTIVE} from '../_app/graphql/queries/cart/IsCartActive'

const { ToastContainer } = createStandaloneToast()

setupI18NFormErrors()

const App = ({ Component, pageProps }: AppProps) => {
    const router = useRouter()
    const { locale } = router
    const apolloClient = initializeApollo(null, { locale })
    useEffect(() => {
        if (locale && !window.dataLayer) {
            GTMService.getGtmId(locale) && TagManager.initialize({ gtmId: GTMService.getGtmId(locale) })
        }
    }, [locale])
    useEffect(() => {
        if (locale) {
            LocalStorageHelper.set('locale', locale)
        }
    }, [locale])
    const handleCreateEmptyCart = async () => {
        const cartId = CookiesHelper.get('cartId') || ''
        let res: any = null
        if (cartId?.length > 0) {
            res = await apolloClient.query({
                query: IS_CART_ACTIVE,
                variables: {
                    masked_cart_id: cartId,
                },
            })
        }
        const isActive = cartId ? await res?.data?.isCartActive?.is_active : false
        await CartHelper.createEmptyCart(apolloClient, cartId, isActive)
    }
    useEffect(() => {
        if (apolloClient && router?.asPath) {
            handleCreateEmptyCart()
        }
    }, [apolloClient, router?.asPath])
    return (
        <>
            <Head>
                <title>{SeoHelper.getTitle() || ''}</title>
                <meta name="description" content={SeoHelper.getDescription()}/>
            </Head>
            <ApolloProvider client={apolloClient}>
            <ChakraProvider resetCSS theme={theme}>
                    <RecoilRoot>
                        <SessionProvider session={pageProps.session} refetchInterval={5 * 60} refetchOnWindowFocus={true}>
                            <FlagshipProvider>
                                <SuccessPopup />
                                <ErrorBoundary>
                                    <MagicLoader>
                                          <Component {...pageProps} />
                                    </MagicLoader>
                                </ErrorBoundary>
                            </FlagshipProvider>
                        </SessionProvider>
                    </RecoilRoot>
                </ChakraProvider>
            </ApolloProvider>
            <ToastContainer />
        </>
    )
}

export default appWithTranslation(App)