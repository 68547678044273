import { gql } from '@apollo/client'

export const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      id
      full_name_locale
      two_letter_abbreviation
      available_regions {
        code
        id
        name
      }
    }
  }
`
