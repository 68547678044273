import {Accordion, Box, IconButton, Text} from '@chakra-ui/react'
import {FC, useState} from 'react'
import {ComponentNavigationColumn, ComponentNavigationTopMenu,} from '_app/graphql/cms/generated/graphql'

import ArrowLeftIcon from '_app/components/Icons/ArrowLeftIcon'
import {NavigationListItem} from './NavigationListItem'
import {NavBarAccordionItem} from './NavBarAccordionItem'
import ElementsSizesEnum from '../../../../enums/ElementsSizesEnum/ElementsSizesEnum'

interface NavBarMobileDropDown {
  topMenu: ComponentNavigationTopMenu[];
}

const NavBarMobileDropDown: FC<NavBarMobileDropDown> = ({ topMenu }) => {
  const [activeTop, setActiveTop] = useState<ComponentNavigationTopMenu | null>(
    null
  )
  const [activeColumn, setActiveColumn] =
    useState<ComponentNavigationColumn | null>(null)

  const onBack = () => {
    if (activeColumn) {
      setActiveColumn(null)
    } else if (activeTop) {
      setActiveTop(null)
    }
  }

  const header = activeColumn?.title || activeTop?.title

  return (
    <Box
      display={{ base: 'block', lg: 'none' }}
      position="fixed"
      top={ElementsSizesEnum.NAVBAR_HEIGHT.base}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      zIndex={100}
      overflowY="scroll"
    >
      {header && (
        <Box
          bg="seashell"
          textAlign="center"
          p={4}
          cursor="pointer"
          borderTopWidth={1}
          borderColor="mercury"
          onClick={onBack}
        >
          <IconButton
            variant="icon"
            icon={<ArrowLeftIcon />}
            aria-label="Arrow left"
            position="absolute"
            height={6}
            width={6}
            left={4}
          />
          <Text>{header}</Text>
        </Box>
      )}
      <Accordion allowToggle width="100%">
        {topMenu?.map(item =>
          item.columns && item.columns.length > 0 ? (
            <NavBarAccordionItem key={item.id} item={item} />
          ) : (
            <NavigationListItem
              text={item.title}
              link={item.link}
              showBorderTop
              mainListItem
              isBold
            />
          )
        )}
      </Accordion>
    </Box>
  )
}

export default NavBarMobileDropDown
