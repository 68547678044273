import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function PinterestIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path transform="translate(11 15)" d="M0 12 5 0" />
      <path
        d="M11.932 16.332A3.642 3.642 0 0 0 15.33 19a4.984 4.984 0 0 0 5-5.331A6.664 6.664 0 1 0 7.4 15.932"
        transform="translate(2.33 2.328)"
      />
      <circle cx="12" cy="12" r="12" transform="translate(4 4)" />
    </Icon>
  )
}
