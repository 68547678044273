import { useState, useCallback } from 'react'

interface Options {
  lock: () => void;
  unlock: () => void;
}

const useBodyScroll = (): Options => {
  const [nativeScrollY, setNativeScrollY] = useState(0)

  const lock = useCallback(() => {
    setNativeScrollY(window.scrollY)
    document.body.style.position = 'fixed'
    document.body.style.top = `-${nativeScrollY}px`
    document.body.style.right = '0'
    document.body.style.left = '0'
  }, [nativeScrollY])

  const unlock = useCallback(() => {
    document.body.style.position = 'relative'
    document.body.style.top = ''
    document.body.style.right = ''
    document.body.style.left = ''
    window?.scroll(0, nativeScrollY)
  }, [nativeScrollY])

  return { lock, unlock }
}

export default useBodyScroll
