const ROUTES = {
  '/': {
    pl: '/',
    en: '/',
    fr: '/',
    de: '/',
  },
  leprive: {
    pl: '/leprive.html',
    en: '/leprive.html',
    fr: '/leprive.html',
    de: '/leprive.html',
  },
  about: {
    pl: '/o-nas.html',
    en: '/about.html',
    fr: '/le-about.html',
    de: '/about.html',
  },
  blog: {
    pl: '/blog.html',
    en: '/blog.html',
    fr: '/blog.html',
    de: '/blog.html',
    '[urlKey]': (path = ':path') => ({
      path,
      pl: `/blog/${path}.html`,
      en: `/blog/${path}.html`,
      fr: `/blog/${path}.html`,
      de: `/blog/${path}.html`,
    }),
  },
  cart: {
    pl: '/koszyk.html',
    en: '/cart.html',
    fr: '/le-cart.html',
    de: '/cart.html',
  },
  checkout: {
    pl: '/checkout.html',
    en: '/checkout.html',
    fr: '/le-checkout.html',
    de: '/checkout.html',
    success: {
      en: '/checkout/success.html',
      pl: '/checkout/success.html',
      fr: '/le-checkout/success.html',
      de: '/checkout/success.html',
    },
  },
  configurator: {
    pl: '/konfigurator.html',
    en: '/configurator.html',
    fr: '/le-configurator.html',
    de: '/configurator.html',
  },
  contact: {
    pl: '/kontakt.html',
    en: '/contact.html',
    fr: '/contact.html',
    de: '/contact.html',
  },
  favorites: {
    pl: '/ulubione.html',
    en: '/favorites.html',
    fr: '/le-favorites.html',
    de: '/favorites.html',
  },
  login: {
    pl: '/logowanie.html',
    en: '/login.html',
    fr: '/le-login.html',
    de: '/login.html',
  },
  signup: {
    pl: '/rejestracja.html',
    en: '/signup.html',
    fr: '/le-signup.html',
    de: '/signup.html',
  },
  information: {
    '[urlKey]': (path = ':path') => ({
      path,
      pl: `/informacje/${path}.html`,
      en: `/information/${path}.html`,
      fr: `/information/${path}.html`,
      de: `/information/${path}.html`,
    }),
  },
  inspirations: {
    pl: '/inspiracje.html',
    en: '/inspirations.html',
    fr: '/le-inspirations.html',
    de: '/inspirations.html',
    '[urlKey]': (path = ':path') => ({
      path,
      pl: `/inspiracje/${path}.html`,
      en: `/inspirations/${path}.html`,
      fr: `/le-inspirations/${path}.html`,
      de: `/inspirations/${path}.html`,
    }),
  },
  password: {
    new: {
      pl: '/haslo/nowe.html',
      en: '/password/new.html',
      fr: '/le-password/new.html',
      de: '/password/new.html',
    },
  reset: {
      pl: '/haslo/reset.html',
      en: '/password/reset.html',
      fr: '/le-password/reset.html',
      de: '/password/reset.html',
    },
  },
  400: {
    pl: '/400.html',
    en: '/400.html',
    fr: '/400.html',
    de: '/400.html',
  },
  500: {
    pl: '/500.html',
    en: '/500.html',
    fr: '/500.html',
    de: '/500.html',
  },
  profile: {
    '[tab]': (path = ':path') => ({
      path,
      pl: `/profil/${path}.html`,
      en: `/profile/${path}.html`,
      fr: `/le-profile/${path}.html`,
      de: `/profile/${path}.html`,
    }),
  },
  showrooms: {
    pl: '/butiki.html',
    en: '/showrooms.html',
    fr: '/le-showrooms.html',
    de: '/showrooms.html',
    '[id]': (path = ':path') => ({
      path,
      pl: `/butiki/${path}.html`,
      en: `/showrooms/${path}.html`,
      fr: `/le-showrooms/${path}.html`,
      de: `/showrooms/${path}.html`,
    }),
  },
  shop: {
    '[...slug]': (path = ':path*') => ({
      path,
      pl: `/sklep/${path}.html`,
      en: `/shop/${path}.html`,
      fr: `/shop/${path}.html`,
      de: `/shop/${path}.html`,
    }),
  },
  newsletter: {
    '[showroom]': (path = ':path') => ({
      path,
      pl: `/formularz-newsletter-${path}.html`,
    }),
  },
  '[url_key]': (path = ':path') => ({
    path,
    pl: `/${path}.html`,
    en: `/${path}.html`,
    fr: `/${path}.html`,
    de: `/${path}.html`,
  }),
}

// list of routes meant for displaying translated urls in the app
// ROUTES should rely on wildcards whenever possible since they're used in rewrites
const TRANSLATED_ROUTES = {
  ...ROUTES,
  information: {
    faq: {
      pl: '/informacje/faq.html',
      en: '/infromation/faq.html',
      fr: '/infromation/faq.html',
      de: '/infromation/faq.html',
    },
    'zwroty-i-reklamacje': {
      pl: '/informacje/zwroty-i-reklamacje.html',
      en: '/information/terms-and-conditions.html',
      fr: '/information/retours-et-reclamations.html',
      de: '/information/widerrufsrecht-fuer-verbraucher.html',
    },
    'formy-platnosci-klarna': {
      pl: '/informacje/formy-platnosci-klarna.html',
      en: '/information/formy-platnosci-klarna.html',
      fr: '/information/formy-platnosci-klarna.html',
      de: '/information/formy-platnosci-klarna.html',
    },
    'jak-pakujemy': {
      pl: '/informacje/jak-pakujemy.html',
      en: '/information/how-we-pack.html',
      fr: '/information/comment-nous-emballons.html',
      de: '/information/verpackung.html',
    },
    'regulamin-sklepu-internetowego': {
      pl: '/informacje/regulamin-sklepu-internetowego.html',
      en: '/information/terms-and-conditions.html',
      fr: '/information/conditions-de-vente.html',
      de: '/information/agb.html',
    },
    'czas-i-koszty-dostawy': {
      pl: '/informacje/czas-i-koszty-dostawy.html',
      en: '/information/shipping.html',
      fr: '/information/livraison.html',
      de: '/information/lieferung.html',
    },
    'formy-platnosci': {
      pl: '/informacje/formy-platnosci.html',
      en: '/information/payment-methods.html',
      fr: '/information/methodes-de-payement.html',
      de: '/information/zahlungsmethoden.html',
    },
    'polityka-prywatnosci': {
      pl: '/informacje/polityka-prywatnosci.html',
      en: '/information/privacy-policy.html',
      fr: '/information/politique-de-confidentialite.html',
      de: '/information/datenschutzerklaerung.html',
    },
    'regulamin-karty-podarunkowej': {
      pl: '/informacje/regulamin-karty-podarunkowej.html',
      en: '/information/gift-card-regulations.html',
      fr: '/information/gift-card-regulations.html',
      de: '/information/gift-card-regulations.html',
    },
    'regulamin-newslettera': {
      pl: '/informacje/regulamin-newslettera.html',
      en: '/information/newsletter-regulations.html',
      fr: '/information/reglement-de-la-newsletter.html',
      de: '/information/newsletter-bestimmungen.html',
    },
  },
  shop: {
    recommended: {
      bestsellers: {
        pl: '/sklep/polecane/bestsellery.html',
        en: '/shop/recommended/bestsellers.html',
        fr: '/shop/recommande/bestsellery.html',
        de: '/shop/empfehlung/bestseller.html',
      },
    },
    '[...slug]': (path = ':path*') => ({
      path,
      pl: `/sklep/${path}.html`,
      en: `/shop/${path}.html`,
      fr: `/shop/${path}.html`,
      de: `/shop/${path}.html`,
    }),
  },
}

module.exports = { ROUTES, TRANSLATED_ROUTES }
