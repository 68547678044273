import {Country} from '_app/types/api'

export const handleAvailableCountries = ({ data }: any) => {
  return data?.countries
        ?.map((c: Country) => ({value: c?.id, label: c?.full_name_locale,}))
        ?.sort((countryA: any, countryB: any) => (countryA?.label || '').localeCompare(countryB?.label || '')) || []
}

export type Suggestion = {
  suggestion: google.maps.places.AutocompletePrediction;
  label: string;
};

export const handleAvailableSuggestions = ({
  data,
  destinationCode,
  locale,
  suggestions,
}): any => {
  const availableCountries: any[] = handleAvailableCountries({
    data,
    showAll: locale !== 'de',
    shippingCode: destinationCode,
  })
  const destinationCountry = availableCountries.find(country => country.value === destinationCode)
  if (!destinationCountry) {
    console.warn('Destination country not found:', destinationCode)
  }
  const normalize = (str: string) => str.trim().toLowerCase()
  const suggestedCountries = suggestions?.map(suggestion => ({
    suggestion,
    label: suggestion.terms[suggestion.terms.length - 1].value,
  }))
  return suggestedCountries?.filter((suggestedCountry: { label: string; }): any => normalize(suggestedCountry.label) === normalize(destinationCountry?.label || '')) ?? []
}
