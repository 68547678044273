import React, { useEffect, useState } from 'react'
import { AspectRatio, useBreakpointValue } from '@chakra-ui/react'
import Banner from '_app/components/Banner/BannerBase'
import ImageCms from '_app/components/Content/ImageCms'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const ImageBanner = ({ img, img_mobile, alt, imgRatio, bg = 'transparent', priority = false, ...props }: any) => {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    const image = BreakpointsHelper.isMobile() ? img_mobile || img : img
    const ratio = useBreakpointValue({ md: imgRatio || 1 })

    const handleHeight = () => {
        if (typeof window !== 'undefined' && document.body.clientWidth <= 320) {
            return '450px'
        } else if (BreakpointsHelper.checkSizeWindow('sm', 'down')) {
            return '550px'
        } else if (BreakpointsHelper.checkSizeWindow('md', 'down')) {
            return '590px'
        }
        return 'auto'
    }

    return (
        <Banner {...{ bg, ...props }}>
            {image?.data?.attributes && (
                <AspectRatio sx={{ height: handleHeight(), filter: 'brightness(0.8)' }} ratio={ratio} w="full">
                    <ImageCms
                        contain={'cover'}
                        src={''}
                        attributes={image.data?.attributes}
                        priority={priority}
                        layout={'fill'}
                        alt={alt || ''}
                    />
                </AspectRatio>
            )}
        </Banner>
    )
}

export default ImageBanner
