const defaultRadio = {
  outline: 'none',
  borderWidth: '1px',
  borderColor: 'input',
  _checked: {
    padding: '2px',
    position: 'relative',
    _after: {
      content: '" "',
      bg: 'black',
      position: 'absolute',
      height: '8px',
      width: '8px',
      borderRadius: '50%',
    },
  },
  _disabled: { opacity: 0.5, color: 'input' },
}

const defaultText = {
  ml: 4,
  w: 'full',
}

export const defaultStyles = {
  radioStyles: defaultRadio,
  textStyles: defaultText,
  boxStyles: {},
}

const buttonRadio = {
  opacity: 0.5,
  sx: {
    display: 'none',
  },
}

const buttonText = {
  py: 2,
  px: 5,
  fontWeight: '300',
}

export const buttonStyles = {
  radioStyles: buttonRadio,
  textStyles: buttonText,
  boxStyles: {
    sx: {
      '.chakra-radio__label': {
        margin: 0,
        overflow: 'hidden',
        border: '1px solid #53381817',
      },
      'input:checked ~ .chakra-radio__label': {
        borderColor: '#242424',
        bg: '#FFF8F4',
      },
      '&:first-of-type .chakra-radio__label': {
        borderRadius: '5px 0 0 5px',
      },
      '&:last-of-type .chakra-radio__label': {
        borderRadius: '0 5px 5px 0',
      },
    },
  },
}
