import { Box, AspectRatio, Text } from '@chakra-ui/react'
import { ImgProps } from '_app/types/content'
import ImageCms from './ImageCms'

export const StorySlide = ({
  description,
  year,
  img,
  isActive,
}: {
  description?: string;
  year: number;
  img: ImgProps;
  isActive?: boolean;
}) => {
  return (
    <Box
      width="full"
      maxW={{
        base: 'full',
        lg: '26.875rem',
      }}
      position="relative"
      overflow="hidden"
      _after={
        isActive !== undefined && !isActive
          ? {
              content: '" "',
              bg: '#fff',
              opacity: 0.4,
              position: 'absolute',
              inset: 0,
              zIndex: '9',
            }
          : {}
      }
    >
      <AspectRatio ratio={330 / 219}>
        <ImageCms
          src={''}
          attributes={img?.data?.attributes}
          alt={`${year}` || ''}
        />
      </AspectRatio>
      <Text fontSize={{ base: '3.125rem', lg: '6.25rem' }}>
        {year}
      </Text>
      <Text textStyle="bodyLight">{description}</Text>
    </Box>
  )
}
