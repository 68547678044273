import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ArrowCurwLeft(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        data-name="Path 527"
        d="m9 11-4 4 4 4m-4-4h11a4 4 0 0 0 0-8h-1"
        stroke="#000"
        fill="none"
      />
    </Icon>
  )
}
