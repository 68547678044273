import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function TrashIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path transform="translate(4.5 6.5)" d="M0 0h16" />
      <path transform="translate(10.5 10.5)" d="M0 0v6" />
      <path transform="translate(14.5 10.5)" d="M0 0v6" />
      <path
        d="m5.5 7.5 1 12c0 1.1-.1 2 1 2h9a2 2 0 0 0 2-2l1-12"
        transform="translate(0 -1)"
      />
      <path
        d="M9.5 7.5v-3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"
        transform="translate(0 -1)"
      />
    </Icon>
  )
}
