import { Text, TextProps, Flex } from '@chakra-ui/react'
import { useRouter } from '_app/hooks/useRouter'
import { Money } from '_app/graphql/generated/graphql'
import getPrice from '_app/utils/localized/getPrice'

interface PriceTextProps extends TextProps {
  amount?: Money;
  floating?: boolean;
  minimumFractionDigits?: number;
  productsDiscountPriceValue?: number | null;
}

const PriceText = ({
  amount,
  floating,
  minimumFractionDigits = 0,
  productsDiscountPriceValue,
  ...rest
}: PriceTextProps) => {
  const { locale } = useRouter()

  return (
    <Flex>
      {productsDiscountPriceValue && (
        <Text as="span" me={3} textDecoration="line-through" color="#bababa">
          {getPrice(
            locale,
            amount?.value,
            amount?.currency,
            minimumFractionDigits,
            floating ? 2 : 0
          )}
        </Text>
      )}
      <Text {...rest}>
        {getPrice(
          locale,
          productsDiscountPriceValue ?? amount?.value,
          amount?.currency,
          minimumFractionDigits,
          floating ? 2 : 0
        )}
      </Text>
    </Flex>
  )
}

export default PriceText
