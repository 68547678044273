import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ChevronLeftIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 40 40" {...props}>
      <path data-name="Path 10" d="m9 6 6 6-6 6" transform="translate(8 8)" />
    </Icon>
  )
}
