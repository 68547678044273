export const Link = {
  baseStyle: {
    fontSize: 'sm',
    lineHeight: 1.21,
    fontWeight: 600,
    position: 'relative',
    paddingBottom: '4px',
    _after: {
      bottom: 0,
      content: '""',
      display: 'block',
      height: '2px',
      left: 0,
      position: 'absolute',
      background: 'currentColor',
      transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      width: '100%',
    },
    _hover: {
      textDecoration: 'none',
      _after: {
        width: '30%',
        left: '35%',
      },
    },
  },
}
