import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function IndicatorIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 64 64" {...props}>
      <path
        d="M2842.935 1086.286a122.86 122.86 0 0 0-1.855-.727 31.549 31.549 0 0 0-4.083-1.275 12.917 12.917 0 0 0-9.189 1.02c-.279.151-.931-1.543-.982-1.677-.082-.213-.731-1.709-.4-1.9a3.67 3.67 0 0 0 1.094-1.353.3.3 0 0 1 .045-.177c3.09-4.109 4.79-8.981 7.2-13.476.194-.362.788.989.842 1.111a10.586 10.586 0 0 1 .5 1.39c.055.2.23.671.142.89-.842 2.11-1.654 4.241-2.57 6.316l.459-.324a65.051 65.051 0 0 0 6.371-5.134 43.24 43.24 0 0 0 10.135-13.5 48.759 48.759 0 0 0 2.05-5.392 23.545 23.545 0 0 0 1.376-9.872c.039.318.493.952.457 1.415a31.75 31.75 0 0 1-.11 1.113c.586 5.6-1.1 11.25-3.585 16.217a51.07 51.07 0 0 1-10.547 13.862 69.131 69.131 0 0 1-5.1 4.4l-1.422 1.114c.7.045 1.4.128 2.1.24a33.508 33.508 0 0 1 4.474 1.1c.635.194 1.266.4 1.9.609a4.6 4.6 0 0 1 .929.355 3.077 3.077 0 0 1 .481.478 4.668 4.668 0 0 1 .88 1.643c.034.087.64 1.915.4 1.9a6 6 0 0 1-1.992-.366z"
        transform="translate(-2826.333 -1042.204)"
      />
    </Icon>
  )
}
