import {
  useMutation as useApolloMutation,
} from '@apollo/client'

import toast from '_app/utils/toast'

export const useMutation = ({
  mutation,
  onCompleted,
  onError,
}: any): any => {
  const [mutate, { data, error, loading }] = useApolloMutation(mutation, {
    onCompleted,
    onError: error => {
      if (onError) {
        onError(error)
      }
      toast({
        description: error.message,
        status: 'error',
      })
    },
  })

  return { mutate, data, error, loading }
}
