import {Box, HStack} from '@chakra-ui/react'
import React from 'react'
import {sortBy, uniq} from 'lodash-es'
import {useSetRecoilState} from 'recoil'
import {BundleProduct, ProductInterface,} from '_app/graphql/generated/graphql'
import useAttributesMetadata from '_app/hooks/useAttributesMetadata'
import {getDimension4, GTM_EVENTS,} from '_app/utils/dataLayer'
import {ChakraNextImage} from '_app/components/Content/ImageChakra'
import {WithAnalyticsId} from '_app/types/dataLayer'
import {gtmItemListNamesAtom} from '_app/state/atoms'
import SimpleItem from './SimpleItem'
import ProductHelper from '../../helpers/ProductHelper/ProductHelper'

type Item = WithAnalyticsId<BundleProduct | ProductInterface>;

const Item = ({
                  item,
                  category,
                  isFavoritesList,
                  position,
                  boxProps,
                  showCarousel,
                  hideCart,
              }: any) => {
    const {name, price_range, sku, material_swatch, upsell_products} = item
    const setGtmItemListNames = useSetRecoilState(gtmItemListNamesAtom)
    const {minimum_price: price} = price_range
    const materialSwatches = uniq([
        ...(material_swatch ? [material_swatch] : []),
        ...(upsell_products
            ? upsell_products.map(upsell => upsell?.material_swatch)
            : []),
    ])
    const {metaData} = useAttributesMetadata({})
    const decodedOptions = sortBy(
        metaData?.material_swatch?.filter(swatch =>
            materialSwatches.includes(parseInt(swatch.value ?? ''))
        ) ?? [],
        'label'
    )
    const handleItemClick = () => {
        if (position && category) {
            if (sku && category.name) {
                setGtmItemListNames(gtmItemListNames => {
                    return {
                        ...gtmItemListNames,
                        [sku]: category.name as string,
                    }
                })
            }
            GTM_EVENTS.productClick({
                ecommerce: {
                    click: {
                        products: [
                            {
                                id: sku as string,
                                name: name as string,
                                price: price?.final_price?.value as number,
                                brand: 'Lilou',
                                category1: category?.name,
                                category2: category?.breadcrumbs?.[0]?.category_name,
                                category3: category?.level,
                                dimension4: getDimension4(item?.categories),
                                dimension6: ProductHelper.getNameAvailable(ProductHelper.getAvailableAmount(item?.items, undefined, item?.salable_qty, item)),
                            },
                        ],
                    },
                },
            })
        }
    }
    return (
        <SimpleItem
            onClick={handleItemClick}
            isFavoritesList={isFavoritesList}
            boxProps={boxProps}
            item={item}
            hideCart={Boolean(hideCart)}
            itemOptions={
                <Box flex={1} minH="1.125rem">
                    {decodedOptions.length > 0 && (
                        <HStack>
                            {decodedOptions.map(
                                ({image, value}, index) =>
                                    image && (
                                        <Box key={`${name}.${value}`}>
                                            <ChakraNextImage
                                                src={image}
                                                alt={`${value}-${index}`}
                                                boxSize="1.125rem"
                                            />
                                        </Box>
                                    )
                            )}
                        </HStack>
                    )}
                </Box>
            }
            showCarousel={showCarousel}
        />
    )
}

export default Item
