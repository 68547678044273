import {Box, Button, Center, HStack, Text} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'

import {useGetLayoutQuery} from '_app/graphql/cms/generated/graphql'
import CmsLink from '_app/components/Link/CmsLink'
import {getSocialIcon} from '_app/components/Footer/helpers'
import {useRouter} from '_app/hooks/useRouter'
import Link from '_app/components/Link/Link'
import RequestEnum from '../../../enums/RequestEnum/RequestEnum'

const ContactSuccess = () => {
  const { t } = useTranslation('contact')
  const router = useRouter()

  const { data: dataFooter } = useGetLayoutQuery({
    context: {
      clientName: RequestEnum.CMS_CONTEXT,
    },
    variables: {
      locale: router.locale,
    },
  })

  return (
    <Box width="100%" maxWidth={{ base: '100%', lg: '455px' }}>
      <Text textStyle="sectionHeader">{t('THANK_YOU_FOR_MESSAGE')}</Text>
      <Text
        fontSize={16}
        fontWeight={300}
        lineHeight={1.38}
        my={{ base: 4, lg: 6 }}
      >
        {t('WE_WILL_REACH_OUT_TO_YOU_ASAP')}
      </Text>
      <Link href="/" passHref>
        <Button minWidth={{ base: '50%', lg: '200px' }} mt={2} width="full">
          {t('CREATE_YOUR_TALISMAN')}
        </Button>
      </Link>

      {dataFooter?.footer?.data?.attributes?.social_media &&
        !!dataFooter?.footer?.data?.attributes?.social_media.length && (
          <Center py={6}>
            <HStack spacing="16px">
              {dataFooter?.footer?.data?.attributes?.social_media.map(
                (social, idx) =>
                  social?.text && (
                    <Box cursor="pointer" key={`socials[${idx}]`}>
                      <CmsLink link={social}>
                        {getSocialIcon(social.text, 24)}
                      </CmsLink>
                    </Box>
                  )
              )}
            </HStack>
          </Center>
        )}
    </Box>
  )
}

export default ContactSuccess
