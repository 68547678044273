export default {
    getEdroneId: (locale?: string): string => {
        const ids = {
            PL: process.env.NEXT_PUBLIC_EDRONE_PL,
            DE: process.env.NEXT_PUBLIC_EDRONE_DE,
            FR: process.env.NEXT_PUBLIC_EDRONE_FR,
            EN: process.env.NEXT_PUBLIC_EDRONE_EN,
        }
        return locale ? ids[locale.toUpperCase()] : ids?.PL || ''
    }
}