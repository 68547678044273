export default {
    set: (key: string, data: any) => {
        if (typeof window !== 'undefined' && window.localStorage) {
            data && localStorage.setItem(key, JSON.stringify(data))
        }
    },
    get: (key: string) => {
        if (typeof window !== 'undefined' && window.localStorage) {
            const item = localStorage.getItem(key)
            return item ? JSON?.parse(item) : null
        }
        return null
    },
    remove: (key: string) => {
        if (typeof window !== 'undefined' && window.localStorage) {
            localStorage.removeItem(key)
        }
    },
    clear: () => {
        if (typeof window !== 'undefined' && window.localStorage) {
            localStorage.clear()
        }
    }
}