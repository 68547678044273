import EnvironmentEnum from '../../enums/EnvironmentEnum/EnvironmentEnum'

export default {
    getEnvironment: () => {
        const appEnv = process.env.NEXT_PUBLIC_APP_ENVIRONMENT
        switch (appEnv) {
            case EnvironmentEnum.PRODUCTION?.toLowerCase():
                return EnvironmentEnum.PRODUCTION
            default:
                return EnvironmentEnum.PREPROD
        }
    }
}