import React from 'react'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import CmsLink from '_app/components/Link/CmsLink'
import { Maybe, ComponentPartsLink } from '_app/graphql/cms/generated/graphql'
import ImageCms from '_app/components/Content/ImageCms'

export interface GridContentProps extends BoxProps {
  img?: any;
  alt?: string;
  title?: string;
  sizes?: string;
  link?: Maybe<ComponentPartsLink>;
  analyticsId?: string;
}

const GridContent: React.FC<GridContentProps> = ({
  img,
  title,
  link,
  sizes,
  analyticsId,
  ...rest
}) => {
  return (
    <CmsLink link={link} id={analyticsId}>
      <Box display="flex" aria-label={title} position="relative" {...rest}>
        {img ? (
          <ImageCms
            src={''}
            attributes={img?.data?.attributes}
            sizes={sizes}
            paddingRight={0}
          />
        ) : null}
        <Box position="absolute" left={0} right={0} bottom={0}>
          <Text
            fontSize={{
              base: '16px',
              lg: '24px',
            }}
            color="white"
            fontWeight={600}
            p={4}
            background={{ lg: 'linear-gradient(0deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%)', md: 'none' }}
          >
            {title}
          </Text>
        </Box>
      </Box>
    </CmsLink>
  )
}

export default GridContent
