import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function InstagramIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <rect width="22" height="22" rx="4" transform="translate(5 5)" />
      <circle cx="5" cy="5" r="5" transform="translate(11 11)" />
      <path transform="translate(22 10)" d="M0 0h0" strokeWidth={2} />
    </Icon>
  )
}
