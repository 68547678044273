import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function ProtectionIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path
        id="Path_13479"
        data-name="Path 13479"
        d="M15,3A16,16,0,0,0,26.329,7,16,16,0,0,1,15,27,16,16,0,0,1,3.662,7,16,16,0,0,0,15,3"
        transform="translate(1.004 1)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
      />
    </Icon>
  )
}
