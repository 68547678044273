import { Box } from '@chakra-ui/react'

interface TabProps {
  groupKey: string;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab = ({ label, isActive, onClick }: TabProps) => (
  <Box
    role="button"
    py="1.25rem"
    fontWeight="normal"
    lineHeight={1}
    fontSize="1rem"
    borderColor={isActive ? '#fcca9d' : 'transparent'}
    _hover={{
      borderColor: isActive ? '#fcca9d' : 'rgba(252, 202, 157, 0.4)',
    }}
    borderBottomWidth={4}
    cursor="pointer"
    onClick={onClick}
    transition="border 0.2s"
  >
    {label}
  </Box>
)

export default Tab
