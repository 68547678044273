import React, { FC, ReactNode } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalContentProps,
} from '@chakra-ui/react'

interface Props extends ModalContentProps {
  title?: string;
  isOpen: boolean;
  children: ReactNode;
  onClose?: () => void;
  isCloseButton?: boolean;
  isInfoSize?: boolean;
}

const ModalNecklace: FC<Props> = ({
  isOpen,
  title,
  children,
  onClose,
  isCloseButton,
  isInfoSize,
  ...props
}) => {
  const infoSizeProps = {
    w: { base: '100%' },
    maxW: { base: '100%', md: '1170px' },
    maxH: { base: '100%', md: 'fit-content' },
    height: 'fit-content',
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => onClose?.()} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius="0"
          margin="0"
          overflow={{ base: 'auto', md: 'hidden' }}
          {...props}
          {...infoSizeProps}
        >
          <ModalCloseButton />
          <ModalBody p={0}>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalNecklace
