import {Box, Center, Container, Flex, Grid, GridItem, IconButton, Text,} from '@chakra-ui/react'
import React, {FC, ReactElement, useEffect} from 'react'
import {useTranslation} from 'next-i18next'
import {useRouter} from '_app/hooks/useRouter'
import {ArrowLeftIcon} from '_app/components/Icons'
import useVisitorEvent from '_app/hooks/useVisitorEvent'
import NavBarLogo from '../NavBarLogo'
import useSimpleHeaderTopBarPosition from './useSimpleHeaderTopBarPosition'
import {APP_ROUTES} from '../../routes'
import ElementsSizesEnum from '../../../enums/ElementsSizesEnum/ElementsSizesEnum'
import {NextSeo} from 'next-seo'

const Header = ({ hasTopBar }: { hasTopBar: boolean }) => {
  const router = useRouter()
  const { locale } = useRouter()
  const { t } = useTranslation()
  const positions = useSimpleHeaderTopBarPosition(hasTopBar)
  const handleBack = () => {
    const pathName = router?.asPath?.replace('.html','').slice(1)
    if (pathName === 'koszyk' || pathName === 'le-cart' || pathName === 'cart') {
      router.push(`/${APP_ROUTES.INDEX}`)
    } else {
      if (pathName === 'checkout' || pathName === 'le-checkout') {
        router.push(`/${APP_ROUTES.CART}`)
      } else {
        router.back()
      }
    }
  }
  return (
    <Center
      as="header"
      position="fixed"
      left={0}
      right={0}
      top={positions}
      height={ElementsSizesEnum.HEADER_HEIGHT}
      bg="white"
      py={hasTopBar ? 0 : 4}
      borderBottomWidth={1}
      borderBottomColor="mercury"
      transition="all 0.2s"
      zIndex={10}
    >
      <Grid
        as={Container}
        width="full"
        templateColumns="repeat(3, 1fr)"
        gap={6}
      >
        <GridItem display="flex" w="100%" alignItems="center">
          <Flex onClick={() => handleBack()} cursor="pointer">
            <IconButton
              aria-label="Back"
              variant="icon"
              icon={<ArrowLeftIcon />}
            />
            <Text display={['none', null, 'inline']} fontWeight="300">
              {t('BACK')}
            </Text>
          </Flex>
        </GridItem>
        <GridItem w="100%">
          <NavBarLogo mb={0} />
        </GridItem>
        <GridItem w="100%" />
      </Grid>
    </Center>
  )
}

type Props = {
  topBar?: ReactElement;
  children: ReactElement;
  seo?: any;
};

const PageSimpleHeader: FC<Props> = ({ topBar, children, seo }) => {
  useVisitorEvent()
  return (
      <>
        {
            seo && (
                <NextSeo
                    {...{
                      ...seo,
                    }}
                />
            )
        }
        <Box>
          {topBar}
          <Header hasTopBar={!!topBar} />
          <Box
              as="main"
              mt={ElementsSizesEnum.HEADER_HEIGHT}
              minH={`calc(100vh - ${ElementsSizesEnum.HEADER_HEIGHT.lg})`}
          >
            {children}
          </Box>
        </Box>
      </>
  )
}

export default PageSimpleHeader
