import {Box, Center, Container, Divider, Flex, Spinner, Text,} from '@chakra-ui/react'
import {useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {useTranslation} from 'next-i18next'
import {BundleProduct, ProductInterface} from '_app/graphql/generated/graphql'
import Item from 'modules/Listing/Item'
import {useGetIntentionsSectionQuery} from '_app/graphql/cms/generated/graphql'
import {useRouter} from '_app/hooks/useRouter'
import theme from 'theme'
import {intentions} from './constants'
import {getIntentionIcon} from './helpers'
import {StyledIntentionIconBox, StyledIntentionsIconsWrapper, StyledWrapper,} from './IntentionsSection.styled'
import RequestEnum from '../../../enums/RequestEnum/RequestEnum'
import {useGetProductsListQuery} from '../../graphql/queries/category/GetProductsList'

const IntentionsSection = () => {
  const router = useRouter()
  const { t } = useTranslation('inspirations')

  const { data } = useGetIntentionsSectionQuery({
    context: {
      clientName: RequestEnum.CMS_CONTEXT,
    },
    variables: {
      locale: router.locale,
    },
  })

  const [activeTab, setActiveTab] = useState(
    intentions.items?.find(({ key }) => getIntentionIcon(key) !== null)?.key,
  )

  const products = (data?.intention?.data?.attributes as any)?.[
    `items_${activeTab}`
    ]?.items?.map((item: { url_key: string }) => item?.url_key)

  const { loading, data: intentionProducts } = useGetProductsListQuery({
    variables: {
      pageSize: 16,
      currentPage: 1,
      filter: {
        url_key: {
          in: products ?? [],
        },
      },
    },
  })

  const items = (intentionProducts?.products?.items as BundleProduct[]) || []
  const activeItem = intentions.items.find(({ key }) => key === activeTab)

  return (
    <Container px={4}>
      <StyledWrapper as="section">
        <Center>
          <Box textAlign="center" maxW="34.75rem">
            <Text mb={6} textStyle="sectionHeader">
              {t(intentions.title)}
            </Text>
            <Text textStyle="bodyLight">{t(intentions.description)}</Text>
          </Box>
        </Center>
        <Center justifyContent="space-between" mb="6.25rem">
          <Divider
            borderColor={theme.colors.darkGray}
            display={{ base: 'none', md: 'block' }}
          />
          <StyledIntentionsIconsWrapper>
            {
              intentions?.items?.map(({ key, name }: { key: string; name: string }) => {
                  const intentionKey = getIntentionIcon(key)
                  intentionKey && (
                    <StyledIntentionIconBox
                      key={key}
                      activeTab={activeTab}
                      onClick={() => setActiveTab(key)}
                    >
                      {intentionKey}
                      {activeTab === key && (
                        <Text position="absolute" bottom={-10} left={0} right={0}>
                          {t(name)}
                        </Text>
                      )}
                    </StyledIntentionIconBox>
                  )
                },
              )
            }
          </StyledIntentionsIconsWrapper>
          <Divider
            display={{ base: 'none', md: 'block' }}
            borderColor={theme.colors.darkGray}
          />
        </Center>

        <Box
          my={4}
          display={{
            base: 'block',
            lg: 'none',
          }}
        >
          <Text textStyle="sectionHeader" mb={4}>
            {activeItem && t(activeItem.title)}
          </Text>
          <Container px={0}>
            {loading ? (
              <Center height="80vw">
                <Spinner
                  thickness="0.25rem"
                  speed="1s"
                  emptyColor="main"
                  color="#000"
                  size="xl"
                />
              </Center>
            ) : (
              <Swiper slidesPerView={1.5} spaceBetween={30}>
                {items.map((item, index) => (
                  <SwiperSlide key={`item-slide-[${index}]`}>
                    <Item item={item as ProductInterface} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Container>
        </Box>

        <Flex
          width="full"
          display={{ base: 'none', lg: 'flex' }}
          justifyContent="space-between"
        >
          <Box maxW="25%">
            <Text
              fontSize="1.5rem"
              lineHeight={1.21}
              fontWeight={600}
              mb={6}
            >
              {activeItem && t(activeItem.title)}
            </Text>
            <Text textStyle="bodyLight">
              {activeItem && t(activeItem.description)}
            </Text>
          </Box>
          {loading ? (
            <>
              <Center height="12.5rem" width="full">
                <Spinner
                  thickness="0.25rem"
                  speed="1s"
                  emptyColor="main"
                  color="#000"
                  size="xl"
                />
              </Center>
            </>
          ) : (
            <Flex width="75%" justifyContent="flex-end" mx="-1.875rem">
              {items?.map((item, index) => (
                <Box
                  key={`intention-product-${item?.name}-${index}`}
                  maxW="33.3%"
                  flex="1 1 33.3%"
                  px="1.875rem"
                >
                  <Item item={item} />
                </Box>
              ))}
            </Flex>
          )}
        </Flex>
      </StyledWrapper>
    </Container>
  )
}

export default IntentionsSection
