import { useEffect } from 'react'
import loadGoogleMapsApi from 'load-google-maps-api'
import { useRouter } from './useRouter'

const useLoadGooglePlacesApi = (callback?: () => void) => {
  const { locale } = useRouter()

  const loadApi = async () => {
    await loadGoogleMapsApi({
      key: process.env.NEXT_PUBLIC_GOOGLE_GEOCODE_APIKEY,
      libraries: ['places'],
      language: locale,
    })

    if (callback) {
      callback()
    }
  }

  useEffect(() => {
    loadApi()
  }, [])
}

export default useLoadGooglePlacesApi
