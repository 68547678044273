import React, {useEffect, useMemo} from 'react'
import {Box, BoxProps} from '@chakra-ui/react'
import {SwiperProps} from 'swiper/react'

import {useRouter} from 'next/router'
import ProductsCarousel from 'modules/Listing/ProductsCarousel'
import {
    CurrencyEnum,
} from '_app/graphql/generated/graphql'
import {BundleProduct} from '_app/types/api'
import {
    getDimension4,
    GTM_EVENTS,
} from '_app/utils/dataLayer'
import ProductHelper from '../../../helpers/ProductHelper/ProductHelper'
import {useGetProductsListQuery} from '../../graphql/queries/category/GetProductsList'

interface ContentProductsProps {
    title?: string;
    urlKeys?: string[];
    rows?: number;
    swiperProps?: SwiperProps;
    boxProps?: BoxProps;
    productsPriceCurrency?: CurrencyEnum;
}

const ContentProducts = ({
                             title,
                             urlKeys = [],
                             productsPriceCurrency = CurrencyEnum.Pln,
                             rows = 1,
                             swiperProps,
                             boxProps,
                         }: ContentProductsProps) => {
    const {asPath} = useRouter()
    const {loading, data: productsDataTemp }: any = useGetProductsListQuery({
        variables: {
            pageSize: 16,
            currentPage: 1,
            filter: {url_key: {in: urlKeys}},
        },
    })
    let productsData = productsDataTemp

    const items = useMemo(() => {
        if (productsData?.products?.items) {
            const filters = productsData?.products?.filters
            const tempProductData: any = {
                products: {
                    filters,
                    items: [],
                }
            }
            productsData?.products?.items?.forEach((el: any) => {
                const url_key = el?.url_key
                if (urlKeys.includes(url_key)) {
                    tempProductData?.products?.items.push(el)
                }
            })
            productsData = tempProductData
        }
        return (
            [...((productsData?.products?.items as BundleProduct[]) ?? [])]?.sort(
                (a, b) =>
                    urlKeys.indexOf(b?.url_key || '') -
                    urlKeys.indexOf(a?.url_key || '') >
                    0
                        ? -1
                        : 1
            ) || []
        )
    }, [productsData])

    useEffect(() => {
        if (items?.length > 0) {
            const list = asPath.replace('.html', '')
            GTM_EVENTS.impressionView({
                ecommerce: {
                    impressions: (productsData?.products?.items || []).map((product: any) => ({
                            list,
                            category1: product?.main_category?.[0]?.name,
                            id: product?.sku,
                            name: product?.name,
                            price: product?.price_range.minimum_price?.final_price?.value,
                            dimension6: ProductHelper.getNameAvailable(ProductHelper.getAvailableAmount(product?.items, undefined, product?.salable_qty, product)),
                            dimension4: getDimension4(product?.categories),
                        })
                    ),
                },
            })
        }
    }, [items])
    return (
        <>
            {
                !loading && (
                    <Box
                        as="section"
                        my={{
                            base: '48px',
                            lg: '96px',
                        }}
                        {...boxProps}
                    >
                        <ProductsCarousel
                            {...{
                                title,
                                titleProps: {
                                    textStyle: 'pageHeader',
                                },
                                items,
                                rows,
                                swiperProps,
                            }}
                        />
                    </Box>
                )
            }
        </>
    )
}

export default ContentProducts
