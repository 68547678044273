import React from 'react'
import { Box } from '@chakra-ui/react'

import { DisplayDescription, DisplayNews, DisplayTitle } from '../Display'
import { BannerProps, BANNER_ALIGNMENT } from './types'
import {
  StyledContainer,
  StyledHStack,
  StyledMainBox,
  StyledSecondaryBox,
  StyledWrapper,
} from './BannerTextBox.styled'
import dynamic from 'next/dynamic'

const DynamicButtonSlider = dynamic(() => import('../../../components/atoms/Buttons/ButtonSlider/ButtonSlider'), {
  ssr: false,
})

const BannerTextBox = ({
  cta,
  cta2,
  title,
  description,
  color = 'white',
  bg = 'transparent',
  alignment = BANNER_ALIGNMENT.LEFT,
  link,
}: BannerProps) => {
  const isTransparentBg = bg === 'transparent'
  return (
    <StyledWrapper
      bottom="0"
      position={isTransparentBg ? 'absolute' : 'relative'}
      height={isTransparentBg ? 'unset' : '100%'}
      width="100%"
    >
      <StyledMainBox background={bg}>
        <StyledContainer>
          <StyledSecondaryBox color={color} alignment={alignment}>
            <Box flex={1}>
              <DisplayTitle title={title} />
              {
                description && (
                      <DisplayDescription description={description} />
                  )
              }
            </Box>
            <StyledHStack>
              {
                cta?.text && (cta?.link?.href || cta?.link?.external_href) && (
                  <DynamicButtonSlider cta={cta} />
                )
              }
              {
                  cta2?.text && (cta2?.link?.href || cta2?.link?.external_href) && (
                      <DynamicButtonSlider cta={cta2} />
                  )
              }
              {link && <DisplayNews {...{ link }} />}
            </StyledHStack>
          </StyledSecondaryBox>
        </StyledContainer>
      </StyledMainBox>
    </StyledWrapper>
  )
}

export default BannerTextBox
