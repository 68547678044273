import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function PlayIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 69 69" {...props}>
      <g transform="translate(-1265 -4163)">
        <g transform="translate(1265 4163)">
          <circle cx="34.5" cy="34.5" r="34.5" />
          <circle cx="34.5" cy="34.5" r="33.75" />
        </g>
        <g transform="translate(1289 4188.136)">
          <path d="M7,4V23.692l16-9.846Z" transform="translate(-2 -4.482)" />
        </g>
      </g>
    </Icon>
  )
}
