import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  YoutubeIcon,
} from '_app/components/Icons'

export const getSocialIcon = (text: string, fontSize?: number) => {
  switch (text?.toLowerCase()) {
    case 'facebook':
      return <FacebookIcon fontSize={fontSize ?? 32} />
    case 'instagram':
      return <InstagramIcon fontSize={fontSize ?? 32} />
    case 'pinterest':
      return <PinterestIcon fontSize={fontSize ?? 32} />
    case 'youtube':
      return <YoutubeIcon fontSize={fontSize ?? 32} />
    default:
      return null
  }
}
