import React, {useEffect, useMemo, useState} from 'react'
import {Swiper, SwiperSlide, SwiperProps} from 'swiper/react'
import {Swiper as SwiperClass} from 'swiper/types'
import {uniqueId} from 'lodash-es'
import {Box, Container, Flex, IconButton} from '@chakra-ui/react'
import styled from '@emotion/styled'

import {ChevronRightIcon, ChevronLeftIcon} from '_app/components/Icons'

import 'swiper/css'
import {getDimension4, GTM_EVENTS} from '../utils/dataLayer'
import ProductHelper from '../../helpers/ProductHelper/ProductHelper'

const StyledSwiper = styled(Swiper)`
    overflow: unset;
`

const SLIDES_PER_VIEW = 4

interface CarouselSectionProps {
    slides: React.ReactNode[];
    title?: React.ReactNode;
    swiperProps?: SwiperProps;
    slidesPerView?: number;
    withLayer?: boolean;
}

const CarouselSection = ({
                             title,
                             slides = [],
                             swiperProps,
                             slidesPerView = SLIDES_PER_VIEW,
                             withLayer,
                         }: CarouselSectionProps) => {
    const [swiper, setSwiper] = useState<SwiperClass>()
    const [canNext, setCanNext] = useState(true)
    const [canPrev, setCanPrev] = useState(false)
    const [start, setStart] = useState(0)

    const id = useMemo(() => uniqueId('carousel'), [])

    const onNext = () => {
        swiper?.slideNext()
    }

    const onPrev = () => {
        swiper?.slidePrev()
    }

    const onSlideChange = (e: SwiperClass) => {
        setStart(e.activeIndex)
        setCanNext(!e.isEnd)
        setCanPrev(!e.isBeginning)
    }

    useEffect(() => {
        if (slides?.length > 0) {
            GTM_EVENTS.impressionView({
                ecommerce: {
                    impressions: (slides || []).map((product: any) => {
                            const item = product?.props?.item
                            return ({
                                list: item?.category?.name,
                                id: item.sku,
                                name: item.name,
                                price: item?.price_range?.minimum_price?.final_price?.value,
                                brand: 'Lilou',
                                category1: item?.main_category?.[0]?.name,
                                category2: 'Nieokreślone',
                                dimension6: ProductHelper.getNameAvailable(ProductHelper.getAvailableAmount(item?.items, undefined, item?.salable_qty, item)),
                                dimension4: item?.categories && getDimension4(item?.categories),
                            })
                        }
                    ),
                },
            })
        }
    }, [slides])

    return (
        <Box as="section">
            <Container mb={8}>
                <Flex justifyContent="space-between" alignItems="flex-end">
                    <Box>{title}</Box>
                    {slides.length > (swiperProps?.slidesPerView ?? slidesPerView) && (
                        <Box
                            display={{
                                base: 'none',
                                lg: 'block',
                            }}
                        >
                            <IconButton
                                aria-label="previous"
                                variant="icon"
                                icon={<ChevronLeftIcon fontSize="2.5rem"/>}
                                onClick={onPrev}
                                disabled={!canPrev}
                                w="2.5rem"
                                h="2.5rem"
                            />
                            <IconButton
                                aria-label="next"
                                variant="icon"
                                icon={<ChevronRightIcon fontSize="2.5rem"/>}
                                onClick={onNext}
                                disabled={!canNext}
                                w="2.5rem"
                                h="2.5rem"
                            />
                        </Box>
                    )}
                </Flex>
            </Container>
            <Box overflowX="hidden">
                <Container>
                    <StyledSwiper
                        onSwiper={setSwiper}
                        onSlideChange={onSlideChange}
                        slidesPerView={slidesPerView}
                        spaceBetween={swiperProps?.spaceBetween ?? 30}
                        breakpoints={{
                            0: {
                                slidesPerView: 1.5,
                            },
                            832: {
                                slidesPerView: 2.5,
                            },
                            1024: {
                                slidesPerView: 4,
                            },
                        }}
                        {...swiperProps}
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide key={`${id}[${index}]`}>
                                <Box
                                    _after={
                                        withLayer && (index > start + 2 || index < start)
                                            ? {
                                                content: '" "',
                                                bg: '#fff',
                                                opacity: 0.4,
                                                position: 'absolute',
                                                inset: 0,
                                                zIndex: '9',
                                            }
                                            : {}
                                    }
                                >
                                    {slide}
                                </Box>
                            </SwiperSlide>
                        ))}
                    </StyledSwiper>
                </Container>
            </Box>
        </Box>
    )
}

export default CarouselSection
