import {ImageLoaderProps} from 'next/legacy/image'
import ImageHelper from './ImageHelper'

export default {
    cloudflareLoader: ({ src, width, quality = 0.8 }: ImageLoaderProps) => {
        const params = [`width=${width}`, `quality=${quality}`, 'format=auto']
        const paramsString = params.join(',')
        return `${process.env.NEXT_PUBLIC_API_URL}/cdn-cgi/image/${paramsString}/${ImageHelper.normalizeSrc(src)}`
    },
    normalizeSrc: (src: string) => {
        try {
            const url = new URL(src)
            if (url.host === 'imagedelivery.net') {
                return `${process.env.NEXT_PUBLIC_API_URL}/cdn-cgi/imagedelivery${url.pathname}`
            }
            return url.href
        } catch (_) {
            return src
        }
    },
    checkImage: (url: string, callback: { (isValid: any): void; (arg0: boolean): void }) => {
        if (!url) {
            callback(false)
            return
        }
        const img = new window.Image()
        img.onload = () => callback(true)
        img.onerror = () => callback(false)
        img.src = url
    }
}