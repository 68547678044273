import ReactPlayer from 'react-player/lazy'
import React, { ReactElement } from 'react'
import { BaseReactPlayerProps } from 'react-player/base'
import useDisplayReactPlayer from './useDisplayReactPlayer'
import { getSource } from './utils'

export type Props = Pick<
  BaseReactPlayerProps,
  | 'loop'
  | 'playing'
  | 'playsinline'
  | 'muted'
  | 'controls'
  | 'height'
  | 'width'
  | 'onProgress'
> & {
  playOverlay?: ReactElement;
  iframeFrameBorder?: string;
  iframeHeight?: number;
  iframeWidth?: number;
  url?: string;
  onReady?: () => void;
};

const VideoPlayer = ({
  playOverlay,
  loop = true,
  playing = true,
  playsinline = true,
  muted = true,
  controls = false,
  iframeFrameBorder,
  iframeHeight = 400,
  iframeWidth = 440,
  height = '100%',
  width = '110%',
  onProgress,
  onReady,
  url,
}: Props) => {
  const [isIosDevice, isVimeo] = useDisplayReactPlayer(url)

  if (isIosDevice) {
    return (
      <iframe
        id="player"
        width={iframeWidth}
        height={iframeHeight}
        allow="autoplay"
        allowFullScreen={false}
        onLoad={onReady}
        src={getSource({ isVimeo, url, muted, controls, loop })}
        frameBorder={iframeFrameBorder}
      ></iframe>
    )
  }

  return (
    <>
      <ReactPlayer
        className="react-player"
        loop={loop}
        playing={playing}
        playsinline={playsinline}
        muted={muted}
        width={width}
        height={height}
        url={url}
        controls={controls}
        onReady={onReady}
        onProgress={onProgress}
      />
      {playOverlay}
    </>
  )
}

export default VideoPlayer
