import { Box, Grid, Text } from '@chakra-ui/react'
import Image from 'next/legacy/image'
import { useTranslation } from 'next-i18next'
import ModalNecklace from '../ModalNecklace'
import { AskMoreQuestions } from './AskMoreQuestions'

type NecklaceProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const Necklace = ({ isOpen, onClose }: NecklaceProps) => {
  const { t } = useTranslation('product')

  return (
    <ModalNecklace isOpen={isOpen} onClose={onClose}>
      <Grid
        gridTemplateAreas={{
          base: `
            "header"
            "image"
            "text"
            `,
          md: `
                "image header"
                "image text"
                `,
        }}
        gridTemplateColumns={{ md: 'repeat(2, 1fr)' }}
        position="relative"
      >
        <Box
          gridArea="image"
          position="relative"
          minHeight={{ base: '800px', md: '600px' }}
        >
          <Image
            src="/images/product/necklace_measure.webp"
            alt="Necklace_meaasure"
            height={1200}
            width={800}
          />
        </Box>

        <Box
          gridArea="header"
          px={4}
          pl={{ lg: '2rem' }}
          pr={{ lg: '8rem' }}
          pt={{ lg: '2rem' }}
        >
          <Text
            fontWeight={600}
            fontSize={{ base: 20, md: 24 }}
            pt={4}
            lineHeight={1}
            maxW="80%"
            pb={4}
          >
            {t('CHAIN_SIZE_SELECTION')}
          </Text>

          <Text
            fontSize="16px"
            fontWeight={300}
            lineHeight={1.25}
            pb={{ base: 4, md: 0 }}
          >
            {t('WE_PREPARED_GRAPHICS_TO_ILLUSTRATE')}
          </Text>
        </Box>

        <Box gridArea="text" px={4} pl={{ lg: '2rem' }} pr={{ lg: '8rem' }}>
          <Text fontWeight={400} pt={6} pb={2}>
            {t('CHAIN_FOR_CHILDREN_LENGTH')}
          </Text>
          <Text fontWeight={300} lineHeight={1.25}>
            {t('SMALLEST_NECKLACE_RECOMMENDATION')}
          </Text>
          <Text pt={6} pb={2}>
            {t('HOW_LONG_CHAIN_FOR_ADULT')}
          </Text>
          <Text pb={4} fontWeight={300} lineHeight={1.25}>
            {t('CHOOSING_CHAIN_LENGTH_EXPLANATION_FULL')}
          </Text>

          <AskMoreQuestions
            ml="-2rem"
            px="2rem"
            w={{ md: 'calc(100% + 10rem)' }}
          />
        </Box>
      </Grid>
    </ModalNecklace>
  )
}
