import { Flex, FormControl, FormLabel, useRadioGroup } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import { get } from 'lodash-es'
import FormErrorMessage from '_app/components/Form/FormErrorMessage'
import { RadioCard } from './RadioCard'

const RadioOption: any = ({
  name,
  label,
  isRequired,
  options = [],
  content: Content,
  cardProps,
  defaultValue,
  changeHandler,
  isArray,
}) => {
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext()

  const errorMessage = get(errors, name)?.message

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: option => {
      changeHandler?.(option)
      setValue(name, isArray ? [option] : option)
    },
  })

  const current = watch(name)

  const group = getRootProps()

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={() => (
          <Flex gap={1} flexWrap="wrap" {...group}>
            {options.map((option: any) => {
              const radio = getRadioProps({ value: option.value })

              return (
                <RadioCard
                  key={option.value}
                  {...radio}
                  isChecked={current?.[0] === option?.value}
                  cardProps={cardProps}
                >
                  <Content {...option} />
                </RadioCard>
              )
            })}
          </Flex>
        )}
      />
      <FormErrorMessage name={label}>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default RadioOption
