import styled from '@emotion/styled'
import { Box, Flex, Center } from '@chakra-ui/react'
import theme from 'theme'

export const StyledWrapper = styled(Flex)<{ storyImagePosition: string }>`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  gap: 0;
  flex-direction: column;
  position: relative;
  ${({ storyImagePosition }) => `
    justify-content: ${storyImagePosition === 'right'
      ? 'flex-end'
      : 'flex-start'};
  `};
  @media (min-width: ${theme.breakpoints.lg}) {
    margin-top: 6rem;
    margin-bottom: 6rem;
    gap: 9.375rem;
    flex-direction: row;
  }
`

export const StyledAttributesBox = styled(Box)`
  width: 100%;
  flex: 0 1 66%;

  @media (min-width: ${theme.breakpoints.lg}) {
    width: 60%;
  }
`

export const StyledImageBox = styled(Box)<{ mobile: boolean }>`
  ${({ mobile }) => `
    display: block;

    @media (min-width: ${theme.breakpoints.lg}) {
      display: ${mobile ? 'none' : 'block'};
    }
  `}
`

export const StyledCenter = styled(Center)<{ storyImagePosition: string }>`
  position: static;
  flex: 0 1 40%;
  width: 100%;
  top: 0;
  bottom: 0;
  ${({ storyImagePosition }) => `
    right: ${storyImagePosition === 'right' ? 'unset' : 0};
    left: ${storyImagePosition !== 'right' ? 'unset' : 0};
  `};
  @media (min-width: ${theme.breakpoints.lg}) {
    position: absolute;
    width: 40%;
  }
`

export const StyledTextBox = styled(Box)<{ storyImagePosition: string }>`
  background: #ffffff;
  padding: 1.875rem 0 1.875rem 0;

  ${({ storyImagePosition }) => `
    @media (min-width: ${theme.breakpoints.lg}) {
      padding-left: ${storyImagePosition !== 'right' ? '3.75rem' : 0};
      padding-right: ${storyImagePosition !== 'right' ? '3.75rem' : '8.125rem'};
    }
  `}
`
