import * as Sentry from '@sentry/nextjs'

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
    tracesSampleRate: 0.1,
    sampleRate: 0.5,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: (integrations) => [
        ...integrations.filter(integration => integration.name !== 'NextLowQualityTransactionsFilter')
    ],
    environment: process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
    denyUrls: [
        /.*dev-fe\.lilou\.pl.*/,
        /.*preprod\.lilou\.pl.*/,
        /.*preprod\.lilou\.fr.*/,
        /.*preprod\.lilouparis\.de.*/,
        /.*preprod\.lilouparis\.eu.*/,
        /.*hyva-preprod\.lilou\.pl.*/,
    ],
    beforeSend(event) {
        event.headers = {
            ...event.headers,
            'X-Sentry-Token': process.env.NEXT_SENTRY_TOKEN,
        }
        return event
    },
    debug: false,
    release: 'love-lilou-pwa@2.0.0',
})

Sentry.configureScope(scope => {
    scope.setFingerprint(['non-error-promise-rejection'])
})