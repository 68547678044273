import { Flex, Text } from '@chakra-ui/react'
import Image from 'next/legacy/image'
import { Maybe, ComponentPartsLink } from '_app/graphql/cms/generated/graphql'

import CmsLink from '_app/components/Link/CmsLink'

interface NavigationListItemProps {
  onClick?: () => void;
  link?: Maybe<ComponentPartsLink>;
  text?: Maybe<string>;
  isBold?: boolean;
  showBorderBottom?: boolean;
  showBorderTop?: boolean;
  mainListItem?: boolean;
}

export const NavigationListItem = ({
  text,
  onClick,
  link,
  isBold,
  showBorderBottom,
  showBorderTop,
  mainListItem,
}: NavigationListItemProps) => (
  <CmsLink link={link} width="full">
    <Flex
      cursor="pointer"
      py={4}
      px={mainListItem ? 4 : 0}
      justifyContent="space-between"
      width="100%"
      borderBottomWidth={showBorderBottom ? 1 : 0}
      borderTopWidth={showBorderTop ? 1 : 0}
      borderBottomColor="mercury"
      onClick={onClick}
    >
      <Text sx={{ color: text?.toLowerCase() === 'special prices' ? 'red' : 'auto'}} fontWeight={isBold ? 400 : 300}>{text}</Text>
      <Image
        src="/images/icons/arrow-right.svg"
        alt="Arrow Right"
        width="24"
        height="24"
      />
    </Flex>
  </CmsLink>
)
