import React, { FC } from 'react'
import { LinkProps as NextLinkProps } from 'next/link'
import {Link, LinkProps} from '@chakra-ui/react'
import styled from '@emotion/styled'

interface LinkButtonProps
    extends Omit<LinkProps, 'href'>,
        Pick<NextLinkProps, 'shallow' | 'scroll'> {
    href?: any;
    onClick?(): void;
    reverse?: boolean;
    image?: boolean;
}

const LinkUnderline = styled.a<{ reverse?: boolean, style?: string | object | undefined }>`
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: inherit;

    &::after {
        content: '';
        position: absolute;
        left: ${({ reverse }) => (reverse ? '37%' : '0')};
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: currentColor;
        transition: width 0.3s ease, left 0.3s ease;
        transform-origin: left;
    }

    &:hover::after {
        width: ${({ reverse }) => (reverse ? '100%' : '30%')};
        left: ${({ reverse }) => (reverse ? '0' : '37%')};
    }
`

const LinkButton: FC<LinkButtonProps> = ({
                                             href,
                                             children,
                                             onClick,
                                             reverse,
                                             shallow,
                                             scroll,
                                             image,
                                              sx = {},
                                             ...props
                                         }) => {
    return (
        <>
            {href ? (
                <LinkUnderline style={sx as React.CSSProperties} href={href || ''} reverse={reverse}>
                    {children}
                </LinkUnderline>
            ) : (
                <Link
                    {...props}
                    sx={sx}
                    onClick={onClick}
                    reverse={reverse?.toString()}
                    cursor="default"
                >
                    {children}
                </Link>
            )}
        </>
    )
}

export default LinkButton
