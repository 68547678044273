import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function LogoutIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M14.5 8.5v-2a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v12c0 1.1-.1 2 1 2h8a2 2 0 0 0 2-2v-2" />
      <path d="M7.5 12.5h14l-3-3m0 6 3-3" />
    </Icon>
  )
}
