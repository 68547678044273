import { ReactNode } from 'react'
import { useFlagshipContext } from '_app/providers/FlagshipProvider'

interface Props {
  children: ReactNode;
  featureFlag: keyof ReturnType<typeof useFlagshipContext>;
}

export const FeatureWrapper = ({ children, featureFlag }: Props) => {
  const flags = useFlagshipContext()
  const isEnabled = featureFlag && flags[featureFlag]

  if (isEnabled) {
    return <>{children}</>
  }

  return null
}
