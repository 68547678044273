import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function SavedCrossedOutIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 54 54" {...props}>
      <path
        data-name="Line 228"
        transform="translate(7.021 7.021)"
        d="m0 0 41.957 41.957"
      />
      <path
        data-name="Path 479"
        d="M30.351 34.314v7l-11.676-7L7 41.311V10.989m2.751-6.572a4.683 4.683 0 0 1 1.919-.411h14.011a4.668 4.668 0 0 1 4.67 4.665V25"
        transform="translate(9.324 5.349)"
      />
    </Icon>
  )
}
