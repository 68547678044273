import { Props as PlayerProps } from './VideoPlayer'

type Props = Pick<PlayerProps, 'url' | 'muted' | 'controls' | 'loop'> & {
  isVimeo?: boolean;
};

const getSource = ({ isVimeo, url, muted, controls, loop }: Props) => {
  if (!isVimeo) {
    return `${url?.replace(
      /manifest\/video.+/,
      'iframe'
    )}?letterboxColor=white&autoplay=true&muted=${muted}&controls=${controls}&loop=${loop}`
  }

  return `https://player.vimeo.com/video/${url?.replace(
    'https://vimeo.com/',
    ''
  )}?autoplay=1&loop=${loop ? '1' : '0'}&autopause=0&muted=${
    muted ? '1' : '0'
  }&controls=${controls ? '1' : '0'}&?enablejsapi=1`
}

export { getSource }
