import {useReactiveVar} from '@apollo/client'
import {Box, Flex, IconButton, Tooltip} from '@chakra-ui/react'
import {parseCookies} from 'nookies'
import {useTranslation} from 'next-i18next'
import {Dispatch, SetStateAction, useEffect, useMemo} from 'react'
import {useRouter} from '_app/hooks/useRouter'
import {cart, isCartOpenVar, wishlistArrayVar} from '_app/cache'
import Badge from '_app/components/Badge'
import {
    CloseIcon,
    HeartIcon,
    LoggedInUserIcon,
    MenuIcon,
    SearchIcon,
    ShoppingCartIcon,
    UserIcon,
} from '_app/components/Icons'
import {APP_ROUTES} from '_app/routes'
import {useGetCartQuery} from '_app/graphql/queries/cart/GetCartDocumentSmall'
import useCustomer from '_app/hooks/useCustomer'
import NavBarLogo from '_app/components/NavBarLogo'
import URLHelper from '../../../helpers/URLHelper/URLHelper'
import CookiesHelper from '../../../helpers/CookiesHelper/CookiesHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

interface Icons {
    toggle: {
        isOpen: boolean;
        onToggle(): void;
    };
    getToggleProps(...{}): object;
    setExpanded: Dispatch<SetStateAction<boolean>>;
}

const NavBarIcons = ({
        toggle: {isOpen, onToggle},
        getToggleProps,
        setExpanded,
    }: Icons) => {
    const router = useRouter()
    const {t} = useTranslation()
    const {cartId} = parseCookies()
    const isSuccessPage = router.asPath?.includes('success')
    const {data: dataCart} = useGetCartQuery({
        variables: {
            cartId: cartId,
        },
        skip: isSuccessPage || !cartId,
    })
    const cartValue = useReactiveVar(cart) || dataCart
    const data = (isSuccessPage || !cartId) ? null : (cartValue ? cartValue : null)
    const quantity = useMemo(() => (data?.cart?.items || []).reduce((acc: number, val: { quantity: any }) => {
        return acc + (val?.quantity ?? 0)
    }, 0), [data, cartValue])
    const isCartOpen = useReactiveVar(isCartOpenVar)
    const wishlistArray = useReactiveVar(wishlistArrayVar)
    const {customer} = useCustomer()
    const customerToken = CookiesHelper.get('customerToken')
    const quantityOfFavorites = !!customer
        ? customer?.wishlists?.[0]?.items_v2?.items?.length
        : wishlistArray?.length
    const handleFavoritesIcon = () =>
        !!customer
            ? router.push(APP_ROUTES.PROFILE.FAVORITES)
            : () => {}
    const handleUserIcon = () =>
        !!customer
            ? router.push(APP_ROUTES.PROFILE.DEFAULT)
            : window?.open(URLHelper.translateHref('/login', router.locale) as string, '_self')
    const updateCart = () => {
        if (dataCart) {
            cart(dataCart)
        }
    }
    useEffect(() => {
        updateCart()
    }, [dataCart])
    useEffect(() => {
        const handleRouteChange = () => {
            updateCart()
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])
    return (
        <>
            <Box
                cursor="pointer"
                display={{base: 'flex', lg: 'none'}}
                flex={BreakpointsHelper.isMobile() ? '1 1 33.33%' : '1 1 19%'}
            >
                <IconButton
                    variant="icon"
                    onClick={onToggle}
                    icon={isOpen ? <CloseIcon mr="1rem"/> : <MenuIcon/>}
                    aria-label={t('MENU')}
                />
            </Box>
            <NavBarLogo
                display={{base: 'block', lg: 'none'}}
                paddingLeft={'20px'}
                flex={BreakpointsHelper.isMobile() ? '1 1 33.33%' : '1 1 19%'}
            />
            <Flex
                gap={5}
                pb="17px"
                flex={BreakpointsHelper.isMobile() ? '1 1 33.33%' : '1 1 19%'}
                justifyContent="flex-end"
                alignItems="center"
            >
                <IconButton
                    variant="icon"
                    w={{base: 'auto', lg: '1.5rem'}}
                    px={{base: 0, lg: 3}}
                    icon={<SearchIcon/>}
                    aria-label={t('SEARCH')}
                    {...getToggleProps({
                        onClick: () => setExpanded(prevExpanded => !prevExpanded),
                    })}
                    display={{base: !isOpen ? 'block' : 'none', lg: 'flex'}}
                />
                {
                    customerToken?.length > 0 ? (
                        <Badge
                            hide={quantityOfFavorites === 0 || quantityOfFavorites === undefined}
                            value={quantityOfFavorites}
                            maxValue={quantityOfFavorites}
                            display={{base: isOpen ? 'block' : 'none', lg: 'flex'}}
                        >
                            <IconButton
                                variant="icon"
                                w={{base: 'auto', lg: '1.5rem'}}
                                px={{base: 0, lg: 3}}
                                icon={<HeartIcon/>}
                                aria-label={t('FAVORITES')}
                                onClick={handleFavoritesIcon}
                            />
                        </Badge>
                    ) : (
                        <Tooltip isDisabled={customerToken?.length > 0} hasArrow label={t('FAVORITES_DISABLED')} bg='red.600'>
                            <IconButton
                                variant="icon"
                                w={{base: 'auto', lg: '1.5rem'}}
                                px={{base: 0, lg: 3}}
                                icon={<HeartIcon/>}
                                sx={{
                                    cursor: customerToken?.length > 0 ? 'pointer' : 'auto',
                                }}
                                aria-label={t('FAVORITES')}
                                onClick={handleFavoritesIcon}
                            />
                        </Tooltip>
                    )
                }
                <IconButton
                    variant="icon"
                    w={{base: 'auto', lg: '1.5rem'}}
                    px={{base: 0, lg: 3}}
                    icon={!!customer ? <LoggedInUserIcon/> : <UserIcon/>}
                    onClick={handleUserIcon}
                    display={{base: isOpen ? 'block' : 'none', lg: 'flex'}}
                    aria-label={t('PROFILE_LOWER_CASE')}
                />
                <Badge
                    hide={quantity === 0 || quantity === undefined}
                    value={quantity}
                    maxValue={quantity}
                    sx={{ width: '24px', height: '24px' }}
                    display={{base: !isOpen ? 'block' : 'none', lg: 'flex'}}
                >
                    <IconButton
                        variant="icon"
                        w={{base: 'auto', lg: '1.5rem'}}
                        px={{base: 0, lg: 3}}
                        icon={<ShoppingCartIcon/>}
                        aria-label={t('CART')}
                        onClick={() => isCartOpenVar(!isCartOpen)}
                    />
                </Badge>
            </Flex>
        </>
    )
}

export default NavBarIcons
