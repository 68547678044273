import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function AbundanceIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <ellipse
        id="Ellipse_5147"
        data-name="Ellipse 5147"
        cx="6.667"
        cy="4"
        rx="6.667"
        ry="4"
        transform="translate(10 4.667)"
      />
      <path
        id="Path_13483"
        data-name="Path 13483"
        d="M11.5,6.5v5.333c0,2.209,2.985,4,6.667,4s6.667-1.791,6.667-4V6.5"
        transform="translate(-1.5 2.167)"
      />
      <path
        id="Path_13484"
        data-name="Path 13484"
        d="M11.5,10.5v5.333c0,2.209,2.985,4,6.667,4s6.667-1.791,6.667-4V10.5"
        transform="translate(-1.5 3.5)"
      />
      <path
        id="Path_13485"
        data-name="Path 13485"
        d="M11.5,14.5v5.333c0,2.209,2.985,4,6.667,4s6.667-1.791,6.667-4V14.5"
        transform="translate(-1.5 4.833)"
      />
    </Icon>
  )
}
