import { gql } from '@apollo/client'

export const IS_CART_ACTIVE = gql`
    query isCartActive($masked_cart_id: String!) {
        isCartActive(masked_cart_id: $masked_cart_id) {
            is_active
            message
        }
    }
`
