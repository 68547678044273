export const appendQueryParams = (
  search: string,
  params: Record<string, string | number>
) => {
  const newParams = new URLSearchParams(search)
  Object.entries(params).forEach(([key, value]) => {
    newParams.set(key, value.toString())
  })

  return newParams
}

export const paramsToUrl = (url: string, search: URLSearchParams) => {
  const paramsString = search.toString()
  if (!paramsString.length) {
    return url
  }

  return `${url}?${paramsString}`
}

export const asPathSplit = (asPath: string) => {
  const [pathname, search] = asPath.split('?')

  return { pathname, search }
}
