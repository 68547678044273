import {CircularProgress, FormControl, HStack, IconButton, Input, StyleProps,} from '@chakra-ui/react'
import styled from '@emotion/styled'
import {AddIcon, MinusIcon} from '@chakra-ui/icons'
import React, {FC, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {get, isFunction, isNumber} from 'lodash-es'
import FormErrorMessage from '_app/components/Form/FormErrorMessage'
import {BaseInputProps} from '_app/types/form'

const StyledIconBtn = styled(IconButton)`
    &:disabled {
        cursor: auto;
        opacity: .3;
    }
`

interface Props extends BaseInputProps, StyleProps {
  min?: number;
  max?: number;
  value: number;
  onStateUpdate?: (value: number) => Promise<any>;
  onStateDelete?: any;
  disableQty: boolean;
  cartItem?: any;
  setData?: any;
}

const Quantity: FC<Props> = React.memo(
  ({
    name,
    label,
    min = 0,
    max,
    value,
    isReadOnly,
    onStateUpdate,
    onStateDelete,
    cartItem,
    setData,
    disableQty,
    mb = 4,
    ...props
  }) => {
    const [curr, setCurr] = useState(isNumber(value) ? value : 1)
    const style = {
        svgLoading: {
            height: '20px',
            left: '20px',
            position: 'relative',
        }
    }
    const {
      register,
      setValue,
      formState: { errors },
    } = useFormContext()
    const errorMessage = get(errors, name)?.message
    const dec = { isDisabled: Boolean((isNumber(max) ? value <= min : false) || disableQty) }
    const inc = {
      isDisabled: Boolean((isNumber(max) ? value >= max : false) || disableQty),
    }
    const input = { isReadOnly: isReadOnly }
    const handleSetCurr = async (val: number, action) => {
        if (!isFunction(onStateUpdate)) {
            return
        } else {
            if (curr === 1 && action === 'min') {
                const res = await onStateDelete(cartItem)
                if (res?.data) {
                    setData(res?.data?.removeItemFromCart)
                }
                return res
            } else {
                const res = await onStateUpdate(val)
                if (res?.data) {
                    setValue(name, val)
                    setCurr(val)
                }
                return res
            }
        }
    }
    return (
      <FormControl isInvalid={!!errorMessage} mb={mb} {...props}>
          {
              disableQty ? (
                  <CircularProgress isIndeterminate sx={{ '& svg': style.svgLoading }} />
              ) : (
                  <>
                      <HStack maxW="320px">
                          <StyledIconBtn
                              variant="icon"
                              icon={<MinusIcon />}
                              {...dec}
                              aria-label="minus"
                              onClick={() => handleSetCurr(curr - 1, 'min')}
                          />
                          <Input
                              variant="short"
                              {...register(name)}
                              type="number"
                              {...input}
                              value={curr}
                              disabled={disableQty}
                          />
                          <StyledIconBtn
                              aria-label="plus"
                              variant="icon"
                              icon={<AddIcon />}
                              {...inc}
                              onClick={() => handleSetCurr(curr + 1, 'max')}
                          />
                      </HStack>
                      <FormErrorMessage name={label}>{errorMessage}</FormErrorMessage>
                  </>
              )
          }
      </FormControl>
    )
  }
)

Quantity.displayName = 'Quantity'

export default Quantity
