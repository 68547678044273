import React from 'react'
import {Box} from '@chakra-ui/react'
import BannerTextBox from './BannerTextBox'
import {BannerProps} from './types'

const Banner: React.FC<BannerProps> = ({ boxProps, ...props }) => {
  return (
    <Box position="relative" {...boxProps}>
      {props.children}
      <BannerTextBox {...props} />
    </Box>
  )
}

export default Banner
