export enum Events {
  PageView = 'pageView',
  ProductDetailView = 'productDetailView',
  ProductClick = 'productClick',
  AddToCart = 'addToCart',
  AddToWishlist = 'addToWishList',
  Checkout = 'checkout',
  PaymentMethod = 'paymentMethod',
  Purchase = 'purchase',
  Payment = 'payment',
  Newsletter = 'newsletter',
  RegisterAccount = 'register account',
  PromotionView = 'eec.promotionView',
  PromotionClick = 'eec.promotionClick',
  Scroll = 'scroll',
  Search = 'search',
  Errors = 'errorsInfor',
  ImpressionView = 'impressionView',
  HitsViewed = 'Hits Viewed',
  PersonalizeProduct = 'personalizeProduct',
  ConfigureProducts = 'configureProducts',
}

export enum PageType {
  MainPage = 'main page',
  Category = 'category',
  ProductPage = 'product page',
  Checkout = 'checkout',
  Confirmation = 'purchase',
  Login = 'login page',
  Blog = 'content page',
  Register = 'register page',
  Profile = 'account page',
  Cart = 'cart',
  InspirationInformation = 'lookbook page',
  Other = 'other',
}

export enum VisitorLoginState {
  LoggedOut = 'logged out',
  LoggedIn = 'logged in',
}

export enum ProductAvailability {
  InStock = 'W magazynie',
  InStock13 = 'Ostatnie sztuki',
  OutOfStock = 'Produkt niedostępny',
}

export interface VisitorPayload {
  pageType: PageType;
  visitorLoginState: VisitorLoginState;
  visitorLTVRevenue: number | 0;
  visitorLTVTransactions: number | 0;
  userId?: string | null;
  email?: string | null;
}

export interface ProductDetailViewPayload {
  ecommerce: {
    currencyCode: string;
    detail: {
      products: {
        name: string;
        id: string;
        price: number;
        category?: string;
        quantity: number;
        dimension6?: string;
        dimension4: string;
      }[];
    };
  };
}

export interface CartProduct {
  name: string;
  id: string;
  price: number;
  category?: string;
  brand?: string | null;
  variant?: string;
  quantity: number;
  dimension4?: string;
  dimension7?: string;
  metric3?: number;
  dimension3?: string;
  item_list_name?: string;
}

export interface CheckoutStepPayload<T = number> {
  ecommerce: {
    checkout: {
      actionField: { step: T };
      cartId: string;
      products?: CartProduct[];
    };
  };
}

export interface PromotionPayload {
  ecommerce: {
    promoView: {
      promotions: {
        name: string;
        position: number;
      }[];
    };
  };
}

export interface PromotionClickPayload {
  ecommerce: {
    promoClick: {
      promotions: {
        name: string;
        position: number;
        place?: string;
      }[];
    };
  };
}

export interface ScrollPayload {
  eventLabel: '25%' | '50%' | '75%' | '100%';
}

export type WithAnalyticsId<T extends Record<string, unknown>> = T & {
  analyticsId?: string;
};
