export default {
  xxlMax: '4080',
  xxlMin: '2080',
  xlMax: '2080',
  xlMin: '1440',
  lgMax: '1440',
  lgMin: '1200',
  mdMax: '1200',
  mdMin: '1024',
  smMax: '640',
  smMin: '400',
  xsMax: '400',
  xsMin: '0',
}
