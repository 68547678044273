import {Maybe} from 'graphql/jsutils/Maybe'
import {cloneDeep} from 'lodash-es'
import {GetCartQuery} from '_app/types/api'
import {AddToCartPayload, OrderPayload, ProductViewPayload,} from '_app/types/edrone'
import EnvHelper from '../../helpers/EnvHelper/EnvHelper'
import EnvironmentEnum from '../../enums/EnvironmentEnum/EnvironmentEnum'
import EdroneService from '../../services/EdroneService/EdroneService'

declare global {
  interface Window {
    _edrone?: {
      init?: () => void;
      [key: string]: any;
    };
  }
}

enum LoadingStage {
  NotLoaded,
  InProgress,
  Loaded,
}

let loaded = LoadingStage.NotLoaded
const updatesBeforeInit: Record<string, unknown>[] = []
let initialEdrone: { init?: () => void; [p: string]: any } | undefined = {}
const updateEdroneObject = (item: Record<string, unknown>) => {
  window._edrone = {
    ...initialEdrone,
    ...item,
  }
}

const sendEvent = (payload: Record<string, unknown>) => {
  if (typeof window !== 'undefined' && EnvHelper.getEnvironment() === EnvironmentEnum.PRODUCTION) {
    if (loaded === LoadingStage.Loaded) {
      updateEdroneObject(payload)
      if (window?._edrone?.init) {
        window._edrone.init()
      }
    } else {
      updatesBeforeInit.push(payload)
    }
  }
}

const getProtocol = (protocol: string) => {
  return 'https:' === protocol ? 'https:' : 'http:'
}

const onScriptLoad = () => {
  loaded = LoadingStage.Loaded
  initialEdrone = cloneDeep(window?._edrone)

  updatesBeforeInit.forEach((item, index) => {
    updateEdroneObject(item)
    if (index > 1) {
      window._edrone?.init?.()
    }
  })
}

const loadScriptIfNeeded = (locale?: string) => {
  if (loaded === LoadingStage.NotLoaded && EdroneService?.getEdroneId(locale) && typeof window !== 'undefined' && EnvHelper.getEnvironment() === EnvironmentEnum.PRODUCTION) {
    initEdroneObject(locale)
    loaded = LoadingStage.InProgress
    const doc = document.createElement('script')
    doc.onload = onScriptLoad
    doc.type = 'text/javascript'
    doc.async = true
    doc.src = getProtocol(document.location.protocol) + `//d3bo67muzbfgtl.cloudfront.net/edrone_2_0.js?app_id=${EdroneService?.getEdroneId(locale)}`
    const s = document?.getElementsByTagName('script')[0]
    if (s && doc && s?.parentNode) {
      s?.parentNode?.insertBefore(doc, s)
    }
  }
}

const getInitialEdronePayload = (locale?: string) => {
  return {
    app_id: EdroneService.getEdroneId(locale),
    version: '1.0.0',
    action_type: 'other',
    platform_version: '1.0.0',
    platform: 'custom',
  }
}

const initEdroneObject = (locale?: string) => {
  window._edrone = {
    ...(window._edrone || {}),
    ...getInitialEdronePayload(locale),
  }
}

export const EdroneEvents = {
  other: (locale?: string) => {
    loadScriptIfNeeded(locale)
    sendEvent(getInitialEdronePayload(locale))
  },

  productView: (payload?: ProductViewPayload, locale?: string) => {
    loadScriptIfNeeded(locale)
    sendEvent({
      product_ids: payload?.productId,
      product_skus: payload?.productSku,
      product_titles: payload?.productTitle,
      product_images: payload?.productImages,
      product_urls: payload?.productUrl,
      product_category_ids: payload?.productCategoryIds,
      product_category_names: payload?.productCategoryNames,
      product_availability: payload?.productAvailability,
      action_type: 'product_view',
    })
  },

  addToCart: (payload: AddToCartPayload, locale?: string) => {
    loadScriptIfNeeded(locale)
    sendEvent({
      product_ids: payload.productId,
      product_skus: payload.productSku,
      product_titles: payload.productTitle,
      product_images: payload.productImages,
      product_urls: payload.productUrl,
      product_category_ids: payload.productCategoryIds,
      product_category_names: payload.productCategoryNames,
      action_type: 'add_to_cart',
    })
  },

  order: (payload: OrderPayload, locale?: string) => {
    loadScriptIfNeeded(locale)

    sendEvent({
      action_type: 'order',
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
      product_skus: payload.productSkus,
      product_ids: payload.productIds,
      product_titles: payload.productTitles,
      product_images: payload.productImages,
      product_urls: payload.productUrls,
      product_category_ids: payload.categoryIds,
      product_category_names: payload.categoryNames,
      product_counts: payload.productCounts,
      order_id: payload.orderId,
      country: payload.country,
      city: payload.city,
      base_currency: payload.baseCurrency,
      order_currency: payload.orderCurrency,
      base_payment_value: payload.basePaymentValue,
      order_payment_value: payload.orderPaymentValue,
    })
  },
}

export const uidToNumber = (uid: string | undefined): string => {
  if (typeof window === 'undefined' || !uid) {
    return ''
  }

  try {
    return window.atob(uid)
  } catch (e) {
    return uid
  }
}

export const parseUidsArray = (uids: Array<Maybe<string>>) => {
  return uids
    .filter((uid): uid is string => !!uid)
    .map(uid => uidToNumber(uid))
    .join('~')
}

export const joinElements = (elements: Array<Maybe<string>>) => {
  return elements.filter((uid): uid is string => !!uid).join('~')
}

export const getUrl = (path: Maybe<string>) => {
  if (typeof window === 'undefined' || !path) {
    return ''
  }

  return `${window.location.origin}/${path}.html`
}

type Cart = NonNullable<GetCartQuery['cart']>;
export type Item = NonNullable<Cart['items']>[0];

export const mapCartData =
  (cartData: GetCartQuery | undefined) =>
  (callback: (item: Item | undefined) => void) => {
    return cartData?.cart?.items?.map(item => callback(item)).join('|') || ''
  }
