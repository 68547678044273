export const Input: any = {
  baseStyle: {
    field: {
      border: 'none',
      borderRadius: '0',
      borderBottom: '1px solid black',
    },
  },
  sizes: {},
  variants: {
    main: {
      field: {
        borderRadius: '0',
      },
    },
    short: {
      field: {
        textAlign: 'center',
        borderBottom: 'none',
        width: '60px',
        height: '23px',
        px: 0,
      },
    },
  },
  defaultProps: {
    variant: 'main',
  },
}
