import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
import {GetCartQuery, GetCartQueryVariables} from '../../generated/graphql'

const defaultOptions = {} as const

export function useGetCartQuery(baseOptions: Apollo.QueryHookOptions<GetCartQuery, GetCartQueryVariables> & ({ variables: GetCartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocumentSmall, options)
}

export const GetCartDocumentSmall = gql`
    query GetCart($cartId: String!) {
        cart(cart_id: $cartId) {
            email
            items {
                prices {
                    price {
                        value
                        currency
                    }
                }
                id
                uid
                entered_options {
                    option {
                        label
                        value
                    }
                }
                ... on SimpleCartItem {
                    customizable_options {
                        label
                        values {
                            customizable_option_value_uid
                            label
                            value
                            price {
                                value
                                type
                            }
                        }
                    }
                }
                ... on BundleCartItem {
                    bundle_options {
                        uid
                        label
                        type
                        values {
                            id
                            label
                            price
                            quantity
                            uid
                        }
                    }
                    customizable_options {
                        label
                        values {
                            customizable_option_value_uid
                            value
                            price {
                                value
                                type
                                __typename
                            }
                        }
                    }
                }
                product {
                    id
                    uid
                    sku
                    name
                    brand
                    categories {
                        name
                        uid
                    }
                    main_category {
                        name
                    }
                    alfabet_swatch
                    kolor3_swatch
                    cyfry_swatch
                    kolor_emalii_swatch
                    kolor_kamienia_swatch
                    material_swatch
                    rodzaj_kamienia_swatch
                    rozmiar_swatch
                    znaki_zodiaku_swatch
                    url_key
                    description {
                        html
                    }
                    salable_qty
                    sku
                    crosssell_products {
                        sku
                        id
                        salable_qty
                        url_key
                        name
                        uid
                        image {
                            url
                        }
                        price_range {
                            minimum_price {
                                regular_price {
                                    currency
                                    value
                                }
                                final_price {
                                    currency
                                    value
                                }
                            }
                        }
                    }
                    ... on BundleProduct {
                        items {
                            options {
                                uid
                                id
                                product {
                                    id
                                    sku
                                    url_key
                                    name
                                    rozmiar_swatch
                                    material_swatch
                                    rodzaj_kamienia_swatch
                                    kolor3_swatch
                                    kolor_emalii_swatch
                                    kolor_kamienia_swatch
                                    cyfry_swatch
                                    alfabet_swatch
                                    znaki_zodiaku_swatch
                                    salable_qty
                                    categories {
                                        name
                                    }
                                    main_category {
                                        name
                                    }
                                }
                            }
                        }
                        options {
                            ... on CustomizableEngraveOption {
                                title
                                uid
                                value {
                                    code
                                    placement
                                    uid
                                }
                            }
                        }
                    }
                    upsell_products {
                        name
                        sku
                        salable_qty
                        url_key
                        alfabet_swatch
                        kolor3_swatch
                        cyfry_swatch
                        kolor_emalii_swatch
                        kolor_kamienia_swatch
                        material_swatch
                        rodzaj_kamienia_swatch
                        rozmiar_swatch
                        znaki_zodiaku_swatch
                        image {
                            url
                            label
                        }
                        price_range {
                            minimum_price {
                                regular_price {
                                    currency
                                    value
                                }
                                final_price {
                                    currency
                                    value
                                }
                            }
                        }
                        ... on BundleProduct {
                            items {
                                options {
                                    uid
                                    id
                                    product {
                                        id
                                        sku
                                        url_key
                                        name
                                        rozmiar_swatch
                                        material_swatch
                                        rodzaj_kamienia_swatch
                                        kolor3_swatch
                                        kolor_emalii_swatch
                                        kolor_kamienia_swatch
                                        cyfry_swatch
                                        alfabet_swatch
                                        znaki_zodiaku_swatch
                                        main_category {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    price_range {
                        minimum_price {
                            regular_price {
                                currency
                                value
                            }
                            final_price {
                                currency
                                value
                            }
                        }
                    }
                    image {
                        url
                        label
                    }
                    ... on SimpleProduct {
                        options {
                            ... on CustomizableFieldOption {
                                option_id
                                uid
                                required
                                title
                                value {
                                    max_characters
                                    price
                                    price_type
                                }
                            }
                            ... on CustomizableEngraveOption {
                                title
                                uid
                                value {
                                    code
                                    placement
                                    uid
                                }
                            }
                        }
                    }
                }
                quantity
            }
            applied_coupons {
                code
            }
            prices {
                grand_total {
                    currency
                    value
                }
                subtotal_including_tax {
                    value
                    currency
                }
                subtotal_with_discount_excluding_tax {
                    value
                    currency
                }
            }
            shipping_addresses {
                firstname
                lastname
                company
                telephone
                postcode
                city
                street
                country {
                    code
                    label
                }
                vat_id
                available_shipping_methods {
                    amount {
                        currency
                        value
                    }
                    available
                    base_amount {
                        currency
                        value
                    }
                    carrier_code
                    carrier_title
                    error_message
                    method_code
                    method_title
                    price_excl_tax {
                        currency
                        value
                    }
                    price_incl_tax {
                        currency
                        value
                    }
                }
                selected_shipping_method {
                    amount {
                        currency
                        value
                    }
                    method_code
                    carrier_code
                }
            }
            order_comment
        }
    }
`