import { Container, Text } from '@chakra-ui/react'
import React from 'react'
import { StorySectionProps } from '_app/types/content'
import ImageCms from '_app/components/Content/ImageCms'
import {
  StyledAttributesBox,
  StyledCenter,
  StyledImageBox,
  StyledTextBox,
  StyledWrapper,
} from './StorySection.styled'

const StorySection = ({
  title,
  author,
  description,
  img,
  img_mobile,
  story_img_position,
}: StorySectionProps) => {
  const imgAttr = img?.data?.attributes
  const mobileImgAttr = img_mobile?.data?.attributes

  return (
    <Container>
      <StyledWrapper storyImagePosition={story_img_position} as="section">
        <StyledAttributesBox>
          {imgAttr?.url && !mobileImgAttr?.url && (
            <StyledImageBox mobile={false}>
              <ImageCms src={''} attributes={imgAttr} layout="responsive" />
            </StyledImageBox>
          )}
          {mobileImgAttr?.url && (
            <StyledImageBox mobile={true}>
              <ImageCms src={''} attributes={mobileImgAttr} layout="responsive" />
            </StyledImageBox>
          )}
        </StyledAttributesBox>
        <StyledCenter storyImagePosition={story_img_position}>
          <StyledTextBox storyImagePosition={story_img_position}>
            <Text textStyle="sectionHeader">{title}</Text>
            {description && (
              <Text
                textStyle="bodyLight"
                whiteSpace="pre-line"
                fontStyle="italic"
                mt="24px"
              >
                {description}
              </Text>
            )}
            {author && <Text mt="24px">{author}</Text>}
          </StyledTextBox>
        </StyledCenter>
      </StyledWrapper>
    </Container>
  )
}

export default StorySection
