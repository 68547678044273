export default {
    getGtmId: (locale: string) => {
        const localisedGtmIds: Record<string, string | undefined> = {
            PL: process.env.NEXT_PUBLIC_GTM_ID_PL,
            DE: process.env.NEXT_PUBLIC_GTM_ID_DE,
            FR: process.env.NEXT_PUBLIC_GTM_ID_FR,
            EN: process.env.NEXT_PUBLIC_GTM_ID_EN,
        }
        return localisedGtmIds[locale.toUpperCase()] ?? localisedGtmIds.PL ?? ''
    }
}