import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function YoutubeIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <rect width="24" height="18" rx="4" transform="translate(4 7)" />
      <path d="m10 9 6.664 4L10 17z" transform="translate(3.329 2.996)"></path>
    </Icon>
  )
}
