import {Box, ImageProps} from '@chakra-ui/react'
import NextImage from 'next/legacy/image'
import ImageHelper from '../../../helpers/ImageHelper/ImageHelper'

export const ChakraNextImage = (props: ImageProps) => {
  const { src, alt, ...rest } = props
  return (
      <>
          {
              src ? (
                  <Box position="relative" {...rest}>
                      <NextImage
                          objectFit="cover"
                          layout="fill"
                          src={src}
                          alt={alt || ''}
                          loader={ImageHelper.cloudflareLoader}
                      />
                  </Box>
              ) : ''
          }
      </>

  )
}
