import styled from '@emotion/styled'
import {Box, Container, Flex, HStack} from '@chakra-ui/react'
import theme from 'theme'
import {Enum_Componentsectionsbanner_Alignment} from '_app/types/cms'
import {BANNER_ALIGNMENT} from './types'
import ElementsSizesEnum from '../../../enums/ElementsSizesEnum/ElementsSizesEnum'

export const StyledWrapper = styled(Flex)`
  align-items: center;
  @media (min-width: ${theme.breakpoints.md}) {
    position: absolute;
    inset: 0;
  }
`

export const StyledMainBox = styled(Box)<{ background: string }>`
  width: 100%;
  background: ${({ background }) => background};
  padding-inline-start: 0;
  padding-inline-end: 0;
  height: 100%;

  @media (min-width: ${theme.breakpoints.md}) {
    background: transparent;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: auto;
  }
`

export const StyledSecondaryBox = styled(Box)<{
  color: string;
  alignment: BANNER_ALIGNMENT | Enum_Componentsectionsbanner_Alignment;
}>`
  color: ${({ color }) => color};
  margin-left: 0;
  max-width: 100%;
  margin-top: 0;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-left: ${({ alignment }) =>
      alignment === BANNER_ALIGNMENT.LEFT ? 0 : '50%'};
    max-width: 70%;
    display: block;
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    margin-top: ${ElementsSizesEnum.NAVBAR_HEIGHT.lg};
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-top: 0;
    max-width: 50%;
  }
`

export const StyledContainer = styled(Container)`
  height: 100%;
  @media (min-width: ${theme.breakpoints.md}) {
    height: auto;
  }
`

export const StyledHStack = styled(HStack)`
  spacing: 0;
  align-items: unset;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.md}) {
    spacing: 2.5rem;
    align-items: center;
    flex-direction: row;
  }
`
