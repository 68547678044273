import DateHelper from '../../../helpers/DateHelper/DateHelper'

type Item = {
  order: number;
  text: string;
  url?: string;
  date_from: any;
  date_to: any;
};

export type TopBarItems = Item[];
type TopBarProps = (Item | undefined)[];

const filterFunction = <T>(item: T): item is NonNullable<T> => !!item

const filterBannerItems = (items?: TopBarProps): TopBarItems => {
  return (items || [])
    .filter(filterFunction)
    .filter(item => DateHelper.isBetween(item.date_from, item.date_to))
    .sort((a, b) => a.order - b.order)
}

export default filterBannerItems
