import {
  Modal,
  ModalBody,
  Button,
  ModalContent,
  Text,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react'
import { useReactiveVar } from '@apollo/client'

import { isObject, isString } from '@chakra-ui/utils'
import { useEffect } from 'react'
import { CompleteIcon } from '_app/components/Icons'
import { popupVar } from '_app/cache'
import { useRouter } from '_app/hooks/useRouter'
import { APP_ROUTES } from '_app/routes'

const SuccessPopup = () => {
  const {
    query: { popup: popupQs },
    push: routerPush,
    asPath,
  } = useRouter()

  useEffect(() => {
    if (popupQs === 'ls' && typeof window !== 'undefined') {
      let popupVarConfig = window.localStorage.getItem('popupVar')

      window.localStorage.removeItem('popupVar')

      if (isString(popupVarConfig)) {
        popupVarConfig = JSON.parse(popupVarConfig)
      }

      if (isObject(popupVarConfig)) {
        (async () => {
          await routerPush(APP_ROUTES.INDEX, undefined, { shallow: true })

          popupVar({
            additionalTxt: popupVarConfig?.additionalTxt,
            text: popupVarConfig?.text,
            succeedAction: popupVarConfig?.succeedAction,
            textAlign: popupVarConfig?.textAlign,
          })
        })()
      }
    }
  }, [asPath, popupQs, routerPush])

  const popup = useReactiveVar(popupVar)

  return (
    <Modal onClose={() => popupVar(null)} isOpen={!!popup} isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="0"
        overflowY="auto"
        margin="0"
        minW={{ base: '90%', sm: '16.875rem' }}
        w="auto"
      >
        <ModalBody padding={6}>
          <Flex
            flexDirection="column"
            alignItems={popup?.textAlign || 'center'}
          >
            {popup?.succeedAction && <CompleteIcon fontSize="4rem" />}
            <Text textAlign="center" fontWeight={600} fontSize="1.25rem">
              {popup?.text}
            </Text>
            {popup?.additionalTxt && <Text mt={5}>{popup.additionalTxt}</Text>}
            <Button mt="0.375rem" width="100%" onClick={() => popupVar(null)}>
              Zamknij
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SuccessPopup
