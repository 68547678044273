import { useBreakpointValue } from '@chakra-ui/react'
import useScrollPosition from '@react-hook/window-scroll'
import { useState, useEffect } from 'react'

type Options = {
  breakpoint: number;
  onlyMobile?: boolean;
};
export const useFixedPosition = ({
  breakpoint,
  onlyMobile = true,
}: Options) => {
  const runUseEffect = useBreakpointValue({ base: true, lg: !onlyMobile })
  const scrollY = useScrollPosition(15)
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    if (runUseEffect) {
      setIsFixed(scrollY >= breakpoint)
    }
  }, [scrollY, runUseEffect, breakpoint])

  return { isFixed }
}
