import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function LoggedInUserIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle
        data-name="Ellipse 5157"
        cx="4"
        cy="4"
        r="4"
        transform="translate(5 3)"
      />
      <path
        data-name="Path 13729"
        d="M3 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"
      />
      <path data-name="Path 13730" d="m16 11 2 2 4-4" />
    </Icon>
  )
}
