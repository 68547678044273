import {
  Box,
  Flex,
  HStack,
  ListItem,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { Fragment } from 'react'
import Image from 'next/legacy/image'
import { TFunction, useTranslation } from 'next-i18next'
import Modal from '_app/components/Modal'
import { AskMoreQuestions } from './AskMoreQuestions'

const getBraceletMeasurements = (t: TFunction) => [
  {
    text: t('MEASURE_WRIST_METHOD_1'),
    img: '/images/product/sposob_01.jpg',
  },
  {
    text: t('MEASURE_WRIST_METHOD_2'),
    img: '/images/product/sposob_02.jpg',
  },
]

type BraceletProps = {
  isOpen: boolean;
  onClose: () => void;
};

const braceletCircumferences = [
  {
    circumference: '15,7',
    size: '50',
  },
  {
    circumference: '18,8',
    size: '60',
  },
  {
    circumference: '20,4',
    size: '65',
  },
  {
    circumference: '22,0',
    size: '70',
  },
]

export const Bracelet = ({ isOpen, onClose }: BraceletProps) => {
  const { t } = useTranslation('product')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCloseButton isInfoSize>
      <Box height="100%">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          lineHeight={1.38}
          alignItems="center"
          textStyle="bodyLight"
          px={{ base: 0, lg: 10 }}
        >
          <Box textAlign="center" p={6} maxW={{ base: 'unset', md: '80%' }}>
            <Text textStyle="h3" my={8}>
              {t('HOW_TO_CHOOSE_BRACELET_SIZE')}
            </Text>
            <Text>{t('BRACELET_MEASURE_WRIST')}</Text>
            <Text>{t('MEASURE_WRIST_CIRCUMFERENCE')}</Text>

            <HStack spacing={10} justify="center" alignItems="flex-start" m={8}>
              <Text textStyle="h5">{t('PREPARE')}</Text>
              <UnorderedList>
                <ListItem>{t('CENTIMETER')}</ListItem>
                <ListItem>{t('STRING')}</ListItem>
                <ListItem>{t('FELT_TIP_PEN')}</ListItem>
              </UnorderedList>
            </HStack>

            <Text>{t('OUR_OFFER_INCLUDES_BRACELETS')}</Text>
          </Box>
          <Flex direction="column">
            {getBraceletMeasurements(t).map(({ text, img }, idx) => (
              <Fragment key={idx}>
                <HStack
                  display={{ base: 'none', md: 'flex' }}
                  maxHeight={230}
                  overflowY="hidden"
                >
                  <Box flex={1}>
                    <Image
                      width={800}
                      height={800}
                      src={img}
                      layout="responsive"
                      alt={`sposob_${idx}`}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text textStyle="h5" mb={4}>
                      {t(`Sposób ${idx + 1}:`)}
                    </Text>
                    <Text maxW="80%">{t(`${text}`)}</Text>
                  </Box>
                </HStack>

                <VStack display={{ base: 'flex', md: 'none' }} justify="center">
                  <Text textStyle="h5" my={4}>
                    {t(`Sposób ${idx + 1}:`)}
                  </Text>
                  <Flex align="center" maxH="100px" overflowY="hidden">
                    <Box flex={1} minW="250px" minH="250px">
                      <Image
                        width={800}
                        height={800}
                        src={img}
                        layout="responsive"
                        alt={`spoob_mobile${idx}`}
                      />
                    </Box>
                  </Flex>
                  <Box flex={1} textAlign="center" p={6}>
                    <Text>{t(`${text}`)}</Text>
                  </Box>
                </VStack>
              </Fragment>
            ))}
          </Flex>
          <Flex textStyle="bodyLight" flexWrap="wrap" height="100%" p={6}>
            <Box
              flex={{ base: '100%', lg: '50%' }}
              py={4}
              pr={{ base: 4, lg: 14 }}
              pl={{ base: 0, md: 4 }}
            >
              <Text textStyle="h5" mb={4} maxW="85%">
                {t('CIRCUMFERENCE_TABLE_BRACELETS')}
              </Text>
              <Table variant="simple" style={{ tableLayout: 'fixed' }}>
                <Tbody>
                  <Tr>
                    <Td border="none" p="10px 0">
                      {t('CIRCUMFERENCE')}
                    </Td>
                    <Td border="none" p="10px 0">
                      {t('LILOU_SIZE')}
                    </Td>
                  </Tr>
                  {braceletCircumferences.map((bc, idx) => (
                    <Tr key={idx}>
                      <Td border="none" p="5px 0">
                        {bc.circumference} cm
                      </Td>
                      <Td border="none" p="5px 0">
                        {bc.size} mm
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Box
              flex={{ base: '100%', lg: '50%' }}
              py={4}
              pl={0}
              pr={{ base: 4, lg: 14 }}
              lineHeight={1.38}
            >
              <Text textStyle="h5" mb={4}>
                {t('TIPS')}
              </Text>
              <Text pb={4}>{t('STRING_BRACELETS_FITTING_EXPLANATION')}</Text>
              <Text pb={4}>{t('IF_YOU_PREFER_CHAINS_CHOOSE_LARGER')}</Text>
              <Text>{t('REMEMBER_HOW_BANGLES_ARE_PUT_ON')}</Text>
            </Box>
          </Flex>
        </Flex>

        <AskMoreQuestions px={{ base: 6, lg: 20 }} />
      </Box>
    </Modal>
  )
}
