import {Box, BoxProps} from '@chakra-ui/react'
import React from 'react'
import {APP_ROUTES} from '_app/routes'

const Logo = (props: BoxProps) => {
  return (
      <Box
        cursor="pointer"
        as="svg"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 91.033 44"
        onClick={() => {
            window?.open(APP_ROUTES.INDEX, '_self')
        }}
        {...props}
      >
        <g transform="translate(-34 -223.64)">
          <path
            style={{
              fill: 'currentcolor',
              fillRule: 'evenodd',
            }}
            d="M44.46 223.64h.627a5.623 5.623 0 0 1 2.311.675c.567.318 1.419.772 1.489 1.508a1.2 1.2 0 0 1-1.293 1.388c-1.133-.078-.938-1.386-1.567-1.745a3.3 3.3 0 0 0-1.684-.2c-2.407.238-3.92 2.834-4.7 4.841a13.266 13.266 0 0 0-.47 8.174A8.952 8.952 0 0 0 43.6 243.4a13.336 13.336 0 0 0 7.914 1.309 58.886 58.886 0 0 1 4.035-10.276 18.879 18.879 0 0 1 2.822-4.325 6.8 6.8 0 0 1 4.9-2.182c3.707-.008 5.558 2.682 5.25 6.428a11.613 11.613 0 0 1-2.429 6.189c-2.611 3.264-6.915 4.627-11.754 5.356-1.6 3.785-2.67 8.114-4.428 11.744.77.99 1.49 1.667 2.115 2.38.675.77 1.387 1.6 2.116 2.341 1.326 1.343 3.155 3.141 5.6 3.334a5.681 5.681 0 0 0 5.681-3.254c1.259-2.256 1.962-6.772-.47-8.451-.948-.655-3.01-.814-3.879-.08-.442.373-.5 1.207-1.019 1.587a1.3 1.3 0 0 1-2.115-1.229c.122-.736 1.29-1.14 2.155-1.389 5.284-1.523 10.928.681 9.912 6.665a7.609 7.609 0 0 1-2.586 4.444c-2.267 1.954-6.711 3.7-10.617 2.143-3.262-1.305-5.375-3.976-7.875-6.19-1.911 3.58-4.054 6.924-8.737 7.7H38.9a5.41 5.41 0 0 1-4.9-4.8v-1.19a9.343 9.343 0 0 1 5.249-7.062c3.192-1.382 6.973-.038 8.894 1.428.1.08.269.277.431.2.985-3.286 1.817-6.728 2.742-10.077a19.254 19.254 0 0 1-9.8-2.063 11.639 11.639 0 0 1-5.877-6.23 12.234 12.234 0 0 1-.509-5.4 9.248 9.248 0 0 1 1.763-4.284 10.311 10.311 0 0 1 7.561-4.524zm18.805 16.109A9.181 9.181 0 0 0 65.7 235.7a6.756 6.756 0 0 0-.745-5.276 2.95 2.95 0 0 0-3.409-1.19c-1.352.568-2.184 2.1-2.86 3.49-1.645 3.382-2.737 7.82-3.8 11.546a15.777 15.777 0 0 0 8.384-4.523zm-18.374 17.1a9.082 9.082 0 0 0-3.526-1.11c-2.408-.164-3.654.821-4.388 2.261-1.637 3.209.717 7.042 3.644 7.3a5.86 5.86 0 0 0 4.858-2.143 12.177 12.177 0 0 0 2.115-4.365 8.032 8.032 0 0 0-2.7-1.945z"
          />
          <path
            style={{
              fill: 'currentcolor',
              fillRule: 'evenodd',
            }}
            d="M229.091 288.233a1.274 1.274 0 0 1 2.311.469 2.625 2.625 0 0 1-.548 1.45c-.749 1.249-1.836 2.986-3.448 3.33a3.239 3.239 0 0 1-2.272-.353c-1.355-.771-2.159-2.539-2.625-4.153a18.015 18.015 0 0 1-4.153 3.723 3.7 3.7 0 0 1-2.978.235c-1.875-.857-2.589-3.567-2.272-6.543a12.007 12.007 0 0 1 .9-3.605c.381-.867 1.137-2.257 2.194-2.272a1.341 1.341 0 0 1 1.136 2c-.223.551-.815.862-1.136 1.372a9.632 9.632 0 0 0-.587 6.934c.208.568.605 1.346 1.332 1.411a2.645 2.645 0 0 0 1.8-.94 14.492 14.492 0 0 0 2.39-3.1 6.529 6.529 0 0 0 .94-1.763 16.613 16.613 0 0 0-.079-2 72.538 72.538 0 0 1 1.175-13.2 44.552 44.552 0 0 1 1.607-6.621c.675-1.962 1.566-4.1 3.133-5.054a2.8 2.8 0 0 1 3.252.274c1.657 1.49 1.945 4.6 1.608 7.64a24.139 24.139 0 0 1-2.116 7.287c-1.73 3.765-3.826 7.056-5.877 10.578a7.316 7.316 0 0 0-.824 1.489 5.088 5.088 0 0 0 .706 2.9 4.19 4.19 0 0 0 1.724 1.842c2.058 1.079 3.327-.99 2.7-3.33zm-.039-26.172c-2.44 2.126-3.675 5.5-4.545 9.207a42.006 42.006 0 0 0-1.019 12.5 60.545 60.545 0 0 0 4.781-9.716c1.185-3.156 2.729-8.321.939-12.029 0-.023-.006-.045-.039-.038a.265.265 0 0 0-.117.078z"
            transform="translate(-142.121 -27.644)"
          />
          <path
            style={{
              fill: 'currentcolor',
              fillRule: 'evenodd',
            }}
            d="M226.595 337.265a1.35 1.35 0 0 1 1.528.979c.41 2.083-3.325 2.113-2.9 0a1.336 1.336 0 0 1 1.371-.979z"
            transform="translate(-151.771 -89.88)"
          />
          <path
            style={{
              fill: 'currentcolor',
              fillRule: 'evenodd',
            }}
            d="M330.048 369.591c-1.381 1.825-5.438 2.343-7.64.979-1.7-1.054-2.228-3.324-1.841-6.033a9.279 9.279 0 0 1-3.487 1.294c-.991 2.575-2.947 5.2-6.308 5.445a5.057 5.057 0 0 1-5.8-5.249 9.35 9.35 0 0 1 2.194-5.838 6.428 6.428 0 0 1 5.485-2.509c2.984.166 5.174 2.339 4.936 5.877-.028.42-.128.7-.2 1.255 2.382-.738 3.609-2.719 4.349-5.172a4.885 4.885 0 0 1 .313-1.019c.662-1.1 2.3-.382 2.351.784a7.54 7.54 0 0 1-.744 2.35c-.688 2.161-.966 6.108.274 7.758a2.021 2.021 0 0 0 2.391.744c1.638-.612 2.574-2.645 2.938-4.858a9.65 9.65 0 0 0-.509-5.6c-.2-.376-.733-.629-.823-1.136a1.249 1.249 0 0 1 1.174-1.489 3.27 3.27 0 0 1 3.135 1.8 3 3 0 0 1 .157.784 12.875 12.875 0 0 1-.745 4.153 16.4 16.4 0 0 0-.783 4.114 2.234 2.234 0 0 0 2.9 2.391 5.5 5.5 0 0 0 3.879-3.252c.233-.586.322-1.472.782-1.567a1.665 1.665 0 0 1 1.686 1.8c-.09.475-.755.9-1.215 1.255-1.328 1.027-3.114 2.5-5.054 2.664a4.184 4.184 0 0 1-3.8-1.724zm-17-11.049a3.614 3.614 0 0 0-1.254.354c.072 2.788.827 4.894 2.7 5.877.729-1.919 1.22-6.244-1.451-6.23zm-5.25 9.169a2.915 2.915 0 0 0 1.411 2.546 2.769 2.769 0 0 0 2.664-.744 9.946 9.946 0 0 0 2.35-3.957 7.837 7.837 0 0 1-4.506-4.7 12.091 12.091 0 0 0-1.919 6.856z"
            transform="translate(-215.105 -105.438)"
          />
        </g>
      </Box>
  )
}

export default Logo
