import { useEffect, FC, useRef, useCallback, useState } from 'react'
import {
  FormControl,
  FormLabel,
  Textarea as ChakraUITextarea,
  TextareaProps as ChakraUITextareaProps,
} from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from 'lodash-es'

import FormErrorMessage from '_app/components/Form/FormErrorMessage'

interface TextareaProps extends ChakraUITextareaProps {
  label?: string;
  name: string;
}

const FormTextarea: FC<TextareaProps> = ({
  label,
  name,
  isRequired,
  hidden,
  isDisabled,
  mb = 4,
  ...props
}) => {
  const {
    control,
    formState: { errors },
    unregister,
  } = useFormContext()

  const errorMessage: any = get(errors, name)?.message

  const textareaElement = useRef<HTMLTextAreaElement>(null)
  const [textareaLength, setTextareaLength] = useState(
    (props.value
      ? props.value
      : props.defaultValue
      ? props.defaultValue
      : ''
    ).toString().length
  )

  const autoHeight = useCallback(
    (element: EventTarget & HTMLTextAreaElement) => {
      if (element?.value?.length < textareaLength) {
        element.style.minHeight = 'unset'
        element.style.overflowY = 'unset'
      }
      if (element) {
        element.style.minHeight = `${element.scrollHeight}px`
        element.style.overflowY = 'hidden'
        setTextareaLength(element?.value?.length)
      }
    },
    [textareaLength]
  )

  useEffect(() => {
    if (textareaElement?.current) {
      autoHeight(textareaElement.current)
    }
  }, [textareaElement, autoHeight, props.defaultValue])

  useEffect(() => {
    return () => unregister(name)
  }, [unregister, name])

  return (
    <FormControl
      isRequired={isRequired && !hidden && !isDisabled}
      isInvalid={!!errorMessage}
      mb={mb}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <ChakraUITextarea
            ref={textareaElement}
            resize="none"
            focusBorderColor="none"
            fontSize="16px"
            borderRadius="0"
            borderTop="none"
            borderRight="none"
            borderLeft="none"
            boxShadow="none"
            borderBottom="solid 1px #707070"
            _hover={{ borderBottom: 'solid 1px #707070' }}
            _placeholder={{ color: 'black', opacity: 0.4 }}
            px="0"
            pt="4px"
            pb="8px"
            minH="none"
            {...props}
            value={value}
            onChange={e => {
              autoHeight(e.target)
              onChange(e)
            }}
            _invalid={{ borderColor: 'red', boxShadow: 'none' }}
          />
        )}
      />

      <FormErrorMessage name={label}>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormTextarea
