const AttributesEnum = {
    CUSTOM_ATTRIBUTES: [
        'rozmiar_swatch',
        'material_swatch',
        'rodzaj_kamienia_swatch',
        'kolor3_swatch',
        'kolor_emalii_swatch',
        'kolor_kamienia_swatch',
        'cyfry_swatch',
        'alfabet_swatch',
        'znaki_zodiaku_swatch',
    ],
}

const FILTERS_ATTRIBUTE_CODES = [...AttributesEnum.CUSTOM_ATTRIBUTES, 'price']

export default {
    ...AttributesEnum,
    FILTERS_ATTRIBUTE_CODES
}