export default {
    get: (name: string) => {
        let data: string = ''
        if (typeof document !== 'undefined' && document?.cookie) {
            const nameTemp = name + '='
            const decodeCookie = decodeURIComponent(document.cookie)
            const ca = decodeCookie.split(';')
            ca.forEach((el) => {
                const c = el.trim()
                if (c.indexOf(nameTemp) === 0) {
                    data = c.substring(nameTemp.length, c.length)
                }
            })
        }
        return data
    },
    set: (key: string, value: string, days: number = 1, path: string = '/', secure: boolean = true, sameSite: 'Lax' | 'Strict' | 'None' = 'None') => {
        if (typeof document !== 'undefined') {
            let expires = ''
            if (days) {
                const date = new Date()
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
                expires = '; expires=' + date.toUTCString()
            }
            secure = true
            document.cookie = `${key}=${value || ''}${expires}; path=${path}; ${secure ? 'Secure;' : ''} SameSite=${sameSite}`
        }
    },
    remove: (key: string) => {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    },
    clear: () => {
        document.cookie.split(';').forEach(cookie => {
            const eqPos = cookie.indexOf('=')
            const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim()
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`
            const domainParts = window.location.hostname.split('.')
            if (domainParts.length > 1) {
                const rootDomain = domainParts.slice(-2).join('.')
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${rootDomain}`
            }
        })
    }
}