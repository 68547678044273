import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import theme from 'theme'

export const StyledFlex = styled(Flex)`
  gap: 1.5rem;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.lg}) {
    gap: 9.375rem;
    flex-direction: row;
  }
`

export const StyledImageBox = styled(Box)<{
  mobile: boolean | undefined;
  imageMobile: boolean;
}>`
  ${({ mobile, imageMobile }) =>
    !mobile
      ? `
          padding: 1.865rem;
          display: ${imageMobile ? 'none' : 'block'};
          position: relative;

          @media (min-width: ${theme.breakpoints.lg}) {
            padding: 0;
            display: block;
          }
        `
      : `
          padding: 1.865rem;
          display: 'block';

          @media (min-width: ${theme.breakpoints.lg}) {
            display: none;
          }
        `}
`
