import {
  Box,
  Container,
  Flex,
  HStack,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react'
import React from 'react'

import { ComponentNavigationTopMenu } from '_app/graphql/cms/generated/graphql'
import ImageCms from '_app/components/Content/ImageCms'
import CmsLink from '_app/components/Link/CmsLink'
import CtaButton from '_app/components/Buttons/CtaButton'
import LinkButton from '_app/components/Buttons/LinkButton'
import { useRouter } from 'next/router'
import URLHelper from 'helpers/URLHelper/URLHelper'

interface NavBarDropDownProps {
  id: string | number;
  columns: ComponentNavigationTopMenu['columns'];
  image?: any;
}

const NavBarDropDown = ({ id, columns, image }: NavBarDropDownProps) => {
  const imgAtr = image?.img?.data?.attributes
  const { locale } = useRouter()
  return (
    <>
      {columns && columns.length > 0 ? (
        <Box
          position="absolute"
          display="none"
          width="100vw"
          top="100%"
          left={0}
          cursor="default"
          bg="transparent"
          _groupHover={{
            display: 'flex',
            borderY: '1px solid LightGray',
            bg: 'white',
          }}
        >
          <Container py={12}>
            <Flex justify="space-between" pr={5}>
              <HStack spacing="70px" align="flex-start" mr="4rem" className="dropdownMenu">
                {columns?.map(column => (
                  <List key={`${id}-${column?.id}`}>
                    <ListItem>
                      <Text fontWeight="medium">{column?.title}</Text>
                    </ListItem>
                    {column?.items &&
                      column.items.map((item: any) => {
                        const itemTemp: any = { ...item }
                        itemTemp.addLocale = true
                        itemTemp.locale = locale
                        return (
                            <ListItem my={4} key={item?.id} maxWidth="12rem">
                              <LinkButton
                                  href={item ? URLHelper.createURL(itemTemp) : '/'}
                                  fontSize="1rem"
                                  fontWeight="light"
                                  sx={{
                                    color: item?.text?.toLowerCase() === 'special prices' ? 'red' : 'black'
                                  }}
                                  reverse
                              >
                                {item?.text}
                              </LinkButton>
                            </ListItem>
                        )
                      })}
                  </List>
                ))}
              </HStack>
              {imgAtr && (
                <a
                  href={image?.link || '#'}
                  style={{
                    width: '100%',
                    maxWidth: '28.125rem',
                    position: 'relative',
                  }}
                >
                  <ImageCms src={''} attributes={imgAtr} layout="responsive" alt={''} />
                  {image.cta && (
                    <Box
                      position="absolute"
                      top={386}
                      left={0}
                      right={0}
                      textAlign="center"
                      display="inline"
                    >
                      <CtaButton cta={image.cta} />
                    </Box>
                  )}
                </a>
              )}
            </Flex>
          </Container>
        </Box>
      ) : null}
    </>
  )
}

export default NavBarDropDown
