export const generateRangeStyles = (extend: number, orientation?: string) => {
  const textPosition =
    orientation === 'vertical'
      ? {
          left: '1.25rem',
        }
      : {
          top: '0.625rem',
        }

  const sliderProps =
    orientation === 'vertical'
      ? {
          isReversed: true,
          my: `${extend}px`,
          _before: {
            content: "''",
            position: 'absolute',
            top: `-${extend}px`,
            left: '50%',
            width: '1px',
            bg: 'gray.200',
            height: `calc(100% + ${2 * extend}px)`,
            transform: 'translateX(-50%)',
          },
        }
      : {
          mx: `${extend}px`,
          _before: {
            content: "''",
            position: 'absolute',
            left: `-${extend}px`,
            top: '50%',
            height: '1px',
            bg: 'gray.200',
            width: `calc(100% + ${2 * extend}px)`,
            transform: 'translateY(-50%)',
          },
        }

  return { textPosition, sliderProps }
}
