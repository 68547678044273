import { Flex } from '@chakra-ui/react'
import { BundleCartItem, SimpleCartItem } from '_app/graphql/generated/graphql'
import PriceText from './PriceText'

interface SpecialPriceProps {
  isGift: boolean;
  item: SimpleCartItem | BundleCartItem;
  isPanel?: boolean;
}

const SpecialPrice = ({ isGift, item, isPanel = false }: SpecialPriceProps) => {
  if (isGift) {
    return null
  }

  const price =
    item.prices?.price ?? item.product.price_range.minimum_price.final_price

  return (
    <Flex>
      <PriceText
        amount={price}
        floating
        minimumFractionDigits={2}
        mt={{ base: '0.25rem', md: 0 }}
        position={{ base: isPanel ? 'relative' : 'absolute', md: 'relative' }}
        bottom={0}
        right={0}
        top={{ md: 0 }}
      />
    </Flex>
  )
}

export default SpecialPrice
