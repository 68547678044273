import React, { useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { FormControl, Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import { get } from 'lodash-es'
import FormErrorMessage from '_app/components/Form/FormErrorMessage'
import { BaseInputProps } from '_app/types/form'

const Checkbox = ({
  label,
  name,
  isRequired,
  children,
  checked,
  isDisabled = false,
  onChange,
  ...props
}: BaseInputProps) => {
  const {
    control,
    unregister,
    formState: { errors },
  } = useFormContext()

  const errorMessage: any = get(errors, name)?.message

  useEffect(() => {
    return () => {
      unregister(name)
    }
  }, [unregister, name])

  return (
    <FormControl
      isInvalid={!!errorMessage}
      mb={4}
      width="auto"
      isRequired={isRequired}
      onChange={onChange}
      {...props}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={checked}
        render={({ field: { onChange, value, ref } }) => (
          <ChakraCheckbox
            onChange={onChange}
            ref={ref}
            isChecked={value}
            data-testid={name}
            required={false}
            isDisabled={isDisabled}
          >
            {label}
            {children}
            {isRequired && '*'}
          </ChakraCheckbox>
        )}
      />
      <FormErrorMessage name={name}>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default Checkbox
