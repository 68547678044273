import { Maybe } from '_app/types/cms'
import BannerGridDesktop from './BannerGridDesktop'
import BannerGridMobile from './BannerGridMobile'
import { GridContentProps } from './GridContent'
import { useBreakpoint } from '@chakra-ui/react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

interface BannerGridProps {
  items?: Maybe<GridContentProps>[];
  id?: string;
}

const ITEM_PREFIX = 'kafelek_'
export const generateItemId = (
  id: BannerGridProps['id'],
  index: number,
  isMobile = false
) => {
  if (!id) {
    return undefined
  }

  return `${ITEM_PREFIX}${id}_${index + 1}${isMobile ? '-mobile' : ''}`
}

const BannerGrid = ({ items = [], id, firstSmall }: any) => {
  const filteredItems = items.filter((item): item is GridContentProps => !!item)
  return (
    <>
      {
        BreakpointsHelper.isMobile() ? (
            <BannerGridMobile items={filteredItems} id={id} />
        ) : (
            <BannerGridDesktop items={filteredItems} id={id} />
        )
      }
    </>
  )
}

export default BannerGrid
