import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function CheckIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 10 10" {...props}>
      <path d="M5,10l3,3,6-6" transform="translate(-4.222 -6.222)" />
    </Icon>
  )
}
