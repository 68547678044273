import {parseCookies} from 'nookies'
import {useEffect, useState} from 'react'
import {useReactiveVar} from '@apollo/client'
import {useMutation} from '_app/hooks/useMutation'
import AddProductsToWishlist from '_app/graphql/mutations/wishlist/AddProductsToWishlist'
import RemoveProductsFromWishlist from '_app/graphql/mutations/wishlist/RemoveProductsFromWishlist'
import useCustomer from '_app/hooks/useCustomer'
import {wishlistArrayVar} from '_app/cache'
import {WishlistItemInterface} from '_app/types/api'
import {GetCustomerDocument} from '_app/graphql/generated/graphql'
import {GTM_EVENTS} from '../utils/dataLayer'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'

interface UseAddToFavorites {
    addToFavorites(sku: string, item?: any): void;

    addProductsToWishlist({
                              variables,
                          }: {
        variables: {
            wishlistId: string;
            wishlistItems: { quantity: number; sku: string }[];
        };
        refetchQueries: string[];
    }): void;

    removeFromFavorites(id: number): void;

    removeFromFavoritesBySku(url_key: string): void;

    isLoading: boolean;

    checkIsFavorite(sku: string): boolean;
}

const useManageFavorites = (): UseAddToFavorites => {
    const {wishlistId, wishlist} = parseCookies()
    const wishlistItems = useReactiveVar(wishlistArrayVar)
    const {customer} = useCustomer()
    const [favoritesSkus, setFavoritesSkus] = useState<string[]>([])
    const {mutate: addProductsToWishlist, loading: addLoading} = useMutation({
        mutation: AddProductsToWishlist,
    })
    const {mutate: removeProductsFromWishlist, loading: removeLoading} = useMutation({
        mutation: RemoveProductsFromWishlist,
    })
    const removeFromGuestWishlist = async (sku: string) => {
        if (wishlist) {
            const guestFavorites = [...(wishlistItems ?? [])].filter(
                (item: string) => item !== sku
            )
            CookiesHelper.set('wishlist', JSON.stringify(guestFavorites))
            wishlistArrayVar([
                ...(wishlistArrayVar()?.filter(item => item !== sku) ?? []),
            ])
        }
    }
    const addToFavorites = (sku: string, item?: any) => {
        if (!removeLoading) {
            const wishListItems: any = CookiesHelper.get('wishlist') && JSON.parse(CookiesHelper.get('wishlist')) || []
            wishListItems.push({ quantity: 1, sku })
            CookiesHelper.set('wishlist', JSON.stringify(wishListItems))
            if (customer) {
                const wishListId = customer?.wishlists?.[0]?.id
                addProductsToWishlist({
                    variables: {
                        wishlistId: wishListId,
                        wishlistItems: wishListItems,
                    },
                    optimisticResponse: {
                        id: 'temporary',
                        __typename: 'Mutation',
                        addProductsToWishlist: {
                            wishlist: {
                                id: 'temporary',
                            },
                        },
                    },
                    update: (cache: any) => {
                        const previousData = cache.readQuery({
                            query: GetCustomerDocument,
                        })
                        cache.writeQuery({
                            query: GetCustomerDocument,
                            data: {
                                customer: {
                                    ...previousData.customer,
                                    wishlists: [
                                        {
                                            ...previousData.customer.wishlists[0],
                                            items_v2: {
                                                items: [
                                                    ...previousData.customer.wishlists[0].items_v2
                                                        .items,
                                                    {
                                                        id: 123000,
                                                        product: item,
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        })
                    },
                    refetchQueries: [GetCustomerDocument],
                    awaitRefetchQueries: true,
                })
            }
            GTM_EVENTS.addToWishlist({
                wishlistItems: wishListItems,
            })
        }
    }
    const removeFromFavorites = (id: number) => {
        if (!addLoading) {
            wishlistId
                ? removeProductsFromWishlist({
                    variables: {wishlistId, wishlistItemsIds: [id]},
                    optimisticResponse: {
                        id: '123456',
                        __typename: 'Mutation',
                        removeProductsFromWishlist: {
                            wishlist: {
                                id: '123456',
                            },
                        },
                    },
                    update: (cache: any) => {
                        const previousData = cache.readQuery({
                            query: GetCustomerDocument,
                        })
                        cache.writeQuery({
                            query: GetCustomerDocument,
                            data: {
                                customer: {
                                    ...previousData?.customer,
                                    wishlists: [
                                        {
                                            ...previousData?.customer?.wishlists[0],
                                            items_v2: {
                                                items:
                                                    previousData?.customer?.wishlists[0].items_v2.items.filter(
                                                        (item: WishlistItemInterface) =>
                                                            Number(item.id) !== id
                                                    ),
                                            },
                                        },
                                    ],
                                },
                            },
                        })
                    },
                    refetchQueries: [GetCustomerDocument],
                    awaitRefetchQueries: true,
                })
                : removeFromGuestWishlist(id.toString())
        }
    }
    const removeFromFavoritesBySku = async (sku: string) => {
        if (!addLoading) {
            if (!!wishlistId && customer) {
                const id = Number(customer?.wishlists?.[0]?.items_v2?.items.find(item => {
                    if (item?.product?.sku === sku) {
                        return item?.product?.sku === sku
                    }
                })?.id)
                if ([id]) {
                    await removeProductsFromWishlist({
                        variables: {wishlistId, wishlistItemsIds: [id]},
                        optimisticResponse: {
                            id: '123456',
                            __typename: 'Mutation',
                            removeProductsFromWishlist: {
                                wishlist: {
                                    id: 'id',
                                },
                            },
                        },
                        update: (cache: any) => {
                            const previousData = cache.readQuery({
                                query: GetCustomerDocument,
                            })
                            cache.writeQuery({
                                query: GetCustomerDocument,
                                data: {
                                    customer: {
                                        ...previousData.customer,
                                        wishlists: [
                                            {
                                                ...previousData.customer.wishlists[0],
                                                items_v2: {
                                                    items:
                                                        previousData.customer.wishlists[0].items_v2.items.filter(
                                                            (item: WishlistItemInterface) =>
                                                                Number(item.id) !== id
                                                        ),
                                                },
                                            },
                                        ],
                                    },
                                },
                            })
                        },
                        refetchQueries: [GetCustomerDocument],
                        awaitRefetchQueries: true,
                    })
                    const newFavourites = [...(JSON.parse(CookiesHelper.get('wishlist')) ?? [])].filter(
                        (item: any) => item?.sku !== sku
                    )
                    CookiesHelper.set('wishlist', JSON.stringify(newFavourites))
                }
            } else removeFromGuestWishlist(sku)
        }
    }
    useEffect(() => {
        if (customer?.wishlists?.[0]?.items_v2?.items) {
            setFavoritesSkus(customer.wishlists[0].items_v2.items.map(item => item?.product?.sku ?? ''))
        } else setFavoritesSkus([])
    }, [customer?.wishlists?.[0]?.items_v2?.items])

    const checkIsFavorite = (sku: string) => {
        const getWishList: any = CookiesHelper.get('wishlist') || '[]'
        const wishListArray = JSON.parse(getWishList)
        if (!wishListArray) {
            return false
        }
        return wishListArray.some((item: { sku: string }) => item.sku === sku)
    }

    return {
        addToFavorites,
        addProductsToWishlist,
        removeFromFavorites,
        removeFromFavoritesBySku,
        isLoading: false,
        checkIsFavorite,
    }
}

export default useManageFavorites
