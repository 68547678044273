const localisedLinks: Record<string, string> = {
  PL: process.env.NEXT_PUBLIC_CLIENT_URL_PL || '/',
  DE: process.env.NEXT_PUBLIC_CLIENT_URL_DE || '/',
  FR: process.env.NEXT_PUBLIC_CLIENT_URL_FR || '/',
  EU: process.env.NEXT_PUBLIC_CLIENT_URL_EN || '/',
}

const getDomain = (locale?: string) => {
  if (!locale) return localisedLinks.PL

  return localisedLinks[locale.toUpperCase()]
}

export default getDomain
