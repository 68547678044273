import {Text} from '@chakra-ui/react'
import {useRouter} from '_app/hooks/useRouter'
import URLHelper from '../../../helpers/URLHelper/URLHelper'

const LinkText = (props: any) => {
  const { locale } = useRouter()
  const { href } = props
  const translatedHref = URLHelper.translateHref(href, locale)
  return (
    <Text
      href={translatedHref}
      as="a"
      textDecoration="underline"
      _hover={{
        color: 'dark.50',
      }}
      {...props.styles}
    >
      {props.children}
    </Text>
  )
}

export default LinkText
