import {InMemoryCache, makeVar} from '@apollo/client'
import {parseCookies} from 'nookies'
import CustomerHelper from '../helpers/CustomerHelper/CustomerHelper'

export const initCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          cartId: {
            read() {
              return cartIdVar()
            },
          },
          isAuthenticated: {
            read() {
              return isAuthenticatedVar()
            },
          },
        },
      },
      Customer: {
        fields: {
          token: {
            read() {
              return customerTokenVar()
            },
          },
        },
      },
    },
  })
}

export const emailOnCart = makeVar<string | null>(null)
export const cartIdVar = makeVar<string | null>(null)

export const isCartOpenVar = makeVar<boolean>(false)
export const cart = makeVar<any>(null)

export const wishlistIdVar = makeVar<string | null>(
  typeof window !== 'undefined' ? parseCookies().wishlistId : null
)

export const wishlistArrayVar = makeVar<string[] | null>(
  typeof window !== 'undefined'
    ? JSON.parse(parseCookies()?.wishlist ?? '[]')
    : null
)

export const customerTokenVar = makeVar<string | null>(
  typeof window !== 'undefined' ? parseCookies().customerToken : null
)

export const isAuthenticatedVar = makeVar<boolean>(!!CustomerHelper.getCustomerToken())

export const popupVar = makeVar<{
  text: string;
  succeedAction: boolean;
  additionalTxt?: string;
  textAlign?: string;
} | null>(null)
