import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  FormControl,
  FormLabel,
  Text,
  RangeSliderProps,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import { get } from 'lodash-es'
import { FC } from 'react'

import FormErrorMessage from '_app/components/Form/FormErrorMessage'
import { BaseInputProps } from '_app/types/form'
import { useRouter } from '_app/hooks/useRouter'
import getPrice from '_app/utils/localized/getPrice'
import { generateRangeStyles } from './helpers'

interface RangeProps {
  extend?: number;
}

const Range: FC<BaseInputProps & RangeSliderProps & RangeProps> = ({
  label,
  name,
  defaultValue = [10, 90],
  isRequired,
  hidden,
  isDisabled,
  extend = 0,
  minStepsBetweenThumbs,
  ...props
}) => {
  const { formState: { errors }, control, watch} = useFormContext()
  const { locale } = useRouter()
  const errorMessage = get(errors, name)?.message
  const [min, max] = watch(name) || []
  const { textPosition, sliderProps } = generateRangeStyles(extend, props.orientation)
  return (
    <FormControl
      isInvalid={!!errorMessage}
      mb={4}
      isRequired={isRequired && !hidden && !isDisabled}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <RangeSlider
            value={value}
            onChange={onChange}
            position="relative"
            minStepsBetweenThumbs={minStepsBetweenThumbs}
            {...sliderProps}
            {...props}
          >
            <RangeSliderTrack height="1px" width="1px">
              <RangeSliderFilledTrack height="1.5px" bg="black" />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} borderColor="input">
              <Text
                as="span"
                position="absolute"
                {...textPosition}
                fontWeight={300}
                lineHeight={1.88}
                whiteSpace="nowrap"
              >
                {getPrice(locale, min as number)}
              </Text>
            </RangeSliderThumb>
            <RangeSliderThumb index={1} borderColor="input">
              <Text
                as="span"
                position="absolute"
                {...textPosition}
                fontWeight={300}
                lineHeight={1.88}
                whiteSpace="nowrap"
              >
                {getPrice(locale, max as number)}
              </Text>
            </RangeSliderThumb>
          </RangeSlider>
        )}
      />
      <FormErrorMessage name={label}>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default Range
