import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { get } from 'lodash-es'
import FormErrorMessage from '_app/components/Form/FormErrorMessage'
import StyledSelect from '_app/components/Form/StyledSelect'

const Select: any = ({
  label,
  name,
  options = [],
  placeholder,
  isRequired,
  isMulti,
  setActive,
  disabled,
  openMenuOnFocus,
  autoFocus,
  hidden,
  defaultValue,
  isImages,
  id,
}) => {
  const {
    formState,
    control,
    unregister,
    setValue,
  } = useFormContext()
  const errorMessage: any = get(formState?.errors, name)?.message
  useEffect(() => {
    return () => {
      unregister(name)
    }
  }, [name])
  useEffect(() => {
    if (id) {
      setTimeout(() => {
        setValue(name, id)
      }, 1000)
    }
  }, [id])
  return (
    <FormControl
      hidden={hidden}
      isInvalid={!!errorMessage}
      mb={4}
      isRequired={isRequired}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <StyledSelect
            placeholder={placeholder ?? ''}
            options={options}
            inputId={name}
            value={options.find((option: any) => option?.value === value)}
            defaultValue={options.find((option: any) => option?.value === defaultValue)}
            onChange={(val: any) => {
              if (val?.value) {
                onChange(val?.value)
                setActive && setActive(val)
              }
            }}
            isMulti={isMulti}
            instanceId={name}
            isDisabled={disabled}
            openMenuOnFocus={openMenuOnFocus}
            autoFocus={autoFocus}
            isImages={isImages}
          />
        )}
      />
       <FormErrorMessage name={name}>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default Select
