import { Box } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import GridContent from './GridContent'
import { BannerGridProps } from './types'
import { generateItemId } from '.'

const BannerGridMobile = ({ items = [], id }: BannerGridProps) => {
  const [mainItem, ...restItems] = items

  return (
    <Box
      display={{
        base: 'block',
        lg: 'none',
      }}
    >
      <Box mb="1.875rem">
        <GridContent
          {...mainItem}
          pr={{ base: 4, md: 'unset' }}
          sizes="75vw"
          analyticsId={generateItemId(id, 0, true)}
        />
      </Box>
      <Swiper slidesPerView={1.5} spaceBetween={30}>
        {restItems.map((item, index) => (
          <SwiperSlide key={`item-slide-[${index}]`}>
            <GridContent
              {...item}
              sizes="50vw"
              analyticsId={generateItemId(id, index + 1, true)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default BannerGridMobile
