import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function CopyIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g transform="translate(.385)">
        <rect width="12" height="12" rx="2" transform="translate(8.115 8.5)" />
        <path d="M16.115 8.5v-2a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v8c0 1.1-.1 2 1 2h3" />
      </g>
    </Icon>
  )
}
