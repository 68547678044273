import {Maybe} from 'graphql/jsutils/Maybe'
import {last} from 'lodash-es'
import {AttributeMetadata} from '_app/hooks/useAttributesMetadata'
import {APP_ROUTES} from '_app/routes'
import {BundleProduct, CurrencyEnum, GetCartQuery} from '_app/types/api'
import {
    CartProduct,
    CheckoutStepPayload,
    Events,
    PageType,
    PromotionClickPayload,
    PromotionPayload,
    ScrollPayload,
    VisitorPayload,
} from '_app/types/dataLayer'
import {Engraving} from '../../modules/configurator/Provider'
import {engravingFileName} from '../../modules/product/utils/engraveOptionName'
import {Item} from './edrone'
import ProductHelper from '../../helpers/ProductHelper/ProductHelper'

const sendEvent = (payload: any) => {
    if (process.env.NEXT_PUBLIC_DATALAYER === 'true') {
        console.info(payload)
    }
    if (typeof window !== 'undefined') {
        if (window?.dataLayer) {
            payload && window?.dataLayer?.push(payload)
        } else {
            const interval = setInterval(() => {
                if (window?.dataLayer) {
                    payload && window?.dataLayer?.push(payload)
                    clearInterval(interval)
                }
            }, 500)
            return () => clearInterval(interval)
        }
    }
}

export const getPageType: (pathname: string) => PageType = pathname => {
    switch (pathname) {
        case APP_ROUTES.INDEX:
            return PageType.MainPage
        case APP_ROUTES.LOGIN:
            return PageType.Login
        case APP_ROUTES.INFORMATION:
            return PageType.InspirationInformation
        case APP_ROUTES.INSPIRATION:
            return PageType.InspirationInformation
        case APP_ROUTES.SIGN_UP:
            return PageType.Register
        case APP_ROUTES.PROFILE.DEFAULT:
            return PageType.Profile
        case APP_ROUTES.CART:
            return PageType.Cart
        case APP_ROUTES.BLOG:
            return PageType.Blog
        case APP_ROUTES.CHECKOUT:
            return PageType.Checkout
        case APP_ROUTES.CHECKOUT_SUCCESS:
            return PageType.Confirmation
        case '/shop/[...slug]':
            return PageType.Category
        case '/[url_key]':
            return PageType.ProductPage
        default:
            return PageType.Other
    }
}
export const GTM_EVENTS = {
    visitor: (payload: VisitorPayload) => sendEvent({event: Events.PageView, ...payload}),
    productDetailView: (payload: any) => sendEvent({event: Events.ProductDetailView, ...payload}),
    productClick: (payload: any) => sendEvent({event: Events.ProductClick, ...payload}),
    addToCart: (payload: any) => sendEvent({event: Events.AddToCart, ...payload}),
    addToWishlist: (payload: any) => sendEvent({event: Events.AddToWishlist, ...payload}),
    cart: (payload: CheckoutStepPayload<1>) => sendEvent({event: Events.Checkout, ...payload}),
    deliveryMethod: (payload: CheckoutStepPayload<2>) => sendEvent({event: Events.Checkout, ...payload}),
    paymentMethod: (payload: CheckoutStepPayload<3>) => sendEvent({event: Events.PaymentMethod, ...payload}),
    purchase: (payload: any) => sendEvent({event: Events.Purchase, ...payload}),
    payment: (payload: any) => sendEvent({event: Events.Payment, ...payload}),
    personalizeProduct: () => sendEvent({event: Events.PersonalizeProduct}),
    configureProduct: () => sendEvent({event: Events.ConfigureProducts}),
    newsletter: () => sendEvent({event: Events.Newsletter, eventLabel: 'sign in'}),
    registerAccount: () => sendEvent({event: Events.RegisterAccount}),
    promotionView: (payload: PromotionPayload) => sendEvent({event: Events.PromotionView, ...payload}),
    promotionClick: (payload: PromotionClickPayload) => sendEvent({event: Events.PromotionClick, ...payload}),
    scroll: (payload: ScrollPayload) => sendEvent({event: Events.Scroll, ...payload}),
    impressionView: (payload: any) => sendEvent({event: Events.ImpressionView, ...payload}),
    algoliaSessionChange: (userToken: string) => sendEvent({algoliaUserToken: userToken}),
    algoliaHitsViewed: () => sendEvent({event: Events.HitsViewed}),
    errors: (payload: any) => sendEvent({event: Events.Errors, ...payload}),
    search: (value: any, count: any, ids: any, products: any) => sendEvent({
        event: Events.Search,
        ecommerce: {
            searchTerm: value,
            searchResults: count,
            searchCount: ids,
            products: products,
        },
    }),
}

export const getMaterialSwatchLabel = (
    metadata: AttributeMetadata,
    id: string
) => {
    return (
        metadata.material_swatch?.find(({value}) => value === id)?.label || ''
    )
}

export const getBrand = (item?: Item) =>
    item?.product && 'items' in item.product
        ? item?.product?.items?.map(element => element?.options?.[0]?.product?.sku).filter(Boolean).join('|')
        : item?.product?.sku

export const getCategory = (product?: BundleProduct) =>
    product?.main_category?.[0]?.name || last(product?.categories)?.name || ''

export const getCurrencyCode = (product?: Partial<BundleProduct>) =>
    product?.price_range?.minimum_price?.final_price?.currency ||
    CurrencyEnum?.Pln

export const parseProductsToGTM = (
    data: GetCartQuery | undefined,
    metadata: AttributeMetadata,
    gtmItemListNames: Record<string, string>
) => {
    return (data?.cart?.items || [])
        .map((item: any) => {
                const infoResponseProduct = data?.cart?.items?.filter((el) => el?.product?.sku === item?.product?.sku)[0]
                const options: any = []
                item?.bundle_options?.flatMap(el =>
                    el?.values?.map((option: any) => {
                        options.push({
                            label: option.label,
                            id: option.id,
                            price: option.price,
                            quantity: option.quantity,
                        })
                    })
                )
                item?.customizable_options?.flatMap(el => {
                    options.push({
                        label: el.label,
                        price: el?.values?.[0]?.price?.value
                    })
                })
                return item &&
                    ({
                        id: item.product.sku,
                        name: item.product.name,
                        price: item.product.price_range.minimum_price.final_price.value,
                        quantity: infoResponseProduct?.quantity,
                        category1: getCategory(item.product as any),
                        category2: 'Nieokreślone',
                        brand: 'Lilou',
                        dimension4: getDimension4(item.product?.categories),
                        dimension7: undefined,
                        dimension8: ProductHelper.isPersonalizedProduct(item.product) ? 'Personalizowany' : 'Standard',
                        dimension9: item.product.price_range.minimum_price.final_price.value === 0 ? 'Gratis' : 'Zakup',
                        variant: getMaterialSwatchLabel(
                            metadata,
                            item.product.material_swatch?.toString() || ''
                        ),
                        item_list_name: getGtmItemListName(
                            gtmItemListNames,
                            item?.product?.sku
                        ),
                        options,
                    } as CartProduct)
            }
        )
        .filter((item): item is CartProduct => !!item)
}

export const getGtmItemListName = (
    gtmItemListNames: Record<string, string>,
    sku: Maybe<string>
) => {
    return (sku && gtmItemListNames[sku]) || ''
}

type Category = Maybe<{ name?: Maybe<string> }>;
type Categories = Maybe<Array<Category>> | undefined;
export const getDimension4 = (categories: Categories) => {
    return categories && categories?.length > 0 ? categories?.find(category => category?.name?.includes('Kolekcja'))?.name :
        ''
}


export const getEngravingGraphicsNames = (engraving: Engraving) => {
    if (!engraving.selected_engrave_options) {
        return ''
    }

    return Object.values(engraving.selected_engrave_options)
        .flatMap(sku => {
            return Object.values(sku).flatMap(options =>
                options.map(option => engravingFileName(option))
            )
        })
        .join('|')
}
