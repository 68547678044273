import {parseCookies} from 'nookies'
import {useEffect, useMemo, useState} from 'react'
import dayjs from 'dayjs'
import {useRouter} from '_app/hooks/useRouter'
import {useLazyQuery} from '@apollo/client'
import {GetCartDocumentSmall} from '_app/graphql/queries/cart/GetCartDocumentSmall'
import DateHelper from '../../../helpers/DateHelper/DateHelper'

const giftBarLocalisedThresholds = {
  pl: 299,
  de: 150,
}
const supportedLocales = Object.keys(giftBarLocalisedThresholds)
const dates = {
  from: dayjs('2023-10-06 10:00:00').format(),
  to: dayjs('2023-10-19 23:59:59').format(),
}

const useGiftPriceThreshold = () => {
  const { locale } = useRouter()
  const { cartId } = parseCookies()
  const [data, setData]: any = useState(null)
  const thresholdPrice = giftBarLocalisedThresholds[locale as 'pl' | 'de' | 'fr']
  const [getCartDocument] = useLazyQuery(GetCartDocumentSmall)
  const isGiftThresholdPriceReached = () => cartSum >= thresholdPrice
  const shouldSkip = () => {
    return (
      !cartId ||
      !supportedLocales.includes(locale) ||
      !DateHelper.isBetween(dates.from, dates.to) ||
      isGiftThresholdPriceReached()
    )
  }
  const cartSum = useMemo(() => (
      data?.cart?.items || []).reduce(
        (acc: number, val: any) => acc + (val?.prices?.price.value ?? 0) * (val?.quantity ?? 0), 0
      ), [data]
  )
  const isCartEmpty = () => !cartSum
  const getAmountLeft = () => thresholdPrice - cartSum
  const getCart = async () => {
    if (cartId && !shouldSkip()) {
      const tempData: any = await getCartDocument({
        variables: {
          cartId,
        },
      })
      setData(tempData)
    }
  }
  useEffect(() => {
    getCart()
  }, [cartId])
  return {
    shouldSkip,
    isGiftThresholdPriceReached,
    isCartEmpty,
    amountLeft: getAmountLeft(),
  }
}

export default useGiftPriceThreshold
