import { Maybe, MediaGalleryInterface } from '_app/types/api'

type MediaGalleryInterfaceWithUrl = MediaGalleryInterface & { url: string };

type ProductWithImages =
  | { media_gallery?: Maybe<Maybe<MediaGalleryInterface>[]> }
  | undefined;

export const getProductImages = (product: ProductWithImages) => {
  return (
    product?.media_gallery
      ?.filter(
        (photo): photo is MediaGalleryInterfaceWithUrl =>
          !!photo?.url && !photo.disabled
      )
      .sort((a, b) => (a.position || 0) - (b.position || 0)) || []
  )
}

export const getProductMainImage = (product: ProductWithImages) => {
  const mainImage = getProductImages(product)

  return mainImage[0]?.url
}
