import {IconButton, ButtonProps, useToast, Tooltip} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'
import {
    HeartIcon,
    HeartFilledIcon,
    HeartFillGoldIcon,
} from '_app/components/Icons'
import useManageFavorites from '_app/hooks/useManageFavorites'
import toast from '_app/utils/toast'
import {useEffect, useRef, useState} from 'react'
import CookiesHelper from '../../../helpers/CookiesHelper/CookiesHelper'
import {GTM_EVENTS} from '../../utils/dataLayer'

interface FavoriteButtonProps extends ButtonProps {
    sku: string;
    isGold?: boolean;
    item?: any;
}

const FavoriteButton = ({
                            sku,
                            isGold = false,
                            item,
                            ...props
                        }: FavoriteButtonProps) => {
    const {t} = useTranslation()
    const toastClose = useToast()
    const customerToken = CookiesHelper.get('customerToken')
    const [check, setCheck] = useState(false)
    const {
        addToFavorites,
        removeFromFavoritesBySku,
        isLoading,
        checkIsFavorite,
    } = useManageFavorites()

    const handleClick = () => {
        if (customerToken?.length > 0) {
            const isFavorite = checkIsFavorite(sku)
            isFavorite ? removeFromFavoritesBySku(sku) : addToFavorites(sku, item)
            setCheck(!check)
            toastClose.closeAll()
            toast({
                description: isFavorite
                    ? t('REMOVED_FROM_FAVORITES')
                    : t('ADDED_TO_FAVORITES'),
                status: 'success',
            })
        }
    }
    const heartIconColorVariant = (isGold: boolean) =>
        isGold ? <HeartFillGoldIcon/> : <HeartFilledIcon/>
    useEffect(() => {
        if (sku) {
            setCheck(checkIsFavorite(sku))
        }
    }, [sku])
    return (
        <Tooltip isDisabled={customerToken?.length > 0} hasArrow label={t('FAVORITES_DISABLED')} bg='red.600'>
            <IconButton
                variant="icon"
                p={0}
                h="unset"
                icon={
                    check ? heartIconColorVariant(isGold) : <HeartIcon/>
                }
                sx={{
                    cursor: customerToken?.length > 0 ? 'pointer' : 'auto',
                }}
                isLoading={isLoading}
                aria-label={t('ADD_TO_FAVORITES')}
                onClick={handleClick}
                {...props}
            />
        </Tooltip>
    )
}

export default FavoriteButton
