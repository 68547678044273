import CartHelper from './CartHelper'
import {cart, cartIdVar} from '../../_app/cache'
import CookiesHelper from '../CookiesHelper/CookiesHelper'
import {CREATE_EMPTY_CART} from '../../_app/graphql/mutations/cart/CreateEmptyCart'
import {GetCartDocumentSmall} from '../../_app/graphql/queries/cart/GetCartDocumentSmall'
import {CUSTOMER_CART} from '../../_app/graphql/queries/cart/CustomerCart'

export default {
    createEmptyCart: async (apolloClient: any, cartIdOld: any, isActive: boolean, logIn = false) => {
        const cartId = cartIdOld
        const customerToken = CookiesHelper.get('customerToken')
        const handleCreateEmptyCartLoginUser = async () => {
            return await apolloClient.query({
                query: CUSTOMER_CART,
                variables: {},
            }).then(async (res: any) => {
                if (res) {
                    const newCartId = res?.data?.customerCart?.id
                    await CartHelper.setCartId(newCartId)
                    const data = await apolloClient.query({
                        query: GetCartDocumentSmall,
                        variables: {
                            cartId: newCartId,
                        },
                    })
                    await cart(data?.data)
                    cartIdVar(newCartId)
                    CookiesHelper.set('cartId', newCartId)
                    return newCartId
                }
            })
        }
        const handleCreateEmptyCart = async () => {
            return await apolloClient.mutate({
                mutation: CREATE_EMPTY_CART,
                variables: {},
            }).then(async (res: any) => {
                if (res) {
                    const newCartId = res?.data?.createGuestCart?.cart?.id
                    await CartHelper.setCartId(newCartId)
                    const data = await apolloClient.query({
                        query: GetCartDocumentSmall,
                        variables: {
                            cartId: newCartId,
                        },
                    })
                    await cart(data?.data)
                    cartIdVar(newCartId)
                    CookiesHelper.set('cartId', newCartId)
                    return newCartId
                }
            })
        }
        if (!cartId) {
            if (logIn || customerToken) {
                return await handleCreateEmptyCartLoginUser()
            }
            return await handleCreateEmptyCart()
        } else {
            if (isActive) {
                return cartId || ''
            } else {
                if (logIn || customerToken) {
                    return await handleCreateEmptyCartLoginUser()
                }
                return await handleCreateEmptyCart()
            }
        }
    },
    setCartId: async (id: string) => {
        CookiesHelper.remove('cartId')
        CookiesHelper.set('cartId', id)
    },
}