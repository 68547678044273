import { CurrencyEnum, Maybe } from '_app/graphql/generated/graphql'
import getCurrency from './getCurrency'

const getPrice = (
  locale: string,
  price?: Maybe<number>,
  currency?: Maybe<CurrencyEnum>,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) =>
  price?.toLocaleString(locale, {
    style: 'currency',
    currency: currency ?? getCurrency(locale),
    minimumFractionDigits,
    maximumFractionDigits,
  })

export default getPrice
