import { Country, Maybe } from '_app/types/api'

export const getRegionById = (id: number, country: Country | undefined) => {
  return country?.available_regions?.find(region => region?.id === id)
}

export const getRegionByCode = (
  name: Maybe<string> | undefined,
  country: Country | undefined
) => {
  return country?.available_regions?.find(region => region?.code === name)
}

export const getRegionFromAutoComplete = (
  countries: Country[] | undefined,
  regionName: string | undefined,
  countryCode: string
) => {
  const chosenCountry = countries?.find(country => country.id === countryCode)

  return chosenCountry?.available_regions?.find(
    region => region?.name?.toLowerCase() === regionName?.toLowerCase()
  )
}

export const getCountryById = (
  id: string | undefined,
  countries: Country[] | undefined
) => {
  return countries?.find(country => country.id === id)
}
