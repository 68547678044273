import { Box, BoxProps, RadioProps, useRadio } from '@chakra-ui/react'
import { FC } from 'react'

interface RadioCardProps extends RadioProps {
  cardProps: BoxProps;
}

export const RadioCard: FC<RadioCardProps> = ({ cardProps, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius={0}
        borderWidth={1}
        borderColor="#fff"
        {...cardProps}
      >
        {props.children}
      </Box>
    </Box>
  )
}
