import React from 'react'
import Image from 'next/image'
import ImageHelper from '../../../helpers/ImageHelper/ImageHelper'

type ImgElementStyle = NonNullable<JSX.IntrinsicElements['img']['style']>;

type ObjectFitUnion = ImgElementStyle['objectFit'] | undefined;

const ImageCms = ({attributes, quality = 85, alt, width, height, layout, contain, priority, objectFit, style, className}: any) => {
    const loading = priority ? 'eager' : 'lazy'
    if (!attributes?.url) return null
    const loaderProps = {
        loader: ImageHelper.cloudflareLoader,
    }
    const sizeProps = {
        width: !layout ? (attributes.width || width) : undefined,
        height: !layout ? (attributes.height || height) : undefined,
    }
    const containVariable: ObjectFitUnion = contain as ObjectFitUnion || 'cover'
    const coverVariable: ObjectFitUnion = contain as ObjectFitUnion || 'cover'
    if (sizeProps?.width && sizeProps?.height) {
        return (
            <Image
                {...loaderProps}
                {...sizeProps}
                quality={quality}
                className={className}
                objectFit={coverVariable}
                objectPosition="center"
                placeholder="blur"
                blurDataURL={ImageHelper.cloudflareLoader({
                    src: attributes?.url,
                    width: 10,
                    quality: 50,
                })}
                alt={attributes?.alternativeText || alt || ''}
                src={ImageHelper.normalizeSrc(attributes?.url)}
                loading={loading}
                style={{
                    objectFit,
                    ...style,
                }}
            />
        )
    } else {
        return (
            <Image
                {...loaderProps}
                quality={quality}
                objectFit={containVariable}
                className={className}
                objectPosition="center"
                placeholder="blur"
                blurDataURL={ImageHelper.cloudflareLoader({
                    src: attributes?.url,
                    width: 10,
                    quality: 50,
                })}
                alt={attributes?.alternativeText || alt || ''}
                src={ImageHelper.normalizeSrc(attributes?.url)}
                layout="fill"
                loading={loading}
                style={{
                    objectFit,
                    ...style,
                }}
            />
        )
    }

}

export default ImageCms
