import { BundleProduct } from '_app/types/api'

type RequiredOption = {
  type: string;
  options: {
    label: string;
    value: string;
  }[];
};
export const getSizeById = (
  selectedOptions: string[],
  requiredOptions: RequiredOption[] | undefined
) => {
  const sizes =
    requiredOptions?.find(({ type }) => type === 'size')?.options || []
  const selectedSize = sizes.find(size => selectedOptions.includes(size.value))
  const selectedSizeNumber = selectedSize?.label.match(/\d+/g)?.[0]

  return selectedSizeNumber || '0'
}

const DEFAULT_SIZE = '16'
export const getDefaultSize = (
  options: { label: string; value: string }[],
  cheapestSizeOptionUid: string | undefined
) => {
  const cheapestSize = options.find(
    option => option.value === cheapestSizeOptionUid
  )
  const reserveDefaultSize = options[options.length - 1]
  const option =
    options.find(({ label }) => label.includes(DEFAULT_SIZE)) ||
    cheapestSize ||
    reserveDefaultSize

  return option?.value
}

export const getNecklaceCheapestSize = (product: BundleProduct) => {
  if (!Array.isArray(product.categories) || !product.categories?.some(category => category?.name === 'Naszyjniki')) {
    return
  }

  const sizes = product.items?.find(item => item?.title?.includes('Rozmiar'))

  if (!sizes) {
    return
  }

  const cheapestItem = sizes.options
    ?.slice()
    .sort(
      (a, b) =>
        (a?.product?.price_range?.minimum_price?.final_price?.value || 0) -
        (b?.product?.price_range?.minimum_price?.final_price?.value || 0)
    )[0]

  return cheapestItem?.uid
}
