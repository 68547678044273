import ReactSelect, {
  DropdownIndicatorProps,
  StylesConfig,
  Props,
} from 'react-select'
import { useTranslation } from 'next-i18next'

import theme from 'theme'

import ChevronDownIcon from '_app/components/Icons/ChevronDownIcon'

const styles: StylesConfig = {
  control: (provided, { isFocused }) => ({
    ...provided,
    borderRadius: 0,
    borderWidth: '0 0 1px 0',
    borderColor: isFocused ? theme.colors.focus : theme.colors.black,
    boxShadow: 'unset',
    ':hover': {
      borderColor: theme.colors.black,
    },
    backgroundColor: isFocused ? theme.colors.focusTransparent : 'transparent',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, { isDisabled }) => ({
    ...provided,
    display: isDisabled ? 'none' : 'flex',
  }),
  valueContainer: provided => ({
    ...provided,
    paddingRight: 0,
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0,
    borderWidth: 1,
    borderColor: theme.colors.black,
    boxShadow: 'unset',
    marginTop: 16,
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected
      ? theme.colors.black
      : isFocused
      ? theme.colors.hoverDark
      : 'transparent',
    color: isSelected ? 'white' : 'inherit',
    ':active': {},
    ':hover': {
      backgroundColor: !isSelected ? theme.colors.hoverDark : '',
      color: !isSelected ? 'white' : '',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: theme.colors.black,
    opacity: 0.4,
  }),
  singleValue: (provided, { data }) => {
    const styles = data as any

    return {
      ...provided,
      fontFamily: styles?.fontFamily || 'inherit',
    }
  },
}

const DropdownIndicator = ({
  selectProps: { menuIsOpen },
}: DropdownIndicatorProps) => {
  return (
    <ChevronDownIcon
      sx={{
        transform: `rotate(${menuIsOpen ? 180 : 0}deg)`,
        transition: 'transform 0.2s',
      }}
    />
  )
}

const components = {
  DropdownIndicator,
}

interface SelectProps extends Props {
  instanceId: string | number;
  isImages?: boolean;
}

const Select = (props: SelectProps) => {
  const { t } = useTranslation()
  const { isImages } = props

  if (!!isImages) {
    styles.option = (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected
        ? theme.colors.main
        : isFocused
        ? theme.colors.main
        : 'transparent',
      color: isSelected ? 'white' : 'inherit',
      ':active': {},
      ':hover': {
        backgroundColor: !isSelected ? theme.colors.main : '',
        color: !isSelected ? 'white' : '',
      },
    })
  }

  return (
    <ReactSelect
      placeholder={t('CHOOSE', { ns: 'common' })}
      styles={styles}
      components={components}
      {...props}
    />
  )
}

export default Select
