import { atom } from 'recoil'

export const showTopBarAtom = atom({
  key: 'showTopBar',
  default: false,
})
export const gtmItemListNamesAtom = atom<Record<string, string>>({
  key: 'gtmItemListNames',
  default: {},
})
