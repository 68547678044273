import {FC} from 'react'
import {FormControl, FormLabel, Input as ChakraInput} from '@chakra-ui/react'
import {useFormContext} from 'react-hook-form'
import {get} from 'lodash-es'

import FormErrorMessage from '_app/components/Form/FormErrorMessage'

const Input: FC<any> = ({
  label,
  name,
  defaultValue,
  type = 'text',
  isRequired,
  hidden,
  isDisabled,
  mb = 4,
  mask,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const errorMessage: any = get(errors, name)?.message

  return (
    <FormControl
      isRequired={isRequired && !hidden && !isDisabled}
      isInvalid={!!(errorMessage)}
      mb={mb}
      defaultValue={defaultValue}
    >

      <FormLabel htmlFor={name}>{label}</FormLabel>
      <>
        {
          props?.mask ? (
            <ChakraInput
                type={type}
                p={0}
                borderColor={errorMessage ? 'alert' : 'black'}
                {...register(name)}
                data-testid={name}
                hidden={hidden}
                isDisabled={isDisabled}
                _placeholder={{ color: 'black', opacity: 0.4 }}
                {...props}
                required={false}
              />
            ) : (
            <ChakraInput
              type={type}
              p={0}
              borderColor={errorMessage ? 'alert' : 'black'}
              {...register(name)}
              data-testid={name}
              hidden={hidden}
              isDisabled={isDisabled}
              _placeholder={{ color: 'black', opacity: 0.4 }}
              {...props}
              required={false}
            />
          )
        }
      </>
      <FormErrorMessage name={label}>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default Input
