import {useEffect, useMemo} from 'react'
import {destroyCookie, parseCookies} from 'nookies'
import {useReactiveVar} from '@apollo/client'
import {signOut, SignOutParams, useSession} from 'next-auth/react'
import {
  cart,
  cartIdVar,
  customerTokenVar,
  emailOnCart,
  isAuthenticatedVar,
  wishlistArrayVar,
  wishlistIdVar,
} from '_app/cache'
import {useGetCustomerQuery} from '_app/graphql/generated/graphql'
import {Customer} from '_app/types/api'
import CustomerHelper from '../../helpers/CustomerHelper/CustomerHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'
import {initializeApollo} from '../../helpers/ApolloHelper/ApolloHelper'
import {LOGOUT} from '../graphql/queries/customer/Logout'


type revokeSocialTokenArgs = undefined | SignOutParams<false>;

type revokeCustomerTokenArgs =
    | undefined
    | {
  variables?: Record<string, unknown> | undefined;
  refetchQueries?: any;
  optimisticResponse?: any;
  update?: any;
  awaitRefetchQueries?: boolean | undefined;
};

type logoutArguments =
    | undefined
    | {
  revokeSocialTokenParams?: revokeSocialTokenArgs;
  revokeCustomerTokenParams?: revokeCustomerTokenArgs;
};

interface UseCustomer {
  customer?: Customer;
  addOnCompletedLogoutCallback: (callback: () => void) => void;
  logout: (args?: logoutArguments) => void;
  loading: boolean;
}

const useCustomer = (): UseCustomer => {
  const onCompletedRevokeCustomerToken: (() => void)[] = []
  const apolloClient: any = typeof document !== 'undefined' && initializeApollo(null, { locale: document.documentElement.lang })
  const { wishlistId, customerSocialsToken, cartId } = parseCookies() ?? {}
  const { data, loading } = useGetCustomerQuery({
    fetchPolicy: 'network-only',
    skip: !CustomerHelper.getCustomerToken() || typeof window === 'undefined',
  })
  const { data: session } = useSession()
  const revokeSocialToken = async (
      revokeSocialTokenParams: revokeSocialTokenArgs
  ) => {
    signOut(revokeSocialTokenParams)
  }
  const cartIdReactive = useReactiveVar(cartIdVar)
  const customerToken = useReactiveVar(customerTokenVar)
  const isAuthenticated = useReactiveVar(isAuthenticatedVar)

  const addOnCompletedRevokeCustomerTokenCallback = (callback: () => void) => {
    onCompletedRevokeCustomerToken.push(callback)
    return onCompletedRevokeCustomerToken
  }

  const frontLogout = async () => {
    await apolloClient.query({
      query: LOGOUT,
    })
    CookiesHelper.remove('cartId')
    CookiesHelper.remove('wishlistId')
    CookiesHelper.remove('customerToken')
    CookiesHelper.remove('customerSocialsToken')
    CookiesHelper.remove('PHPSESSID')
    CookiesHelper.remove('private_content_version')
    CookiesHelper.set('wishlist', '[]')
    LocalStorageHelper.remove('receiveInvoiceWatch')
    isAuthenticatedVar(false)
    customerTokenVar(null)
    wishlistIdVar(null)
    cartIdVar(null)
    emailOnCart(null)
    cart(null)
    onCompletedRevokeCustomerToken.forEach(cb => cb())
  }

  const logout = async (args?: any) => {
    await frontLogout()
    if (isAuthenticated && customerSocialsToken) {
      revokeSocialToken(args?.revokeSocialTokenParams)
    }
  }

  const memoizedCustomer = useMemo(() => {
    return data?.customer
  }, [data?.customer])

  useEffect(() => {
    if (memoizedCustomer?.wishlists?.[0]?.id && !wishlistId && isAuthenticated) {
      CookiesHelper.set('wishlistId', memoizedCustomer.wishlists[0].id)
      wishlistArrayVar(null)
    } else if (!memoizedCustomer && !customerToken) CookiesHelper.remove('wishlistId')
  }, [customerToken, memoizedCustomer, wishlistId, customerSocialsToken])

  useEffect(() => {
    if (session && !customerSocialsToken) {
      signOut().then(() => {})
      destroyCookie(null, 'cartId', {
        path: '/',
      })
      destroyCookie(null, 'guestCartId', {
        path: '/',
      })
    }
  }, [customerSocialsToken, session])

  useEffect(() => {
    if (cartId && cartId !== cartIdReactive) cartIdVar(cartId)
  }, [cartId, cartIdReactive])

  return {
    customer: memoizedCustomer as Customer,
    addOnCompletedLogoutCallback: addOnCompletedRevokeCustomerTokenCallback,
    logout,
    loading,
  }
}

export default useCustomer
