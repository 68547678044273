import styled from '@emotion/styled'
import { Box, Flex } from '@chakra-ui/react'
import theme from 'theme'

export const StyledWrapper = styled(Flex)`
  margin-block: 3rem;
  gap: 1.5rem;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.lg}) {
    margin-block: 6rem;
  }
`

export const StyledIntentionsIconsWrapper = styled(Flex)`
  border-radius: 3.125rem;
  border: 1px solid ${theme.colors.darkGray};
  margin-inline-start: auto;
  margin-inline-end: auto;
  flex-shrink: 0;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
  }
`

export const StyledIntentionIconBox = styled(Box)<{
  key: string;
  activeTab?: string;
}>`
  position: relative;
  padding: 1.25rem;
  border-radius: 3.125rem;
  cursor: pointer;
  ${({ key, activeTab }) => `
    background: ${activeTab === key ? 'white' : 'transparent'};
    border: ${activeTab === key ? '1px solid black' : 'none'};
  `}
`
