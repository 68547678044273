import { BoxProps, Box } from '@chakra-ui/react'
import { FC } from 'react'

type CardProps = BoxProps;

const Card: FC<CardProps> = ({ children, ...props }) => {
  return (
    <Box
      borderColor="divider"
      borderWidth={1}
      p={{ base: '1rem', md: '1.5rem' }}
      bg="white"
      {...props}
    >
      {children}
    </Box>
  )
}

export default Card
