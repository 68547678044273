import { FC } from 'react'
import { Ring } from './SizingOptions/Ring'
import { Necklace } from './SizingOptions/Necklace'
import { Bracelet } from './SizingOptions/Bracelet'
interface SizingOptionsProps {
  isOpen: boolean;
  onClose: () => void;
  optionType: any;
}

const SizingOptions: FC<SizingOptionsProps> = ({
  isOpen,
  onClose,
  optionType,
}) => {
  const sizingOptions = {
    ring: <Ring isOpen={isOpen} onClose={onClose} />,
    necklace: <Necklace isOpen={isOpen} onClose={onClose} />,
    bracelet: <Bracelet isOpen={isOpen} onClose={onClose} />,
  }

  return sizingOptions[optionType] || null
}

export default SizingOptions
