import {useEffect, useMemo} from 'react'
import {useSetRecoilState} from 'recoil'
import {showTopBarAtom} from '_app/state/atoms'
import {useGetLayoutQuery} from '_app/graphql/cms/generated/graphql'
import {useRouter} from '_app/hooks/useRouter'
import filterBannerItems from '../TopBar/utils'
import RequestEnum from '../../../enums/RequestEnum/RequestEnum'

const usePageData = () => {
    const router = useRouter()
    const { locale } = router
    const setShowTopBar = useSetRecoilState(showTopBarAtom)
    // const {data: tempDAta} = useGetLayoutQuery({
    //     context: {clientName: RequestEnum.CMS_CONTEXT},
    //     variables: { locale },
    // })
    let data: any = {
        'navigation': {
            'data': {
                'attributes': {
                    'banner': {
                        'items': [
                            {
                                'order': 1,
                                'text': ' W najbliższą niedzielę handlową 26.01, nasze butiki będą otwarte.',
                                'url': 'https://lilou.pl/pl/butiki.html',
                                'date_from': '2024-12-27T00:00:00.000Z',
                                'date_to': '2025-01-26T21:59:00.000Z',
                                '__typename': 'ComponentNavigationBannerItems'
                            },
                            {
                                'order': 2,
                                'text': 'Dostawa w prezencie od 249 zł.*',
                                'url': 'https://lilou.pl/pl/informacje/czas-i-koszty-dostawy.html',
                                'date_from': '2024-12-27T00:00:00.000Z',
                                'date_to': '2025-11-18T23:00:00.000Z',
                                '__typename': 'ComponentNavigationBannerItems'
                            },
                            {
                                'order': 1,
                                'text': 'Masz pytania? Skontaktuj się z nami',
                                'url': 'https://lilou.pl/pl/kontakt.html',
                                'date_from': '2024-12-27T00:00:00.000Z',
                                'date_to': '2025-11-18T23:00:00.000Z',
                                '__typename': 'ComponentNavigationBannerItems'
                            },
                        ],
                        '__typename': 'ComponentNavigationBanner'
                    },
                    'top_menu': [
                        {
                            'id': '32',
                            'title': 'Nowości',
                            'image': null,
                            'link': {
                                'id': '696',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/sklep/polecane/nowosci',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '83',
                            'title': 'Biżuteria z grawerunkiem',
                            'image': null,
                            'link': {
                                'id': '1423',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/sklep/bizuteria/bizuteria-z-grawerem',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '7',
                            'title': 'Biżuteria',
                            'image': {
                                'img': {
                                    'data': null,
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'link': {
                                    'id': '251',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'cta': null,
                                '__typename': 'ComponentNavigationTopMenuImage'
                            },
                            'link': {
                                'id': '1186',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/sklep/bizuteria',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [
                                {
                                    'id': '16',
                                    'title': 'Kategorie',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '97',
                                            'external_href': '',
                                            'target': 'self',
                                            'text': 'Bransoletki',
                                            'href': '/sklep/bizuteria/bransoletki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '98',
                                            'external_href': '',
                                            'target': 'self',
                                            'text': 'Naszyjniki',
                                            'href': '/sklep/bizuteria/naszyjniki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '181',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolczyki',
                                            'href': '/sklep/bizuteria/kolczyki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '182',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Pierścionki',
                                            'href': '/sklep/bizuteria/pierscionki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '183',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Zawieszki',
                                            'href': '/sklep/bizuteria/zawieszki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '184',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Broszki',
                                            'href': '/sklep/bizuteria/broszki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '185',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Spinki do mankietów',
                                            'href': '/sklep/bizuteria/spinki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '186',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Biżuteria ze złota próby 585',
                                            'href': '/sklep/bizuteria/bizuteria-ze-zlota-proby-585',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1411',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Akcesoria do biżuterii',
                                            'href': '/sklep/bizuteria/akcesoria-do-bizuterii',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1400',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Special Prices',
                                            'href': '/sklep/polecane/koncowki-kolekcji',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                },
                                {
                                    'id': '31',
                                    'title': 'Polecane',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '1409',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Wiosenne inspiracje',
                                            'href': '/sklep/polecane/inspiracje/spring-trends',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1409',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Ikony Lilou',
                                            'href': '/sklep/polecane/inspiracje/ikony-lilou',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1409',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Miyuki',
                                            'href': '/sklep/kolekcje/kolekcja-miyuki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1409',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Icons',
                                            'href': '/sklep/kolekcje/kolekcja-icons',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1409',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Emalia',
                                            'href': '/sklep/kolekcje/kolekcja-emalia',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1353',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Nausznice',
                                            'href': '/sklep/bizuteria/kolczyki/nausznice',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1288',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Biżuteria na ślub',
                                            'href': '/sklep/bizuteria/bizuteria-na-slub',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1306',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Wyjątkowe okazje',
                                            'href': '/sklep/polecane/inspiracje/bizuteria-na-wyjatkowe-okazje',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '910',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Bestsellery',
                                            'href': '/sklep/polecane/bestsellery',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1221',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja męska Stones',
                                            'href': '/sklep/kolekcje/kolekcja-stones',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        }
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                },
                                {
                                    'id': '73',
                                    'title': 'Motywy',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '713',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Doskonałość mody "Twój styl"',
                                            'href': '/sklep/bizuteria/motywy/doskonalosc-mody-twoj-styl',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '698',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Kwiaty',
                                            'href': '/sklep/bizuteria/motywy/kwiaty',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '699',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Podróże',
                                            'href': '/sklep/bizuteria/motywy/podroze',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '704',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Z emalią',
                                            'href': '/sklep/bizuteria/motywy/z-emalia',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '717',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Miłość',
                                            'href': '/sklep/bizuteria/motywy/milosc',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '703',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Z efektem WOW',
                                            'href': '/sklep/bizuteria/motywy/z-efektem-wow',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '705',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Z kamieniami',
                                            'href': '/sklep/bizuteria/motywy/z-kamieniami',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '706',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Astrologia',
                                            'href': '/sklep/bizuteria/motywy/astrologia',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '701',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Minimalizm',
                                            'href': '/sklep/bizuteria/motywy/minimalizm',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1183',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Nieskończoność',
                                            'href': '/sklep/bizuteria/motywy/nieskonczonosc',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '700',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Zwierzęta',
                                            'href': '/sklep/bizuteria/motywy/zwierzeta',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                },
                                {
                                    'id': '15',
                                    'title': 'Kolekcje',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Miyuki',
                                            'href': '/sklep/kolekcje/kolekcja-miyuki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Płomień Miłości',
                                            'href': '/sklep/kolekcje/kolekcja-plomien-milosci',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Icons',
                                            'href': '/sklep/kolekcje/kolekcja-icons',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Margaret',
                                            'href': '/sklep/kolekcje/kolekcja-margaret',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Bloom',
                                            'href': '/sklep/kolekcje/kolekcja-bloom',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Papillon',
                                            'href': '/sklep/kolekcje/kolekcja-papillon',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Flowers',
                                            'href': '/sklep/kolekcje/kolekcja-flowers',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Honey',
                                            'href': '/sklep/kolekcje/kolekcja-honey',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1410',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Ważka',
                                            'href': '/sklep/kolekcje/kolekcja-wazka',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '695',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Kolekcja Forza',
                                            'href': '/sklep/kolekcje/kolekcja-forza',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1371',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kolekcja Planets',
                                            'href': '/sklep/kolekcje/kolekcja-planets',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1371',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Więcej',
                                            'href': '/sklep/kolekcje',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                },
                                {
                                    'id': '69',
                                    'title': 'Na prezent',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '571',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Do 150 zł',
                                            'href': '/sklep/na-prezent/prezent-do-150-zl',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '569',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Do 300 zł',
                                            'href': '/sklep/na-prezent/prezent-do-300-zl',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1182',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Do 500 zł',
                                            'href': '/sklep/na-prezent/na-prezent-do-500-zl',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '570',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Dla niego',
                                            'href': '/sklep/na-prezent/na-prezent-dla-niego',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '573',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Dla dziecka',
                                            'href': '/sklep/na-prezent/na-prezent-dla-dziecka',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '575',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Zestawy',
                                            'href': '/sklep/na-prezent/zestawy',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        }
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                }
                            ],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '78',
                            'title': 'Biżuteria męska',
                            'image': null,
                            'link': {
                                'id': '1383',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/sklep/bizuteria-meska',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '60',
                            'title': 'Biżuteria na prezent',
                            'image': null,
                            'link': {
                                'id': '1217',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/inspiracje/bizuteria-na-prezent',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '6',
                            'title': 'Torebki i akcesoria',
                            'image': {
                                'img': {
                                    'data': {
                                        'id': '3782',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/cb53a4b5-e2f5-4412-9cf9-1eb6f555ee00/public',
                                            'width': 440,
                                            'height': 440,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'link': null,
                                'cta': null,
                                '__typename': 'ComponentNavigationTopMenuImage'
                            },
                            'link': {
                                'id': '1187',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/sklep/torebki-i-akcesoria',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [
                                {
                                    'id': '14',
                                    'title': 'Torebki',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '95',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Torebki Shopper',
                                            'href': '/sklep/torebki-i-akcesoria/torebki-shoppery',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '94',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Torebki na ramię',
                                            'href': '/sklep/torebki-i-akcesoria/torebki-na-ramie',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '345',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Torebki wizytowe małe',
                                            'href': '/sklep/torebki-i-akcesoria/torebki-wizytowe-male',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        }
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                },
                                {
                                    'id': '33',
                                    'title': 'Akcesoria',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '1378',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Etui na długopis',
                                            'href': '/sklep/torebki-i-akcesoria/etui-na-dlugopis',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '628',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Etui na paszport',
                                            'href': '/sklep/torebki-i-akcesoria/etui-na-paszport',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '342',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Chusty i Apaszki',
                                            'href': '/sklep/torebki-i-akcesoria/chusty-i-apaszki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '670',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Szkatułki na biżuterię',
                                            'href': '/sklep/torebki-i-akcesoria/szkatulki-na-bizuterie',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '340',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Kosmetyczki',
                                            'href': '/sklep/torebki-i-akcesoria/kosmetyczki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '341',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Portfele',
                                            'href': '/sklep/torebki-i-akcesoria/portfele',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '343',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Card holdery',
                                            'href': '/sklep/torebki-i-akcesoria/card-holdery',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '344',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Notesy',
                                            'href': '/sklep/torebki-i-akcesoria/notesy',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1141',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Breloki',
                                            'href': '/sklep/torebki-i-akcesoria/breloki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1142',
                                            'external_href': null,
                                            'target': 'blank',
                                            'text': 'Dodatki do torebek',
                                            'href': '/sklep/torebki-i-akcesoria/dodatki-do-torebek',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        }
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                }
                            ],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '79',
                            'title': 'Bestsellery',
                            'image': null,
                            'link': {
                                'id': '1384',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/sklep/polecane/bestsellery',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '9',
                            'title': 'Poznaj Lilou',
                            'image': {
                                'img': {
                                    'data': {
                                        'id': '3783',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/24d7fb4d-9be9-4874-3035-d74d5ad98100/public',
                                            'width': 500,
                                            'height': 307,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'link': null,
                                'cta': null,
                                '__typename': 'ComponentNavigationTopMenuImage'
                            },
                            'link': {
                                'id': '81',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/o-nas',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [
                                {
                                    'id': '41',
                                    'title': 'Skontaktuj się z nami',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '408',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Kontakt',
                                            'href': '/kontakt',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        }
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                },
                                {
                                    'id': '42',
                                    'title': 'Poznaj Lilou',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '404',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'O Lilou',
                                            'href': '/o-nas',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '405',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Butiki',
                                            'href': '/butiki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '407',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Aktualności',
                                            'href': '/blog?c=news&p=1',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1406',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Akcje charytatywne',
                                            'href': '/inspiracje/akcje-charytatywne',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        }
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                },
                                {
                                    'id': '128',
                                    'title': 'Inspiracje',
                                    'link': null,
                                    'items': [
                                        {
                                            'id': '1385',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Aktualności',
                                            'href': '/blog?c=news&p=1',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        {
                                            'id': '1387',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': 'Porady',
                                            'href': '/blog?c=tips&p=1',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        }
                                    ],
                                    '__typename': 'ComponentNavigationColumn'
                                }
                            ],
                            '__typename': 'ComponentNavigationTopMenu'
                        },
                        {
                            'id': '58',
                            'title': 'Blog',
                            'image': null,
                            'link': {
                                'id': '1203',
                                'external_href': null,
                                'target': 'self',
                                'text': null,
                                'href': '/blog',
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'columns': [],
                            '__typename': 'ComponentNavigationTopMenu'
                        }
                    ],
                    '__typename': 'Navigation'
                },
                '__typename': 'NavigationEntity'
            },
            '__typename': 'NavigationEntityResponse'
        },
        'footer': {
            'data': {
                'attributes': {
                    'columns': [
                        {
                            'id': '18',
                            'title': 'Poradnik zakupowy',
                            'link': null,
                            'items': [
                                {
                                    'id': '131',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Czas i koszt dostawy',
                                    'href': '/informacje/czas-i-koszty-dostawy',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '409',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Formy płatności',
                                    'href': '/informacje/formy-platnosci',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '133',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Zwroty i reklamacje',
                                    'href': '/informacje/zwroty-i-reklamacje',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '132',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'FAQ',
                                    'href': '/informacje/faq',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '130',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Kontakt',
                                    'href': '/kontakt',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                }
                            ],
                            '__typename': 'ComponentNavigationColumn'
                        },
                        {
                            'id': '19',
                            'title': 'Zasady i regulamin',
                            'link': null,
                            'items': [
                                {
                                    'id': '134',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Regulamin sklepu',
                                    'href': '/informacje/regulamin-sklepu-internetowego',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '135',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Polityka prywatności',
                                    'href': '/informacje/polityka-prywatnosci',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '136',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Regulamin karty podarunkowej',
                                    'href': '/informacje/regulamin-karty-podarunkowej',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '137',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Regulamin Newslettera',
                                    'href': '/informacje/regulamin-newslettera',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                }
                            ],
                            '__typename': 'ComponentNavigationColumn'
                        },
                        {
                            'id': '21',
                            'title': 'O Nas',
                            'link': null,
                            'items': [
                                {
                                    'id': '139',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'O Lilou',
                                    'href': '/o-nas',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '584',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Oferta dla firm',
                                    'href': '/inspiracje/oferta-dla-firm',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '265',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Butiki',
                                    'href': '/butiki',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                {
                                    'id': '1218',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Biżuteria na prezent',
                                    'href': '/inspiracje/bizuteria-na-prezent',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                }
                            ],
                            '__typename': 'ComponentNavigationColumn'
                        }
                    ],
                    'company': 'Lilou Online Shop Sp. z o.o. s.k. <br>\nFiltrowa 50 <br>\n02-032 Warszawa <br>\nNIP: 7010666632 <br>',
                    'contact': '<p><span class="text-small"><span>Dane korespondencyjne:</span></span></p><p>Lilou Online Shop Sp. z o.o. s.k.</p><p>Lewartowskiego 6</p><p>00-190 Warszawa</p><p>&nbsp;</p><p>Odwiedź naszą <a href="/pl/informacje/faq.html">sekcję pomocy</a>, <a href="/pl/kontakt.html">napisz do nas</a> lub zadzwoń.</p><p>Nasi konsultanci są do Twojej dyspozycji&nbsp;</p><p>w dni robocze w godzinach 10:00 - 18:00.</p><p>&nbsp;</p><p><a href="mailto:e-sklep@lilou.pl">e-sklep@lilou.pl</a></p><p><a href="tel:+48224604777">+48 224604777</a></p><p>&nbsp;</p><p>Członek Zarządu: Mateusz Motyczyński <a href="mailto:m.motyczynski@lilou.pl">m.motyczynski@lilou.pl</a><br><br>PR &amp; Brand Manager: Kacper Słoma <a href="mailto:k.sloma@lilou.pl">k.sloma@lilou.pl</a></p>',
                    'social_media': [
                        {
                            'id': '141',
                            'external_href': 'https://www.facebook.com/bemylilou',
                            'target': 'blank',
                            'text': 'facebook',
                            'href': null,
                            'rel': null,
                            '__typename': 'ComponentPartsLink'
                        },
                        {
                            'id': '180',
                            'external_href': 'https://www.instagram.com/bemylilou/',
                            'target': 'blank',
                            'text': 'instagram',
                            'href': null,
                            'rel': null,
                            '__typename': 'ComponentPartsLink'
                        },
                        {
                            'id': '330',
                            'external_href': 'https://pl.pinterest.com/lilouofficial/',
                            'target': 'self',
                            'text': 'Pinterest',
                            'href': null,
                            'rel': null,
                            '__typename': 'ComponentPartsLink'
                        },
                        {
                            'id': '331',
                            'external_href': 'https://www.youtube.com/channel/UC-CxYAexy3loeqiCToeii0A',
                            'target': 'self',
                            'text': 'Youtube',
                            'href': null,
                            'rel': null,
                            '__typename': 'ComponentPartsLink'
                        }
                    ],
                    '__typename': 'Footer'
                },
                '__typename': 'FooterEntity'
            },
            '__typename': 'FooterEntityResponse'
        }
    }
    if (locale === 'de') {
        data = {
            'navigation': {
                'data': {
                    'attributes': {
                        'banner': {
                            'items': [
                                {
                                    'order': 1,
                                    'text': 'Haben Sie Fragen? Kontaktieren Sie uns',
                                    'url': 'https://lilouparis.de/de/contact.html',
                                    'date_from': '2023-11-19T23:00:00.000Z',
                                    'date_to': '2025-11-18T23:00:00.000Z',
                                    '__typename': 'ComponentNavigationBannerItems'
                                },
                                {
                                    'order': 1,
                                    'text': 'Personalisierter Schmuck I Kostenloser Versand ab 100 € Einkaufswert',
                                    'url': '/information/lieferung',
                                    'date_from': '2023-02-28T23:00:00.000Z',
                                    'date_to': '2026-03-27T23:00:00.000Z',
                                    '__typename': 'ComponentNavigationBannerItems'
                                }
                            ],
                            '__typename': 'ComponentNavigationBanner'
                        },
                        'top_menu': [
                            {
                                'id': '42',
                                'title': 'Schmuck',
                                'image': {
                                    'img': {
                                        'data': null,
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'link': null,
                                    'cta': null,
                                    '__typename': 'ComponentNavigationTopMenuImage'
                                },
                                'link': {
                                    'id': '1192',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/shop/schmuckstuecke',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [
                                    {
                                        'id': '90',
                                        'title': 'Kategorien',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '833',
                                                'external_href': '',
                                                'target': 'self',
                                                'text': 'Armbänder',
                                                'href': '/shop/schmuckstuecke/armbaender.html',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '834',
                                                'external_href': '',
                                                'target': 'self',
                                                'text': 'Halsketten',
                                                'href': '/shop/schmuckstuecke/halsketten',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '835',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Ohrringe',
                                                'href': '/shop/schmuckstuecke/ohrringe.html',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '836',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Ringe',
                                                'href': '/shop/schmuckstuecke/ringe.html',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '837',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Anhänger',
                                                'href': '/shop/schmuckstuecke/anhaenger.html',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '838',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Broschen',
                                                'href': '/shop/schmuckstuecke/broschen',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1399',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Männer Schmuck',
                                                'href': '/shop/kollektionen/manner-schmuck',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '840',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': '585er Goldschmuck',
                                                'href': '/shop/schmuckstuecke/585-Gold-Echtgold-Schmuck',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '91',
                                        'title': 'Neuheiten',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '1381',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'New Arrivals',
                                                'href': '/shop/empfehlung/neuheiten',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1382',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Now trending',
                                                'href': '/shop/empfehlung/inspirationen/talismane-zum-fruehling',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1419',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Schmuckkonfigurator - Armband',
                                                'href': '/armband-mit-vergoldetem-1-4-cm-elefant-anhanger-an-einem-dunnen-meergrunen-schnur',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1420',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Schmuckkonfigurator - Kette',
                                                'href': '/halskette-mit-einem-goldplattierten-herzen-auf-einer-duennen-klassischen-kette',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1421',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Schmuckkonfigurator - Ohrring',
                                                'href': '/kreolen-1-4cm-vergoldet',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    }
                                ],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '44',
                                'title': 'Valentinstag',
                                'image': null,
                                'link': {
                                    'id': '879',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Valentinstag',
                                    'color': 'red',
                                    'href': '/shop/empfehlungen/valentinstag',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '44',
                                'title': 'Bestseller',
                                'image': null,
                                'link': {
                                    'id': '879',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Bestseller',
                                    'href': '/shop/bestseller',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '69',
                                'title': 'Deine Gravur',
                                'image': null,
                                'link': null,
                                'columns': [
                                    {
                                        'id': '131',
                                        'title': null,
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '1428',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Anhänger mit Gravur',
                                                'href': '/inspirations/gravur',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1429',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Schmuck mit Gravur',
                                                'href': '/inspirations/schmuck-mit-gravur',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1430',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Schmuck Konfigurator',
                                                'href': '/inspirations/schmuck-konfigurator',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    }
                                ],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '82',
                                'title': 'Anlässe',
                                'image': null,
                                'link': null,
                                'columns': [
                                    {
                                        'id': '129',
                                        'title': null,
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '1396',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Party Glamour',
                                                'href': '/shop/empfehlung/inspirationen/party-glamour',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1397',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Hochzeitsschmuck',
                                                'href': '/shop/schmuckstuecke/hochzeitsschmuck',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1398',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Taufe und Kommunion',
                                                'href': '/shop/schmuckstuecke/taufe-und-kommunion',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1417',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Geburtstag Schmuck',
                                                'href': '/shop/schmuckstuecke/geburtstag-schmuck',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1418',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Freundschaftsschmuck',
                                                'href': '/shop/schmuckstuecke/freundschaftsschmuck',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    }
                                ],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '68',
                                'title': 'Schmuck Outlet',
                                'image': null,
                                'link': {
                                    'id': '1304',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/shop/empfehlung/schmuck-outlet',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '45',
                                'title': 'Über uns',
                                'image': {
                                    'img': {
                                        'data': null,
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'link': null,
                                    'cta': null,
                                    '__typename': 'ComponentNavigationTopMenuImage'
                                },
                                'link': {
                                    'id': '883',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/about',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [
                                    {
                                        'id': '96',
                                        'title': ' Über Lilou',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '880',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': ' Über Lilou',
                                                'href': '/about',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '881',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Boutiquen',
                                                'href': '/information/boutiquen',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '97',
                                        'title': 'Kontaktiere uns',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '882',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Kontakt',
                                                'href': '/contact',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    }
                                ],
                                '__typename': 'ComponentNavigationTopMenu'
                            }
                        ],
                        '__typename': 'Navigation'
                    },
                    '__typename': 'NavigationEntity'
                },
                '__typename': 'NavigationEntityResponse'
            },
            'footer': {
                'data': {
                    'attributes': {
                        'columns': [
                            {
                                'id': '98',
                                'title': 'CUSTOMER CARE',
                                'link': null,
                                'items': [
                                    {
                                        'id': '897',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Impressum',
                                        'href': '/information/impressum',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '898',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Lieferung',
                                        'href': '/information/lieferung',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '899',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Zahlungsmethoden',
                                        'href': '/information/zahlungsmethoden',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '900',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Widerrufsrecht für Verbraucher',
                                        'href': '/information/widerrufsrecht-fuer-verbraucher',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '901',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Fragen und Antworten',
                                        'href': '/information/faq-de',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '902',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Kontakt',
                                        'href': '/contact',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    }
                                ],
                                '__typename': 'ComponentNavigationColumn'
                            },
                            {
                                'id': '99',
                                'title': 'PRIVACY POLICY',
                                'link': null,
                                'items': [
                                    {
                                        'id': '903',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'AGB',
                                        'href': '/information/agb',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '904',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Datenschutzerklärung',
                                        'href': '/information/datenschutzerklaerung',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '906',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Cookie Einstellungen',
                                        'href': '/information/cookie-einstellungen',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    }
                                ],
                                '__typename': 'ComponentNavigationColumn'
                            },
                            {
                                'id': '100',
                                'title': 'ÜBER LILOU',
                                'link': null,
                                'items': [
                                    {
                                        'id': '907',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': null,
                                        'href': '/about',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '908',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Boutiquen',
                                        'href': '/information/boutiquen',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '909',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Angebot für Unternehmen',
                                        'href': '/inspirations/b2b',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    }
                                ],
                                '__typename': 'ComponentNavigationColumn'
                            }
                        ],
                        'company': 'Lilou GmbH <br>\nBeckbuschstraße 16 <br>\n40474 Düsseldorf<br>',
                        'contact': "<p>Korrespondenzdaten:</p><p>Lilou GmbH&nbsp;<br>Beckbuschstraße 16&nbsp;<br>40474 Düsseldorf<br>&nbsp;</p><p>Besuche unsere <a href=\"/de/information/faq-de.html\">FAQ</a>, schreibe uns oder rufe uns an.<br>&nbsp;</p><p>&nbsp;</p><p><a href=\"mailto:hello@lilouparis.de\" rel=\"noopener\" target=\"_blank\">hello@lilouparis.de</a></p><p>What's App: <a href=\"tel:+4915202338333\">+49 (0)1520 2338333</a><br>Telefon: <a href=\"tel:+4902114962275\">+49 (0)211 4962275</a></p>",
                        'social_media': [],
                        '__typename': 'Footer'
                    },
                    '__typename': 'FooterEntity'
                },
                '__typename': 'FooterEntityResponse'
            }
        }
    }
    if (locale === 'fr') {
        data = {
            'navigation': {
                'data': {
                    'attributes': {
                        'banner': {
                            'items': [
                                {
                                    'order': 1,
                                    'text': 'Avez-vous des questions? Contactez-nous',
                                    'url': 'https://lilou.fr/fr/contact.html',
                                    'date_from': '2023-11-19T23:00:00.000Z',
                                    'date_to': '2025-11-18T23:00:00.000Z',
                                    '__typename': 'ComponentNavigationBannerItems'
                                },
                                {
                                    'order': 1,
                                    'text': 'Livraison offerte pour toute commande supérieure à 75 €',
                                    'url': 'https://lilou.fr/information/livraison',
                                    'date_from': '2024-02-25T23:00:00.000Z',
                                    'date_to': '2030-02-25T23:00:00.000Z',
                                    '__typename': 'ComponentNavigationBannerItems'
                                }
                            ],
                            '__typename': 'ComponentNavigationBanner'
                        },
                        'top_menu': [
                            {
                                'id': '47',
                                'title': 'Nouveautés',
                                'image': null,
                                'link': {
                                    'id': '953',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': 'Nouveautés',
                                    'href': '/shop/recommandes/nouveautes',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '23',
                                'title': 'Bijoux',
                                'image': {
                                    'img': {
                                        'data': {
                                            'id': '2902',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/a12e59a7-1181-4835-4cf8-b0c61c7da900/public',
                                                'width': 1500,
                                                'height': 920,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'link': null,
                                    'cta': null,
                                    '__typename': 'ComponentNavigationTopMenuImage'
                                },
                                'link': {
                                    'id': '1191',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/shop/bijoux',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [
                                    {
                                        'id': '44',
                                        'title': 'Catégories',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '412',
                                                'external_href': '',
                                                'target': 'self',
                                                'text': 'Bracelets',
                                                'href': '/shop/bijoux/bracelets',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '414',
                                                'external_href': '',
                                                'target': 'self',
                                                'text': 'Colliers',
                                                'href': '/shop/bijoux/colliers',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '415',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': "Boucles d'oreilles",
                                                'href': '/shop/bijoux/boucles-d-oreilles',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '416',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Bagues',
                                                'href': '/shop/bijoux/bagues',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '417',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Pendentifs',
                                                'href': '/shop/bijoux/pendentifs',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '418',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Broches',
                                                'href': '/shop/bijoux/broches',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '419',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Boutons de manchette',
                                                'href': '/shop/bijoux/boutons-de-manchette',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '420',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Bijoux en or 585',
                                                'href': '/shop/bijoux/bijoux-en-or-585',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '101',
                                        'title': 'Produits recommandés',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '1373',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Planets',
                                                'href': '/shop/collections/collection-planets',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1347',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Soleil',
                                                'href': '/shop/collections/collection-soleil',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '921',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Bijoux de mariage',
                                                'href': '/shop/bijoux/bijoux-de-mariage',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1308',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Bijoux pour les occasions spéciales',
                                                'href': '/shop/recommandes/inspirations/bijoux-pour-les-occasions-speciales',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1357',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Chaînes de cheville',
                                                'href': '/shop/bijoux/bracelets/chaines-de-cheville',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '926',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Best-seller',
                                                'href': '/shop/recommandes/best-sellers',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1281',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection pour hommes',
                                                'href': '/shop/collections/collection-pour-hommes',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '928',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Fins de séries',
                                                'href': '/shop/recommandes/fins-de-series',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '103',
                                        'title': 'Nos thèmes',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '929',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Fleurs',
                                                'href': 'shop/bijoux/nos-themes/fleurs',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '930',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Voyages',
                                                'href': 'shop/bijoux/nos-themes/voyages',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '933',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Minimaliste',
                                                'href': 'shop/bijoux/nos-themes/minimaliste',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '931',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Animaux',
                                                'href': 'shop/bijoux/nos-themes/animaux',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '934',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Avec effet waouh',
                                                'href': 'shop/bijoux/nos-themes/avec-effet-waouh',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '935',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': "Avec de l'émail",
                                                'href': 'shop/bijoux/nos-themes/avec-de-lemail',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '932',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Amour',
                                                'href': 'shop/bijoux/nos-themes/amour',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '936',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Avec des pierres',
                                                'href': 'shop/bijoux/nos-themes/avec-des-pierres',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '937',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Astrologie',
                                                'href': 'shop/bijoux/nos-themes/astrologie',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '45',
                                        'title': 'Collections',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '1374',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Planets',
                                                'href': '/shop/collections/collection-planets',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1348',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Soleil',
                                                'href': '/shop/collections/collection-soleil',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1290',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Papillon',
                                                'href': '/shop/collections/collection-papillon',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1266',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Amour',
                                                'href': '/shop/collections/collection-amour',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1236',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Cosmos',
                                                'href': '/shop/collections/collection-cosmos',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1237',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Perles',
                                                'href': 'shop/collections/collection-perles',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1238',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Forza',
                                                'href': 'shop/collections/collection-forza',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1239',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Etno',
                                                'href': '/shop/collections/268-collection-etno',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1240',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection pour hommes',
                                                'href': 'shop/collections/collection-pour-hommes',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1241',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Bloom',
                                                'href': '/shop/collections/collection-bloom',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1242',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Meaning avec quartz',
                                                'href': '/shop/collections/270-collection-meaning-avec-quartz',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1243',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection avec des zircons',
                                                'href': 'shop/collections/collectio-avec-des-zircons',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1244',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Meaning avec quartz',
                                                'href': '/shop/collections/270-collection-meaning-avec-quartz',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1245',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Sparkling',
                                                'href': '/shop/collections/collection-sparkling',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1247',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Zodiac',
                                                'href': '/shop/collections/collection-zodiac',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1248',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Lune',
                                                'href': '/shop/collections/collection-lune',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1249',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Ginkgo',
                                                'href': '/shop/collections/collection-ginkgo',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1250',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Luck',
                                                'href': '/shop/collections/collection-luck',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '1251',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Collection Celebrate',
                                                'href': '/shop/collections/collection-celebrate',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '104',
                                        'title': 'Idées cadeaux',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '943',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Pour Lui',
                                                'href': '/shop/idees-cadeaux/pour-lui',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '944',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Pour les enfants',
                                                'href': '/shop/idees-cadeaux/pour-les-enfants',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '945',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Coffrets Cadeaux',
                                                'href': '/shop/idees-cadeaux/coffrets-cadeaux',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    }
                                ],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '80',
                                'title': 'Bijoux pour lui ',
                                'image': null,
                                'link': {
                                    'id': '1390',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/shop/idees-cadeaux/pour-lui',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '67',
                                'title': 'Idées cadeaux ',
                                'image': null,
                                'link': {
                                    'id': '1303',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/le-inspirations/idees-cadeaux',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '22',
                                'title': 'Sacs et accessoires',
                                'image': {
                                    'img': {
                                        'data': {
                                            'id': '3300',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/ee0a2bb2-f119-42f0-8377-46fe3aee5700/public',
                                                'width': 1200,
                                                'height': 1200,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'link': null,
                                    'cta': null,
                                    '__typename': 'ComponentNavigationTopMenuImage'
                                },
                                'link': {
                                    'id': '1271',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/shop/sacs-et-accessoires',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [
                                    {
                                        'id': '47',
                                        'title': 'Sacs à main',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '436',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Sacs à bandoulière',
                                                'href': '/shop/sacs-et-accessoires/sacs-a-bandouliere',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '435',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Grands sacs à main',
                                                'href': '/shop/sacs-et-accessoires/grands-sacs-a-main',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '437',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Petits sacs à main',
                                                'href': '/shop/sacs-et-accessoires/petits-sacs-a-main',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '48',
                                        'title': 'Accessoires',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '1352',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Étuis pour passports',
                                                'href': '/shop/sacs-et-accessoires/etuis-pour-passeports',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '946',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Trousses',
                                                'href': '/shop/sacs-et-accessoires/trousses',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '947',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Foulards & Châle',
                                                'href': '/shop/sacs-et-accessoires/foulards-châle',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '440',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Coffrets à bijoux',
                                                'href': '/shop/sacs-et-accessoires/boites-a-bijoux',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '948',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Accessoires de sac',
                                                'href': '/shop/sacs-et-accessoires/accessoires-de-sac',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '438',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Portefeuilles',
                                                'href': '/shop/sacs-et-accessoires/portefeuilles',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '439',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Porte-cartes',
                                                'href': '/shop/sacs-et-accessoires/porte-cartes',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '441',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Carnets',
                                                'href': '/shop/sacs-et-accessoires/carnets',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '442',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Porte-clés',
                                                'href': '/shop/sacs-et-accessoires/porte-cles',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    }
                                ],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '81',
                                'title': 'Best-sellers',
                                'image': null,
                                'link': {
                                    'id': '1391',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/shop/recommandes/best-sellers',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [],
                                '__typename': 'ComponentNavigationTopMenu'
                            },
                            {
                                'id': '21',
                                'title': 'À la rencontre de Lilou',
                                'image': {
                                    'img': {
                                        'data': null,
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'link': null,
                                    'cta': null,
                                    '__typename': 'ComponentNavigationTopMenuImage'
                                },
                                'link': {
                                    'id': '443',
                                    'external_href': null,
                                    'target': 'self',
                                    'text': null,
                                    'href': '/about',
                                    'rel': null,
                                    '__typename': 'ComponentPartsLink'
                                },
                                'columns': [
                                    {
                                        'id': '105',
                                        'title': 'Nous connaître',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '949',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'À propos de Lilou',
                                                'href': '/about',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            {
                                                'id': '950',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Les boutiques',
                                                'href': '/showrooms',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    },
                                    {
                                        'id': '106',
                                        'title': 'Échangez avec nous',
                                        'link': null,
                                        'items': [
                                            {
                                                'id': '952',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': 'Contact',
                                                'href': '/contact',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            }
                                        ],
                                        '__typename': 'ComponentNavigationColumn'
                                    }
                                ],
                                '__typename': 'ComponentNavigationTopMenu'
                            }
                        ],
                        '__typename': 'Navigation'
                    },
                    '__typename': 'NavigationEntity'
                },
                '__typename': 'NavigationEntityResponse'
            },
            'footer': {
                'data': {
                    'attributes': {
                        'columns': [
                            {
                                'id': '64',
                                'title': 'Informations utiles',
                                'link': null,
                                'items': [
                                    {
                                        'id': '534',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Livraison',
                                        'href': '/information/livraison',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '535',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Modes de paiement',
                                        'href': '/information/modes-de-paiement ',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '537',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Retours et Réclamations',
                                        'href': '/information/retours-et-reclamations',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '538',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'FAQ',
                                        'href': '/information/faq-france',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '536',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Contact',
                                        'href': '/contact',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    }
                                ],
                                '__typename': 'ComponentNavigationColumn'
                            },
                            {
                                'id': '63',
                                'title': 'Mentions légales',
                                'link': null,
                                'items': [
                                    {
                                        'id': '539',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Contitions Générales de Vente',
                                        'href': '/information/conditions-de-vente',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '540',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Politique de confidentialité',
                                        'href': '/information/politique-de-confidentialite',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    }
                                ],
                                '__typename': 'ComponentNavigationColumn'
                            },
                            {
                                'id': '65',
                                'title': 'À la rencontre de Lilou',
                                'link': null,
                                'items': [
                                    {
                                        'id': '543',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'À propos de Lilou',
                                        'href': '/about',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    {
                                        'id': '954',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': 'Boutiques',
                                        'href': '/showrooms',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    }
                                ],
                                '__typename': 'ComponentNavigationColumn'
                            }
                        ],
                        'company': 'Lilou Online Shop Sp. z o.o. s.k. <br>\nFiltrowa 50 <br>\n02-032 Warszawa <br>\nNIP: 7010666632 <br>\nREGON: 366571012 <br>',
                        'contact': "<p>Adresse postale :&nbsp;</p><p>Lilou Online Shop Sp. z o.o. s.k.</p><p>Lewartowskiego 6</p><p>00-190 Warszawa</p><p>&nbsp;</p><p>&nbsp;</p><p>Vous n'êtes pas sûr(e) de la taille ? Vous souhaitez vous renseigner sur les possibilités de personnalisation ?</p><p>&nbsp;</p><p>Vous pouvez consulter notre FAQ notamment pour des questions relatives au fonctionnement du site,</p><p>&nbsp;</p><p>ou</p><p>&nbsp;</p><p>Vous pouvez nous contacter du lundi au samedi, de 10h30 à 19h00 :</p><p>&nbsp;</p><p>-par téléphone au +33 1 46 34 10 49</p><p>&nbsp;</p><p>-par e-mail à l'adresse <a href=\"mailto:client@lilou.fr\">client@lilou.fr</a></p>",
                        'social_media': [
                            {
                                'id': '962',
                                'external_href': 'https://www.facebook.com/LilouFrance/?locale=fr_FR',
                                'target': 'blank',
                                'text': 'facebook',
                                'href': null,
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            {
                                'id': '1212',
                                'external_href': 'https://www.instagram.com/bemylilouparis/',
                                'target': 'blank',
                                'text': 'Instagram',
                                'href': null,
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            }
                        ],
                        '__typename': 'Footer'
                    },
                    '__typename': 'FooterEntity'
                },
                '__typename': 'FooterEntityResponse'
            }
        }
    }
    const bannerItems = useMemo(() => filterBannerItems(data?.navigation?.data?.attributes?.banner?.items), [data])
    useEffect(() => {
        setShowTopBar(!!bannerItems.length)
    }, [setShowTopBar, bannerItems])
    return {bannerItems, data}
}

export default usePageData
