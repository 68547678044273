import {parseCookies} from 'nookies'

export default {
    getCustomerToken: () => {
        if (typeof window !== 'undefined') {
            const customerToken = parseCookies().customerToken
            if (!!customerToken) return customerToken
            const customerSocialsToken = parseCookies().customerSocialsToken
            if (!!customerSocialsToken) return customerSocialsToken
            return false
        }
    }
}