import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function Plus(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        id="Line_11"
        data-name="Line 11"
        transform="translate(12.5 6.5)"
        d="M0 0v12"
      />
      <path
        id="Line_12"
        data-name="Line 12"
        transform="translate(6.5 12.5)"
        d="M0 0h12"
      />
    </Icon>
  )
}
