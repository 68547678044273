import {Box, Center, SimpleGrid, Spinner, Text} from '@chakra-ui/react'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import SimpleItem from 'modules/Listing/SimpleItem'
import MagicLoader from '../../../components/atoms/MagicLoader/MagicLoader'
import URLHelper from '../../../helpers/URLHelper/URLHelper'
import {useRouter} from 'next/router'

const SearchHits = ({hits = [], enableInfiniteScroll, isLastPage, onLoadMore}) => {
    const {t} = useTranslation()
    const { locale } = useRouter()
    const sentinelRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (enableInfiniteScroll && sentinelRef.current !== null) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !isLastPage && !isLoading) {
                        setIsLoading(true)
                        onLoadMore()
                        setIsLoading(false)
                    }
                })
            })
            observer.observe(sentinelRef.current)
            return () => {
                observer.disconnect()
            }
        }
    }, [isLastPage, enableInfiniteScroll, onLoadMore, isLoading])
    return (
        <SimpleGrid columns={{base: 2, md: 6}} gap="30px">
            <MagicLoader loadingOutSide={isLoading}>
                {hits?.length > 0 ? hits.map((hit: any) => (
                    <SimpleItem
                        key={hit.objectID}
                        item={{
                            ...hit,
                            image: {url: hit.thumbnail_url, label: hit.name},
                            url_key: locale && URLHelper.get(hit.url, locale),
                        }}
                        dataInsightsObjectId={hit.objectID}
                        dataInsightsPosition={hit.__position}
                        dataInsightsQueryId={hit.__queryID}
                    />
                )) : (
                    <Center mb={4}>
                        <Box maxWidth="58.125rem" width="100%" margin="0 auto">
                            <Text>{t('NO_SEARCH_RESULTS')}</Text>
                        </Box>
                    </Center>
                )}
                <li style={{listStyle: 'none', width: '100%'}} ref={sentinelRef} aria-hidden="true" />
                {isLastPage && hits.length > 0 && enableInfiniteScroll && (
                    <Center w="100%">
                        <Spinner />
                    </Center>
                )}
            </MagicLoader>
        </SimpleGrid>
    )
}

export default SearchHits
