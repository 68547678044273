import dynamic from 'next/dynamic'
import {useCallback, useEffect, useState} from 'react'
import {Box, Container, Flex, useDisclosure} from '@chakra-ui/react'
import {useCollapse} from 'react-collapsed'
import {useReactiveVar} from '@apollo/client'
import {isFunction} from 'lodash-es'
import {useRecoilValue} from 'recoil'
import {useRouter} from '_app/hooks/useRouter'
import {isCartOpenVar} from '_app/cache'
import {ComponentNavigationTopMenu} from '_app/graphql/cms/generated/graphql'
import useBodyScroll from '_app/hooks/useBodyScroll'
import {useFixedPosition} from '_app/hooks/useFixedPosition'
import {showTopBarAtom} from '_app/state/atoms'
import NavBarLogo from './../NavBarLogo'
import Navigation from './Navigation'
import NavBarMobileDropDown from './mobile/NavBarMobileDropDown'
import GiftBar from '../GiftBar'
import ElementsSizesEnum from '../../../enums/ElementsSizesEnum/ElementsSizesEnum'
import useViewProduct from '../../hooks/useViewProduct'

const DynamicCartDrawer = dynamic(() => import('modules/cart/components/CartDrawer'))
const DynamicSearchCollapse = dynamic(() => import('../Search/SearchCollapse'))

interface NavBarProps {
    data?: any;
    isTransparent?: boolean;
}

const DynamicNavBarIcons = dynamic(() => import('./NavBarIcons'), {ssr: false})

const NavBar = ({data, isTransparent}: NavBarProps) => {
    const {lastViewedList} = useViewProduct({})
    const showTopBar = useRecoilValue(showTopBarAtom)
    const router = useRouter()
    const {isFixed} = useFixedPosition({breakpoint: 10, onlyMobile: false})
    const {isOpen, onToggle, onClose} = useDisclosure()
    const isCartOpen = useReactiveVar(isCartOpenVar)
    const [isBgFilled, setIsBgFilled] = useState(false)
    const topMenu = data?.navigation?.data?.attributes
        ?.top_menu as ComponentNavigationTopMenu[]
    const [isExpanded, setExpanded] = useState(false)
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded})
    const {lock: lockBodyScroll, unlock: unlockBodyScroll} = useBodyScroll()
    const menuShouldBeFixed = isOpen || isFixed
    const additionalSpace = showTopBar ? '2.5rem' : 0
    const setExpandedWithBodyScrollLock: typeof setExpanded = useCallback(arg => {
        const newState = isFunction(arg) ? arg(isExpanded) : arg
        if (newState) lockBodyScroll()
        else unlockBodyScroll()
        setExpanded(newState)
    }, [isExpanded, lockBodyScroll, unlockBodyScroll])
    useEffect(() => {
        setIsBgFilled((isTransparent && isFixed) || !isTransparent || isExpanded)
    }, [isFixed, isTransparent, isExpanded])
    useEffect(() => {
        if (isCartOpen) {
            router.events.on('routeChangeComplete', () => isCartOpenVar(false))
            return () => router.events.off('routeChangeComplete', () => isCartOpenVar(false))
        }
    }, [isCartOpen])
    useEffect(() => {
        if (isOpen) {
            router.events.on('routeChangeComplete', onClose)
            return () => router.events.off('routeChangeComplete', onClose)
        }
    }, [isOpen])
    return (
        <>
            <Box
                as="nav"
                position="fixed"
                top={{
                    base: isExpanded || menuShouldBeFixed ? 0 : additionalSpace,
                    lg: menuShouldBeFixed ? 0 : additionalSpace
                }}
                zIndex={10}
                left={0}
                right={0}
                py={4}
                height={ElementsSizesEnum.NAVBAR_HEIGHT}
                width="100vw"
                bg={{base: 'white', lg: isBgFilled ? 'white' : 'transparent'}}
                color={{base: 'black', lg: isBgFilled ? 'black' : 'white'}}
                borderBottomWidth={1}
                borderBottomColor={isBgFilled ? 'mercury' : 'transparent'}
                transition="all 0.2s"
                _hover={{bg: 'white', color: 'black'}}
            >
                <Container width="100%">
                    <NavBarLogo display={{base: 'none', lg: 'block'}}/>
                    <Flex justifyContent="space-between" style={{paddingRight: '20px'}}>
                        <Navigation topMenu={topMenu}/>
                        <DynamicNavBarIcons
                            toggle={{isOpen, onToggle}}
                            setExpanded={setExpandedWithBodyScrollLock}
                            getToggleProps={getToggleProps}
                        />
                    </Flex>
                    <GiftBar/>
                </Container>
                {
                    isExpanded && (
                        <DynamicSearchCollapse
                            filter={false}
                            enableInfiniteScroll={false}
                            collapse={{getCollapseProps, getToggleProps}}
                            expanded={{isExpanded, setExpanded: setExpandedWithBodyScrollLock}}
                        />
                    )
                }
            </Box>
            {
                !isCartOpen && isOpen && (
                    <NavBarMobileDropDown topMenu={topMenu}/>
                )
            }
            {
                isCartOpen && (
                    <DynamicCartDrawer lastViewedList={lastViewedList} onClose={() => isCartOpenVar(false)}/>
                )
            }
        </>
    )
}

export default NavBar
