import React, {MouseEventHandler} from 'react'
import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react'
import styled from '@emotion/styled'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

interface Slide {
  content: React.ReactNode;
  id: number;
  onClick?: MouseEventHandler<HTMLElement>;
}

interface CarouselProps extends SwiperProps {
  paginationColor?: string;
  slides: Slide[];
}

type StyledSwiperProps = Omit<CarouselProps, 'slides'>;
const SwiperWithoutTransientProps = ({
  paginationColor: _,
  ...props
}: StyledSwiperProps) => <Swiper {...props} />
const SquaredPaginationSwiper = styled(SwiperWithoutTransientProps)`
  .swiper-pagination-bullet {
    box-sizing: border-box;
    border: ${({ paginationColor }) => `1px solid ${paginationColor ?? 'white'}`};
    width: 0.75rem;
    height: 0.75rem;
    background-color: transparent;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: ${({ paginationColor }) => paginationColor ?? 'white'};
  }
`

const Carousel = ({ slides, ...props }: CarouselProps) => {
  return (
    <SquaredPaginationSwiper {...props}>
      {slides.map(slide => (
        <SwiperSlide key={slide.id} onClick={slide.onClick}>
          {slide.content}
        </SwiperSlide>
      ))}
    </SquaredPaginationSwiper>
  )
}

export default Carousel
