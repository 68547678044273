import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function HeartFillGoldIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        style={{
          fill: '#fcca9d',
          stroke: '#fcca9d',
          outlineColor: '#fff',
        }}
        transform="translate(2.438 4.427)"
        d="M18.526 4.769A4.692 4.692 0 0 0 13.939.61a4.99 4.99 0 0 0-4.33 1.978 4.97 4.97 0 0 0-4.21-2c-.057 0-.11-.014-.167-.012a4.735 4.735 0 0 0-4.56 5.755 4.954 4.954 0 0 0 1.437 2.823l7.5 7.428L17.1 9.16h.006a5.059 5.059 0 0 0 .494-.56 4.971 4.971 0 0 0 .926-3.831z"
      />
    </Icon>
  )
}
