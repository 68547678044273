import { ApolloClient, ApolloLink, HttpLink, NormalizedCacheObject } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import merge from 'deepmerge'
import { isEqual } from 'lodash-es'
import { useMemo } from 'react'
import nookies, { parseCookies } from 'nookies'
import { onError } from '@apollo/client/link/error'
import { Context } from '_app/types/context'
import getLocale from '_app/utils/localized/getLocale'
import logger from '../../_app/utils/logger'
import { initCache } from '../../_app/cache'
import type { AppProps } from 'next/app'
import ApolloEnum from '../../enums/ApolloEnum/ApolloEnum'
import RequestEnum from '../../enums/RequestEnum/RequestEnum'
import CookiesHelper from '../CookiesHelper/CookiesHelper'
import * as Sentry from '@sentry/nextjs'
import {GTM_EVENTS} from '../../_app/utils/dataLayer'

let apolloHelper: ApolloClient<NormalizedCacheObject> | undefined

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, path }) => {
            const errorMessage = `[GraphQL error]: ${message}, [Path]: ${path}, [Operation]: ${JSON.stringify(operation)}`
            console.error(errorMessage)
            Sentry.captureException(new Error(errorMessage), {
                extra: {
                    operationName: operation.operationName,
                    variables: operation.variables,
                    headers: operation.getContext().headers,
                },
            })
        })
    }
})

const customFetch = async (uri: string | Request | URL, options: RequestInit | undefined): Promise<Response> => {
    let additionalOptions: Partial<RequestInit> = {}
    if (uri === process.env.NEXT_PUBLIC_MAGENTO_API_URL) {
        additionalOptions = {
            credentials: 'include',
        }
    }
    const mergedOptions: RequestInit = {
        ...additionalOptions,
        ...options,
        headers: {
            ...options?.headers,
        },
    }
    try {
        const response = await fetch(uri, mergedOptions)
        const responseSetCookie = response.headers.get('set-cookie')
        if (responseSetCookie) {
            responseSetCookie.split(',').forEach(cookie => {
                const [name, value] = cookie.split(';')[0].split('=')
                CookiesHelper.set(name, value, 7)
            })
        }
        return response
    } catch (error) {
        Sentry.captureException(error, {
            extra: {
                url: uri,
                method: options?.method || 'GET',
                headers: options?.headers,
            },
        })
        throw error
    }
}

const authLink = (ctx: Partial<Context>) => new ApolloLink((operation, forward) => {
    let token = ''
    const lang = getLocale(ctx.locale)
    let cookies: Record<string, string> = {}
    if (ctx?.req?.headers['cookie']) {
        cookies = nookies.get(ctx)
        token = cookies?.customerToken || cookies?.customerSocialsToken
    } else {
        cookies = parseCookies()
        token = cookies?.customerToken || cookies?.customerSocialsToken
    }
    const formattedCookies = Object.entries(cookies)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('; ')
    const clientURL = {
        pl: process.env.NEXT_PUBLIC_CLIENT_URL_PL,
        en: process.env.NEXT_PUBLIC_CLIENT_URL_EN,
        de: process.env.NEXT_PUBLIC_CLIENT_URL_DE,
        fr: process.env.NEXT_PUBLIC_CLIENT_URL_FR,
    }
    const actualClientURL = clientURL[lang].replace('https://', '')
    const magentoBasicAuth = process.env.NEXT_PUBLIC_MAGENTO_BASIC_AUTH
    const allHeaders: any = {
        store: lang,
        accept: 'application/json',
        cookie: formattedCookies,
        'Access-Control-Allow-Origin': actualClientURL,
        referer: actualClientURL,
        origin: actualClientURL,
        via: `1.1 ${actualClientURL}`,
        'Sec-Fetch-Site': 'same-origin',
    }
    if (token) {
        allHeaders.authorization = `Bearer ${token}`
    } else {
        if (magentoBasicAuth) {
            allHeaders.authorization = `Basic ${magentoBasicAuth}`
        }
    }
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...allHeaders,
            ...headers,
        },
    }))
    return forward(operation)
})


const operationLogLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(result => {
        return result
    })
})

const basicAuthLink = (ctx: Partial<Context>) =>
    new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                locale: ctx.locale,
            },
        }))
        return forward(operation)
    })

const retryErrorList = ['FetchError', 'ServerParseError']

const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 5,
        retryIf: (error, _operation) => {
            if (_operation && retryErrorList.includes(error.name)) {
                logger.warn(
                    `[RETRY] Name: ${error.name}, message: ${
                        error.message
                    }, operationName: ${
                        _operation.operationName
                    }, variables: ${JSON.stringify(
                        _operation.variables
                    )}, query: ${JSON.stringify(_operation.query)}`
                )

                return true
            }

            return false
        },
    },
})

function createApolloClient(ctx: Partial<Context>) {
    const cache = initCache()
    const ssrMode = typeof window === 'undefined'
    const cmsHttpLink = new HttpLink({ uri: `${process.env.NEXT_PUBLIC_CMS_URL}`, fetch: customFetch })
    const configuratorHttpLink = new HttpLink({ uri: `${process.env.NEXT_PUBLIC_MICROSERVICE_API_URL}`, fetch: customFetch })
    const globalHttpLink = new HttpLink({ uri: process.env.NEXT_PUBLIC_MAGENTO_API_URL, fetch: customFetch })
    return new ApolloClient({
        ssrMode,
        link: ApolloLink.from([
            ApolloLink.split(
                operation => [RequestEnum.CMS_PUBLIC, RequestEnum.CMS_PRIVATE, RequestEnum.MICROSERVICE].includes(operation.getContext().clientName),
                basicAuthLink(ctx),
                authLink(ctx)
            ),
            operationLogLink,
            retryLink,
            errorLink,
            ApolloLink.split(
                operation => operation.getContext().clientName === RequestEnum.CMS_PUBLIC || operation.getContext().clientName === RequestEnum.CMS_PRIVATE,
                cmsHttpLink,
                ApolloLink.split(
                    operation => operation.getContext().clientName === RequestEnum.MICROSERVICE,
                    configuratorHttpLink,
                    globalHttpLink
                )
            ),
        ]),
        cache,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
            query: {
                fetchPolicy: 'no-cache',
            },
        },
    })
}

export function initializeApollo(initialState = null, ctx: Partial<Context> = {}) {
    const _apolloClient = apolloHelper ?? createApolloClient(ctx)
    if (initialState) {
        const existingCache = _apolloClient.extract()
        const data = merge(initialState, existingCache, {
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
            ],
        })
        _apolloClient.cache.restore(data)
    }
    if (typeof window === 'undefined') return _apolloClient
    if (!apolloHelper) apolloHelper = _apolloClient
    return _apolloClient
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: AppProps['pageProps']) {
    if (pageProps?.props && client?.cache) pageProps.props[ApolloEnum.APOLLO_STATE_PROP_NAME] = client?.cache?.extract()
    return pageProps
}

export function useApollo(pageProps: AppProps['pageProps'], ctx?: Partial<Context>) {
    const state = pageProps[ApolloEnum.APOLLO_STATE_PROP_NAME]
    return useMemo(() => initializeApollo(state, ctx), [state, ctx])
}