import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function HeartCrossedOutIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 54 54" {...props}>
      <path
        d="M41.536 24.988 24.028 42.3 6.519 24.988m0 0a11.661 11.661 0 1 1 17.508-15.3A11.663 11.663 0 1 1 41.536 25"
        transform="translate(3.973 6.715)"
      />
      <path
        d="m-1877 17876.521 34.431 34.432"
        transform="translate(1886 -17867.08)"
      />
      <path
        d="m-1877 17876.521 34.431 34.432"
        transform="translate(1885 -17866.08)"
      />
    </Icon>
  )
}
