import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import Logo from './Logo'

const NavBarLogo = (props: BoxProps) => {
  return (
    <Box textAlign="center" mb={{ base: 4, lg: 6 }} {...props}>
      <Logo height={{ base: '1.5rem', lg: '2.75rem' }} />
    </Box>
  )
}

export default NavBarLogo
