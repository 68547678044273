import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function PlayIconSmall(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 200 200" {...props} strokeWidth="5">
      <style type="text/css">
        {'.st0{fill:none;stroke:#000000;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}'}
      </style>
      <circle className="st0" cx="102.5" cy="98.4" r="61.4" />
      <polygon className="st0" points="90,73 128.3,98.4 90,123.8 " />
    </Icon>
  )
}
