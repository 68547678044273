import { IconProps } from '@chakra-ui/react'
import { Icon } from './styles'

export default function HealthIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path
        id="Path_13481"
        data-name="Path 13481"
        d="M11.3,24.283l4.594-6.891,4.594,6.891m-4.594-6.891V12.8l6.891-2.3M9,10.5l6.891,2.3"
        transform="translate(0.109 2.296)"
      />
      <ellipse
        id="Ellipse_5146"
        data-name="Ellipse 5146"
        cx="1.149"
        cy="1.149"
        rx="1.149"
        ry="1.149"
        transform="translate(14.852 4.755)"
      />
    </Icon>
  )
}
