import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AspectRatio,
    Box,
    Collapse,
    Container,
    Flex,
    Text,
} from '@chakra-ui/react'
import {useTranslation} from 'next-i18next'
import Image from 'next/legacy/image'
import {useState} from 'react'
import {ArrowRightIcon} from '_app/components/Icons'
import {ourValues} from './constants'

const CustomAccordion = () => {
  const { t } = useTranslation('about')
  const [activeCard, setActiveCard] = useState(-1)

  return (
    <Box my="3rem">
      <Container>
        <Text textStyle="sectionHeader" mb={6}>
          {t('OUR_VALUES')}
        </Text>
      </Container>
      <Box display={{ base: 'block', lg: 'none' }}>
        <Accordion allowToggle onChange={(e: number) => setActiveCard(e)}>
          {ourValues.map(({ title, img_mobile, description }, index) => (
            <Box key={index}>
              <AccordionItem border="none">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton _hover={{ bg: '#transparent' }} p="0">
                      <Flex flexDirection="column" width="full">
                        <AspectRatio
                          ratio={4 / 3}
                          w="full"
                          position="relative"
                          _after={{
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            bg: 'black',
                            opacity: activeCard !== -1 && !isExpanded ? 0.4 : 0,
                            zIndex: 1,
                          }}
                        >
                          <Image src={img_mobile} layout="fill" alt={title} />
                        </AspectRatio>
                        <Flex px={4}>
                          <Text
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                            position="relative"
                            mt={isExpanded ? 6 : '-2.4rem'}
                            fontStyle="sectionHeader"
                            fontSize="1.25rem"
                            color={isExpanded ? '#000' : '#fff'}
                          >
                            {title}
                            <ArrowRightIcon />
                          </Text>
                        </Flex>
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel px={4}>
                      <Box pt={4} textStyle="bodyLight" mb={8}>
                        {description}
                      </Box>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Box>
          ))}
        </Accordion>
      </Box>

      <Container display={{ base: 'none', lg: 'block' }}>
        <Flex w="full">
          {ourValues.map(({ title, img, description }, index) => (
            <Box
              flex="1 1 25%"
              key={`ourValues-${title}`}
              position="relative"
              _after={{
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                bg: 'black',
                opacity: activeCard !== -1 && activeCard !== index ? 0.4 : 0,
                zIndex: 1,
              }}
              cursor="pointer"
              onClick={() =>
                activeCard === index ? setActiveCard(-1) : setActiveCard(index)
              }
            >
              <AspectRatio ratio={1 / 2} w="full">
                <Image src={img} layout="fill" alt={title} />
              </AspectRatio>
              <Box
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                p={6}
                bg={activeCard === index ? 'white' : 'transparent'}
              >
                <Text
                  textStyle="sectionHeader"
                  fontSize="24px"
                  color={activeCard === index ? 'black' : 'white'}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  {t(title)}
                  <ArrowRightIcon />
                </Text>
                <Collapse startingHeight={0} in={index === activeCard}>
                  <Text pt={6} textStyle="bodyLight">
                    {t(description)}
                  </Text>
                </Collapse>
              </Box>
            </Box>
          ))}
        </Flex>
      </Container>
    </Box>
  )
}

export default CustomAccordion
